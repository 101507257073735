import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";

export default function DropDown({ label }) {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [test, setTest] = useState(label[0].label);
  return (
    <FormControl
      sx={{ m: 1, minWidth: 132 }}
      size="small"
      style={{ margin: "0px", marginBottom: "10px" }}
    >
      <InputLabel id="demo-select-small">{test}</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={age}
        label="Yeaaaaa"
        onChange={handleChange}
      >
        {label.map((value, id) => (
          <MenuItem
            key={value.value}
            onClick={() => setTest(value.label)}
            value={value.value}
            style={value.labelstyle}
          >
            {value.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
