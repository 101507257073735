import React, { useRef, useState, useContext } from "react";
import { useDrag } from "react-dnd";
import { COMPONENT } from "../../../constants";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { IconButton } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { EditorContext } from "../../../context/EditorContext";
import { handleRemoveItemFromLayout } from "../helpers";
import { parseJsonToHtml } from "../../../utils/helper-editor";
const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "move",
};
const Component = ({
  data,
  path,
  itemActuallyEdited,
  handleComponentEditable,
  displayNavbarMenu,
  itemActuallyEditedLinks,
}) => {
  const { NewsletterProperty, setNewsletterProperty, setitemActuallyEdited } =
    useContext(EditorContext);
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    item: { type: COMPONENT, id: data.id, path },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(ref);
  const component = NewsletterProperty?.components.find(
    (componentToFind, index) => componentToFind.id === data.id
  );
  const removeComponentToTrashBin = (item) => {
    const splitItemPath = item.path.split("-");
    setNewsletterProperty({
      ...NewsletterProperty,
      components: NewsletterProperty?.components,
      layout: handleRemoveItemFromLayout(
        NewsletterProperty?.layout,
        splitItemPath
      ),
    });
    setitemActuallyEdited(null);
  };
  const [deleteTag, setDeleteTag] = useState(false);
  const [openUrlTag, setOpenUrlTag] = useState(false);

  const openLinksInNewWindow = (link) => {
    // Filtrer les liens valides (non '#[url]')
    const validLinks = link.filter((link) => !link.includes("#[url]"));

    // Ouvrir chaque lien dans une nouvelle fenêtre
    window.open(validLinks, "_blank");
  };

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
        onClick={displayNavbarMenu}
      >
        <div
          onClick={(e) => handleComponentEditable(e, data.id)}
          ref={ref}
          style={{ ...style, opacity }}
          className={`component draggable ${
            component?.type === "text" ? "tinytext" : ""
          } ${itemActuallyEdited?.id === data.id ? "edit-active" : ""}`}
          dangerouslySetInnerHTML={{
            __html: parseJsonToHtml(component?.content),
          }}
        ></div>
        {itemActuallyEdited?.id === data.id && (
          <div className="delete-container">
            <div className="arrow-up">
              <svg
                viewBox="-0.2 0.9 15 8"
                width="18"
                height="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M 14.431 7.926 L 8.612 2.107 C 8.232 1.727 7.619 1.716 7.225 2.082 L 0.931 7.926"
                  strokeLinecap="round"
                  fill="#FFFFFF"
                ></path>
                <path
                  d="M 14.769 7.939 C 14.964 7.743 14.964 7.427 14.769 7.231 L 8.95 1.412 C 8.674 1.167 8.254 0.98 7.917 0.974 C 7.579 0.968 7.153 1.139 6.869 1.375 L 0.575 7.219 C 0.372 7.406 0.361 7.723 0.549 7.925 C 0.736 8.128 1.053 8.139 1.255 7.951 L 7.549 2.107 C 7.659 1.977 7.736 1.971 7.899 1.974 C 8.061 1.977 8.138 1.985 8.242 2.12 L 14.061 7.939 C 14.257 8.134 14.573 8.134 14.769 7.939 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  d="M 14.763 7.779 C 14.958 7.583 14.958 7.267 14.763 7.071 L 8.944 1.252 C 8.668 1.007 8.248 0.82 7.911 0.814 C 7.573 0.808 7.147 0.979 6.863 1.215 L 0.569 7.059 C 0.366 7.246 0.355 7.563 0.543 7.765 C 0.73 7.968 1.047 7.979 1.249 7.791 L 7.543 1.947 C 7.653 1.817 7.73 1.811 7.893 1.814 C 8.055 1.817 8.132 1.825 8.236 1.96 L 14.055 7.779 C 14.251 7.974 14.567 7.974 14.763 7.779 Z"
                  fill="#DEDEDE"
                ></path>
              </svg>
            </div>
            {itemActuallyEdited?.editable &&
              itemActuallyEditedLinks.length > 0 && (
                <>
                  {itemActuallyEditedLinks.map((link, index) => (
                    <IconButton
                      key={index} // Ajoute une clé unique à chaque élément du tableau
                      className="copy-icon-controller --overidemui"
                      onMouseEnter={() => setOpenUrlTag(true)}
                      onMouseLeave={() => setOpenUrlTag(false)}
                      onClick={() => openLinksInNewWindow([link])} // Utilise une fonction fléchée pour éviter un appel immédiat
                    >
                      <OpenInNewIcon style={{ fontSize: "27.5px" }} />
                      {itemActuallyEditedLinks.length === 1 && openUrlTag && (
                        <div className="delete-appear --delete-editor">
                          Ouvrir
                        </div>
                      )}
                    </IconButton>
                  ))}
                </>
              )}
            <IconButton
              className="delete-icon-controller --overidemui"
              onClick={() => removeComponentToTrashBin({ ...data, path })}
              onMouseEnter={() => setDeleteTag(true)}
              onMouseLeave={() => setDeleteTag(false)}
            >
              <DeleteOutlineIcon style={{ fontSize: "27.5px" }} />
              {deleteTag && (
                <div className="delete-appear --delete-editor">Delete</div>
              )}
            </IconButton>
          </div>
        )}
      </div>
    </>
  );
};
export default Component;
