import DownloadIcon from "@mui/icons-material/Download";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import NewsletterService from "../../services/NewsletterService";
import { getAuthToken } from "../../utils/helpers";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

export default function ExportModal({
  setExportModalToggle,
  exportHTML,
  setNotificationToggle,
  idNews,
  NewsletterProperty,
}) {
  const { user } = useContext(AuthContext);

  return (
    <div
      className="export-modal-container"
      onClick={() => setExportModalToggle(false)}
    >
      <div className="export-modal-menu-items">
        <a onClick={() => exportHTML()} className="menu-item">
          <div className="icon-menu-item">
            <DownloadIcon sx={{ fontSize: "2rem" }} />
          </div>
          <div className="description-menu-item">
            <div className="export-description-title">Télécharger</div>
            <div className="export-description-desc">
              Obtenez un fichier zip avec HTML et images.
            </div>
          </div>
        </a>
        <div
          className="menu-item"
          onClick={() => {
            setNotificationToggle({
              state: true,
              title: "Votre newsletter est terminée !",
              message: `Vous pouvez modifier à tout moment le status de votre newsletter dans la section Paramètres de l'interface d'édition`,
            });
            // setModalToggle({
            //     severityAlert: "success",
            //     message: "Votre newsletter est terminée !",
            // });
            new NewsletterService(getAuthToken()).edit(idNews, {
              ...NewsletterProperty,
              status: "terminer",
            });
          }}
        >
          <div className="icon-menu-item">
            <SaveIcon sx={{ fontSize: "2rem" }} />
          </div>
          <div className="description-menu-item">
            <div className="export-description-title">Terminer</div>
            <div className="export-description-desc">
              Défini le status de la Newsletter de brouillon à terminer.
            </div>
          </div>
        </div>
        <div className="menu-item">
          <div className="icon-menu-item">
            <SendIcon sx={{ fontSize: "2rem" }} />
          </div>
          <div
            className="description-menu-item"
            onClick={async () => {
              const htmlString = await new NewsletterService(
                getAuthToken()
              ).convertLayoutToHTML({
                layout: NewsletterProperty?.layout,
                components: NewsletterProperty?.components,
                utmContent: NewsletterProperty?.utm_content,
                newsletterName: NewsletterProperty?.name,
              });
              new NewsletterService(getAuthToken()).sendBAT({
                html: htmlString,
                email: user?.email,
              });
              setNotificationToggle({
                state: true,
                title: "Votre mail de test a bien été envoyé !",
                message: `Si vous ne le trouvez pas dans votre boîte de réception vérifiez vos spam`,
              });
            }}
          >
            <div className="export-description-title">Envoi d'email test</div>
            <div className="export-description-desc">
              Exportez les conceptions vers votre service de messagerie.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
