import React, { useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { ROW } from "../../../constants";
import DropZone from "../dragAndDrop/DropZone";
import Column from "./Column";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const style = {};
const Row = ({
  data,
  handleDrop,
  path,
  removeToTrashBin,
  itemActuallyEdited,
  handleComponentEditable,
  itemActuallyEditedLinks,
}) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ROW,
      id: data.id,
      children: data.children,
      path,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const renderColumn = (column, currentPath) => {
    return (
      <Column
        key={column.id}
        data={column}
        handleDrop={handleDrop}
        path={currentPath}
        removeToTrashBin={removeToTrashBin}
        itemActuallyEdited={itemActuallyEdited}
        itemActuallyEditedLinks={itemActuallyEditedLinks}
        handleComponentEditable={handleComponentEditable}
      />
    );
  };

  const [deleteTag, setDeleteTag] = useState(false);
  const [duplicateTag, setDuplicateTag] = useState(false);

  function displayDeleteTag() {
    setDeleteTag(true);
    setDuplicateTag(false);
  }
  function displayDuplicateTag() {
    setDuplicateTag(true);
    setDeleteTag(false);
  }
  function disableTag() {
    setDuplicateTag(false);
    setDeleteTag(false);
  }

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        // zIndex: 1,
      }}
    >
      <div
        ref={ref}
        style={{ ...style, opacity }}
        onClick={(e) => handleComponentEditable(e, data.id)}
        className={`base draggable row ${
          itemActuallyEdited?.id == data.id ? "edit-active" : ""
        }`}
      >
        <div
          className="columns"
          style={{ flexDirection: "row" }}
          // onClick={displayNavbarMenu}
        >
          {data.children.map((column, index) => {
            const currentPath = `${path}-${index}`;

            return (
              <React.Fragment key={column.id}>
                <DropZone
                  data={{
                    path: currentPath,
                    childrenCount: data.children.length,
                  }}
                  onDrop={handleDrop}
                  className="horizontalDrag"
                />
                {renderColumn(column, currentPath)}
              </React.Fragment>
            );
          })}
          <DropZone
            data={{
              path: `${path}-${data.children.length}`,
              childrenCount: data.children.length,
            }}
            onDrop={handleDrop}
            className="horizontalDrag"
            isLast
          />
        </div>
      </div>
      {/* {isActiveEditMode && */}
      {itemActuallyEdited?.id == data.id && (
        <div className="delete-container">
          <div className="arrow-up">
            <svg
              viewBox="-0.2 0.9 15 8"
              width="18"
              height="10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M 14.431 7.926 L 8.612 2.107 C 8.232 1.727 7.619 1.716 7.225 2.082 L 0.931 7.926"
                strokeLinecap="round"
                fill="#FFFFFF"
              ></path>
              <path
                d="M 14.769 7.939 C 14.964 7.743 14.964 7.427 14.769 7.231 L 8.95 1.412 C 8.674 1.167 8.254 0.98 7.917 0.974 C 7.579 0.968 7.153 1.139 6.869 1.375 L 0.575 7.219 C 0.372 7.406 0.361 7.723 0.549 7.925 C 0.736 8.128 1.053 8.139 1.255 7.951 L 7.549 2.107 C 7.659 1.977 7.736 1.971 7.899 1.974 C 8.061 1.977 8.138 1.985 8.242 2.12 L 14.061 7.939 C 14.257 8.134 14.573 8.134 14.769 7.939 Z"
                fill="#FFFFFF"
              ></path>
              <path
                d="M 14.763 7.779 C 14.958 7.583 14.958 7.267 14.763 7.071 L 8.944 1.252 C 8.668 1.007 8.248 0.82 7.911 0.814 C 7.573 0.808 7.147 0.979 6.863 1.215 L 0.569 7.059 C 0.366 7.246 0.355 7.563 0.543 7.765 C 0.73 7.968 1.047 7.979 1.249 7.791 L 7.543 1.947 C 7.653 1.817 7.73 1.811 7.893 1.814 C 8.055 1.817 8.132 1.825 8.236 1.96 L 14.055 7.779 C 14.251 7.974 14.567 7.974 14.763 7.779 Z"
                fill="#DEDEDE"
              ></path>
            </svg>
          </div>
          <IconButton
            className="delete-icon-controller --overidemui"
            onClick={() => removeToTrashBin({ ...data, path })}
            onMouseEnter={displayDeleteTag}
            onMouseLeave={disableTag}
          >
            <DeleteOutlineIcon style={{ fontSize: "27.5px" }} />
            {deleteTag && <div className="delete-appear">Delete</div>}
          </IconButton>
          <IconButton
            className="copy-icon-controller --overidemui"
            onMouseEnter={displayDuplicateTag}
            onMouseLeave={disableTag}
          >
            <ContentCopyIcon />
            {duplicateTag && (
              <div
                className="delete-appear"
                style={{
                  width: "80px",
                  height: "30px",
                  left: "0px",
                }}
              >
                Duplicate
              </div>
            )}
          </IconButton>
        </div>
      )}
    </div>
  );
};
export default Row;
