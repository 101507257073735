import React, { useCallback, useContext, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { useParams } from "react-router-dom";
import { EditorContext } from "../../../context/EditorContext";
import { LangueContext } from "../../../context/LangueContext";
import NewsletterService from "../../../services/NewsletterService";
import TemplateService from "../../../services/TemplateService";

import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import TextEdit from "./componentProperty/TextEdit";
import ImageEdit from "./componentProperty/ImageEdit";
import LinkEdit from "./componentProperty/LinkEdit";
import SideBarItem from "./SideBarItem";
import DropZone from "../dragAndDrop/DropZone";
import Row from "../dragAndDrop/Row";

import shortid from "shortid";
import { SIDEBAR_ITEM, COMPONENT, COLUMN } from "../../../constants";
import {
  handleMoveWithinParent,
  handleMoveToDifferentParent,
  handleMoveSidebarComponentIntoParent,
  handleRemoveItemFromLayout,
} from "../helpers";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import {
  Box,
  IconButton,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
} from "@mui/material";

import AppsIcon from "@mui/icons-material/Apps";
import WidgetsIcon from "@mui/icons-material/Widgets";
import SettingsIcon from "@mui/icons-material/Settings";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DropDownComponents from "../dragAndDrop/DropDownComponents";
import ComponentService from "../../../services/ComponentService";
import moment from "moment";
import ProductCheckbox from "./componentProperty/ProductCheckbox";
import { AuthContext } from "../../../context/AuthContext";
import { getAuthToken } from "../../../utils/helpers";
import ExportModal from "../ExportModal";

export default function SideBarSettingsNewsletter() {
  const { NewsletterProperty, setNewsletterProperty } =
    useContext(EditorContext);
  const { newsletterId, templateId } = useParams();
  const { setToggleDeleteModal } = useContext(EditorContext);

  return (
    <div className="sideBar-content-container">
      <div className="sideBar-content-container-settings">
        {/* NOM */}
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <TextField
            label={`Nom`}
            variant="outlined"
            value={NewsletterProperty?.name}
            onChange={(e) =>
              setNewsletterProperty({
                ...NewsletterProperty,
                name: e.target.value,
              })
            }
          />
        </FormControl>
        {newsletterId && (
          <>
            {/* UTM CONTENT */}
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel>Utm Content</InputLabel>
              <Select
                value={NewsletterProperty?.utm_content}
                label="Utm Content"
                onChange={(e) =>
                  setNewsletterProperty({
                    ...NewsletterProperty,
                    utm_content: e.target.value,
                  })
                }
              >
                <MenuItem value={"commerciale"}>commerciale</MenuItem>
                <MenuItem value={"cycle_relationnel"}>
                  cycle_relationnel
                </MenuItem>
                <MenuItem value={"cross_selling"}>cross_selling</MenuItem>
              </Select>
            </FormControl>
            {/* DATE D'ENVOI */}
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={moment(NewsletterProperty.date_envoi)}
                  onChange={(newValue) => {
                    const newDate = moment(newValue.toDate()).format(
                      "YYYY-MM-DD"
                    );
                    setNewsletterProperty({
                      ...NewsletterProperty,
                      date_envoi: newDate,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
            {/* STATUS */}
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={NewsletterProperty?.status}
                label="Status"
                onChange={(e) =>
                  setNewsletterProperty({
                    ...NewsletterProperty,
                    status: e.target.value,
                  })
                }
              >
                <MenuItem value={"brouillon"}>brouillon</MenuItem>
                <MenuItem value={"terminer"}>terminer</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        {/* CTA SUPPRIMER */}
        <FormControl>
          <Button
            variant="contained"
            color="error"
            onClick={() => setToggleDeleteModal(true)}
          >
            Supprimer
          </Button>
        </FormControl>
      </div>
    </div>
  );
}
