import React, { useCallback, useContext, useEffect, useState } from "react";
import { EditorContext } from "../../context/EditorContext";
import NewsletterService from "../../services/NewsletterService";
import TemplateService from "../../services/TemplateService";
import { Link, useParams } from "react-router-dom";
import DropZone from "./dragAndDrop/DropZone";
import Row from "./dragAndDrop/Row";
import SideBarEditComponent from "./sidebar/SideBarEditComponent";
import SideBarListComponent from "./sidebar/SideBarListComponent";
import SideBarNavItem from "./sidebar/SideBarNavItem";
import SideBarSettingsNewsletter from "./sidebar/SideBarSettingsNewsletter";

import shortid from "shortid";
import { SIDEBAR_ITEM, COMPONENT, COLUMN } from "../../constants";
import {
  handleMoveWithinParent,
  handleMoveToDifferentParent,
  handleMoveSidebarComponentIntoParent,
  handleRemoveItemFromLayout,
} from "./helpers";

import { Button } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import ComponentService from "../../services/ComponentService";
import { getAuthToken } from "../../utils/helpers";
import ExportModal from "./ExportModal";
import WidgetsIcon from "@mui/icons-material/Widgets";
import SettingsIcon from "@mui/icons-material/Settings";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import AppsIcon from "@mui/icons-material/Apps";

const sideBarModes = [
  {
    title: "Contenu",
    icon: <WidgetsIcon />,
    disabled: false,
  },
  {
    title: "Paramètres",
    icon: <SettingsIcon />,
    disabled: false,
  },
  {
    title: "Blocs",
    icon: <AppsIcon />,
    disabled: true,
  },
  {
    title: "Images",
    icon: <ImageSearchIcon />,
    disabled: true,
  },
];

export default function Builder({ idNews, langue }) {
  const {
    NewsletterProperty,
    setNewsletterProperty,
    Components,
    setComponents,
    itemActuallyEdited,
    itemActuallyEditedLinks,
    setitemActuallyEdited,
    handleComponentEditable,
    setNotificationToggle,
  } = useContext(EditorContext);
  const currentUrl = window.location.href;
  const [IsNewsletterPage, setIsNewsletterPage] = useState(
    currentUrl.includes("editor")
  );
  const params = useParams();
  // console.log("isNewsletterPage", IsNewsletterPage);

  const paramName = Object.keys(params)[0];
  const [sideBarWidth, setSideBarWidth] = useState("425px");

  function toggleSideBarWidth() {
    if (sideBarWidth === "700px") {
      setSideBarWidth("425px");
      return;
    }
    setSideBarWidth("700px");
  }

  useEffect(() => {
    paramName === "newsletterId"
      ? setIsNewsletterPage(true)
      : setIsNewsletterPage(false);
    const showConfirmation = (e) => {
      const confirmationMessage =
        "Êtes-vous sûr de vouloir quitter ? Vous pourriez perdre votre progression.";
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener("beforeunload", showConfirmation);

    return () => {
      window.removeEventListener("beforeunload", showConfirmation);
    };
  }, []);

  const [SidebarMode, setSidebarMode] = useState(sideBarModes[0].title);
  // Get components filter with langue
  const getAllComponents = async () => {
    const response = await new ComponentService(getAuthToken()).getAllV2(
      langue
    );
    if (response?.status === 200) {
      setComponents(response.data.data);
    }
  };
  useEffect(() => {
    getAllComponents();
  }, [langue]);
  // Exporter, Sauvegarder, Envoi test
  const [ExportModalToggle, setExportModalToggle] = useState(false);
  const exportHTML = async () => {
    const htmlString = await new NewsletterService(
      getAuthToken()
    ).convertLayoutToHTML({
      newsletterName: NewsletterProperty?.name,
      layout: NewsletterProperty?.layout,
      components: NewsletterProperty?.components,
      utmContent: NewsletterProperty?.utm_content,
    });
    await new NewsletterService(getAuthToken()).downloadNewsletter({
      newsletterName: NewsletterProperty?.name,
      html: htmlString,
      utmContent: NewsletterProperty?.utm_content,
    });
    setNotificationToggle({
      state: true,
      title: "Votre newsletter a bien été téléchargée !",
      message:
        "Retrouvez la dans le dossier téléchargement de votre ordinateur",
    });
  };
  const saveHTML = async () => {
    let message;
    if (!IsNewsletterPage) {
      message = "Votre Template a bien été enregistrer !";
      await new TemplateService(getAuthToken()).edit(idNews, {
        ...NewsletterProperty,
        content: await new NewsletterService(
          getAuthToken()
        ).convertLayoutToHTML({
          layout: NewsletterProperty?.layout,
          components: NewsletterProperty?.components,
          utmContent: NewsletterProperty?.utm_content,
          newsletterName: NewsletterProperty?.name,
        }),
      });
    }
    if (IsNewsletterPage) {
      message = "Votre newsletter a bien été enregistrée !";
      await new NewsletterService(getAuthToken()).edit(idNews, {
        ...NewsletterProperty,
        content: await new NewsletterService(
          getAuthToken()
        ).convertLayoutToHTML({
          layout: NewsletterProperty?.layout,
          components: NewsletterProperty?.components,
          utmContent: NewsletterProperty?.utm_content,
          newsletterName: NewsletterProperty?.name,
        }),
      });
    }
    setNotificationToggle({
      state: true,
      title: message,
    });
  };

  const setLayoutAndComponents = ({ newComponents, newLayout }) => {
    setNewsletterProperty({
      ...NewsletterProperty,
      components: newComponents,
      layout: newLayout,
    });
  };
  const removeToTrashBin = (item) => {
    const splitItemPath = item.path.split("-");
    setLayoutAndComponents({
      newComponents: NewsletterProperty?.components,
      newLayout: handleRemoveItemFromLayout(
        NewsletterProperty?.layout,
        splitItemPath
      ),
    });
  };
  const handleDrop = useCallback(
    (dropZone, item) => {
      const splitDropZonePath = dropZone.path.split("-");
      const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");

      const newItem = { id: item.id, type: item.type };
      if (item.type === COLUMN) newItem.children = item.children;

      // sidebar into
      if (item.type === SIDEBAR_ITEM) {
        // 1. Move sidebar item into page
        const newComponent = {
          id: shortid.generate(),
          ...item.component,
        };
        const newItem = {
          id: newComponent.id,
          type: COMPONENT,
        };
        setLayoutAndComponents({
          newComponents: [...NewsletterProperty?.components, newComponent],
          newLayout: handleMoveSidebarComponentIntoParent(
            NewsletterProperty?.layout,
            splitDropZonePath,
            newItem
          ),
        });
        return;
      }

      // move down here since sidebar items dont have path
      const splitItemPath = item.path.split("-");
      const pathToItem = splitItemPath.slice(0, -1).join("-");

      // 2. Pure move (no create)
      if (splitItemPath.length === splitDropZonePath.length) {
        // 2.a. move within parent
        if (pathToItem === pathToDropZone) {
          setLayoutAndComponents({
            newComponents: NewsletterProperty?.components,
            newLayout: handleMoveWithinParent(
              NewsletterProperty?.layout,
              splitDropZonePath,
              splitItemPath
            ),
          });
          return;
        }

        // 2.b. OR move different parent
        // TODO FIX columns. item includes children
        setLayoutAndComponents({
          newComponents: NewsletterProperty?.components,
          newLayout: handleMoveToDifferentParent(
            NewsletterProperty?.layout,
            splitDropZonePath,
            splitItemPath,
            newItem
          ),
        });
        return;
      }

      // 3. Move + Create
      setLayoutAndComponents({
        newComponents: NewsletterProperty?.components,
        newLayout: handleMoveToDifferentParent(
          NewsletterProperty?.layout,
          splitDropZonePath,
          splitItemPath,
          newItem
        ),
      });
    },
    [NewsletterProperty?.layout, NewsletterProperty?.components]
  );

  const renderRow = (row, currentPath) => {
    return (
      <Row
        key={row.id}
        data={row}
        components={NewsletterProperty?.components}
        path={currentPath}
        removeToTrashBin={removeToTrashBin}
        itemActuallyEdited={itemActuallyEdited}
        itemActuallyEditedLinks={itemActuallyEditedLinks}
        handleComponentEditable={handleComponentEditable}
      />
    );
  };

  return (
    <>
      {/* NAVBAR NAVIGATION LINK */}
      <div className="navbar-editor bgcolor-langue">
        <div className="link-navbar-navigation-wrapper">
          <Link to="/" className="link-navbar-navigation-item --first">
            <NoteAddIcon />
            <p>Templates</p>
          </Link>
          <Link to="/newsletters" className="link-navbar-navigation-item">
            <FindInPageIcon />
            <p>Newsletters</p>
          </Link>
        </div>
        {/* NAVBAR BUTTON CONTROLLER */}
        <div className="navbar-editor-controller">
          <Button
            onClick={() => saveHTML()}
            className="navbar-btn"
            variant="outlined"
          >
            Sauvegarder
          </Button>
          {IsNewsletterPage && (
            <Button
              onClick={() => setExportModalToggle(true)}
              className="navbar-btn"
              variant="outlined"
            >
              Exporter
            </Button>
          )}
        </div>
      </div>
      {ExportModalToggle && (
        <ExportModal
          setExportModalToggle={setExportModalToggle}
          exportHTML={exportHTML}
          setNotificationToggle={setNotificationToggle}
          idNews={idNews}
          NewsletterProperty={NewsletterProperty}
        />
      )}
      <div className="editor" onClick={() => setExportModalToggle(false)}>
        {/* BUILDER */}
        <div
          className="newsletter-container"
          onClick={() => setitemActuallyEdited(null)}
          // HIDE PROPERTIES
          style={{
            zIndex: "1202",
            inset: "65px 425px 0px 0px",
          }}
        >
          <div
            className="page"
            style={{
              width: "750px",
              outline: "none",
            }}
          >
            <div className="body" style={{ width: "750px" }}>
              {NewsletterProperty?.layout && (
                <>
                  {NewsletterProperty?.layout.map((row, index) => {
                    const currentPath = `${index}`;

                    return (
                      <React.Fragment key={row.id}>
                        <DropZone
                          data={{
                            path: currentPath,
                            childrenCount: NewsletterProperty?.layout.length,
                          }}
                          onDrop={handleDrop}
                          path={currentPath}
                        />
                        {renderRow(row, currentPath)}
                      </React.Fragment>
                    );
                  })}
                  <DropZone
                    data={{
                      path: `${NewsletterProperty?.layout.length}`,
                      childrenCount: NewsletterProperty?.layout.length,
                    }}
                    onDrop={handleDrop}
                    isLast
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {/* SIDEBAR */}
        <div
          className="sideBar"
          onClick={() => setExportModalToggle(false)}
          style={{
            width:
              itemActuallyEdited == null ||
              itemActuallyEdited?.editable === false
                ? "425px"
                : sideBarWidth,
          }}
        >
          {itemActuallyEdited != null &&
            itemActuallyEdited?.editable === true && (
              <SideBarEditComponent toggleSideBarWidth={toggleSideBarWidth} />
            )}
          {(itemActuallyEdited == null ||
            itemActuallyEdited?.editable === false) && (
            <>
              <div style={{ display: "flex" }}>
                {SidebarMode === "Contenu" && (
                  <SideBarListComponent Components={Components} />
                )}
                {SidebarMode === "Paramètres" && <SideBarSettingsNewsletter />}
                <div className="sideBar-nav-container">
                  {sideBarModes.map((mode, i) => {
                    return (
                      <SideBarNavItem
                        key={i}
                        SidebarMode={SidebarMode}
                        mode={mode}
                        setSidebarMode={setSidebarMode}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
