import { useState, useContext } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import UserService from "../services/UserService";
import { setAuthToken } from "../utils/helpers";
import { AuthContext } from "../context/AuthContext";

export default function Login() {
  const { setUser } = useContext(AuthContext);

  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [errorState, setErrorState] = useState({
    email: false,
    password: false,
  });

  const onFinish = async (event, values) => {
    event.preventDefault();
    const response = await UserService.login({ identifier, password });
    if (response?.status === 200) {
      // localStorage.setItem("defaultlangue", "FR");
      setAuthToken(response?.data?.jwt);
      setUser(response?.data.user);
      return;
    }
    setErrorState({ ...errorState, password: true });
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundColor: "#041E39",
          backgroundRepeat: "no-repeat",
          backgroundSize: "60%",
          backgroundPosition: "center",
          backgroundImage:
            "url(https://voyages.carrefour.fr/carrefour/images/header/logo-carrefour-voyages.svg)",
        }}
      />
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 24,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h4">
            Connexion
          </Typography>
          <Box component="form" noValidate onSubmit={onFinish} sx={{ mt: 1 }}>
            <TextField
              id="identifiant"
              label="Identifiant"
              name="Nom d'utilisateur"
              type="text"
              margin="normal"
              fullWidth
              required
              onChange={(e) => setIdentifier(e.target.value)}
              onBlur={() => {
                identifier.length < 3
                  ? setErrorState({ ...errorState, identifier: true })
                  : setErrorState({ ...errorState, identifier: false });
              }}
              error={errorState.identifier || errorState.password}
              helperText={
                errorState.identifier === true &&
                "Le nom d'utilisateur est trop court"
              }
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              error={errorState.password}
              helperText={
                errorState.password === true &&
                "Vos identifiants sont incorrect"
              }
            />
            {/* <FormControlLabel
                          control={<Checkbox value="remember" color="primary" />}
                          label="Remember me"
                      /> */}
            <Button
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              sx={{ mt: 5, mb: 2 }}
            >
              Connexion
            </Button>
            {/* <Grid container>
                          <Grid item xs>
                          <Link href="#" variant="body2">
                              Forgot password?
                          </Link>
                          </Grid>
                          <Grid item>
                          <Link href="#" variant="body2">
                              {"Don't have an account? Sign Up"}
                          </Link>
                          </Grid>
                      </Grid> */}
            {/* <Copyright sx={{ mt: 5 }} /> */}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
