import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import MailIcon from "@mui/icons-material/Mail";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";

export default function BasicBreadcrumbs() {
  const [pathNames, setPathNames] = useState(null);
  useEffect(() => {
    const getPathName = window.location.pathname;
    const pathNameFormat = getPathName.split("/").filter((path) => path !== "");
    setPathNames(pathNameFormat);
  }, []);

  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          href="/"
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          accueil
        </Link>
        {pathNames &&
          pathNames?.length !== 0 &&
          pathNames.map((path, i) => {
            return (
              <Typography
                key={i}
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color={i === pathNames.length ? "text.primary" : "inherit"}
              >
                {path === "newsletters" || path === "templates" ? (
                  <MailIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                ) : null}
                {path === "settings" && (
                  <SettingsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                )}
                {path === "profile" && (
                  <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                )}
                {path}
              </Typography>
            );
          })}
      </Breadcrumbs>
    </div>
  );
}
