import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LangueContext } from "../context/LangueContext";
import NewsletterService from "../services/NewsletterService";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { Button, Switch, Paper, InputBase, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Card from "./Card";
import Navbar from "../components/Navbar";

import moment from "moment";
import BasicBreadcrumbs from "../components/Breadcrumbs";
import { getAuthToken } from "../utils/helpers";
import { CircularProgress } from "@mui/joy";

moment.locale("fr");

function NewsletterList() {
  // Redirect
  const navigate = useNavigate();

  // Langue
  const { langue, setLangue } = useContext(LangueContext);

  const [newsletterList, setNewsletterList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // GET NEWSLETTER

  const [selectedNewsletters, setSelectedNewsletters] = useState([]);

  const getNewsletters = async () => {
    setIsLoading(true);
    const response = await new NewsletterService(getAuthToken()).getAll(langue);
    if (response.status === 200) setNewsletterList(response.data.data);
    setIsLoading(false);
  };

  // SEARCH BAR NEWSLETTER CONTROLLER

  const [currentSearchValue, setCurrentSearchValue] = useState(null);

  const getNewslettersByName = async (name) => {
    const response = await new NewsletterService(getAuthToken()).getByName(
      name,
      langue
    );
    if (response.status === 200) setNewsletterList(response.data.data);
  };
  useEffect(() => {
    getNewsletters();
  }, [langue]);

  useEffect(() => {
    if (currentSearchValue === null) return;
    getNewslettersByName(currentSearchValue);
  }, [currentSearchValue]);

  // DELETE CHECKBOX CONTROLLER

  const handleDeleteSelected = async () => {
    for (const id of selectedNewsletters) {
      await new NewsletterService(getAuthToken()).delete(id);
    }
    setSelectedNewsletters([]);
    getNewsletters(); // Pour rafraîchir la liste des newsletters après la suppression
  };

  // PREVIEW CONTROLLER SWITCH

  const [displayPreview, setDisplayPreview] = useState(false);

  const handleSwitchChange = (e, val) => {
    setDisplayPreview(val);
  };

  // Function to sort newsletters by their createdAt date in descending order (most recent first)

  const sortNewslettersByDate = (a, b) => {
    const dateA = moment(a.attributes.createdAt);
    const dateB = moment(b.attributes.createdAt);
    return dateB.diff(dateA);
  };

  return (
    <>
      <Navbar />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 10 }}
        style={{ marginLeft: "50px" }}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={langue}>
            <BasicBreadcrumbs />
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={(event, newValue) => setLangue(newValue)}
                aria-label="lab API tabs example"
              >
                <Tab label="FR" value="FR" />
                <Tab label="BE" value="BE" />
                <Tab label="NL" value="NL" />
              </TabList>
            </Box>
            {/* France */}
            <TabPanel value={langue} className="tabPanel">
              <h2>Newsletter {langue}</h2>
              {isLoading && (
                <CircularProgress color="primary" sx={{ marginTop: "5rem" }} />
              )}
              {!isLoading && (
                <>
                  <div className="card-preview-switchButton-container">
                    <p className="switchButton-label">Afficher les titres</p>
                    <Switch onChange={handleSwitchChange} />
                  </div>
                  {selectedNewsletters.length > 0 && (
                    <Button
                      className="delete-selected-elements"
                      variant="contained"
                      onClick={handleDeleteSelected}
                    >
                      Supprimer la sélection
                    </Button>
                  )}
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: 400,
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Recherchez une newsletter..."
                      onChange={(e) => {
                        setCurrentSearchValue(e.target.value);
                      }}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                  <div className="containercard">
                    <div className="card">
                      <div
                        className="preview-edit"
                        style={{
                          display: "flex",
                          gap: "20px",
                        }}
                      >
                        <Button
                          className="bgcolor-langue shadowcolor-langue"
                          startIcon={<AddIcon />}
                          variant="contained"
                          onClick={() => navigate("/templates")}
                        >
                          créer une newsletter
                        </Button>
                      </div>
                    </div>
                    {newsletterList.length > 0 &&
                      newsletterList
                        .sort(sortNewslettersByDate) // Sort newsletters by date before displaying
                        .map((value, index) => (
                          <Card
                            key={index}
                            value={value}
                            index={index}
                            langue={langue}
                            selectedNewsletters={selectedNewsletters}
                            setSelectedNewsletters={setSelectedNewsletters}
                            displayPreview={displayPreview}
                            isLittleVersion={index < 4 ? false : true}
                            isNewsletterCard={true}
                          />
                        ))}
                  </div>
                </>
              )}
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  );
}

export default NewsletterList;
