import { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";

const LangueContext = createContext();

const LangueContextProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [langue, setLangue] = useState("FR"); // Valeur par défaut
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user?.defaultLanguage) {
      setLangue(user.defaultLanguage);
    }
    setIsLoading(false);
  }, [user]);

  if (isLoading) {
    // Vous pouvez personnaliser ce que vous affichez pendant le chargement
    return <div>Loading...</div>;
  }

  return (
    <LangueContext.Provider value={{ langue, setLangue }}>
      <div className={langue}>{children}</div>
    </LangueContext.Provider>
  );
};

export { LangueContext, LangueContextProvider };
