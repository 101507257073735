import { useEffect, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import TemplatesList from "./pages/TemplatesList";
import NewsletterList from "./pages/NewsletterList";
import { LangueContextProvider } from "./context/LangueContext";
import { AuthContext } from "./context/AuthContext";
import { CircularProgress } from "@mui/joy";
import UserSettings from "./pages/UserSettings";
import { EditorContextProvider } from "./context/EditorContext";
import Layout from "./components/Editor/Layout";

function App() {
  const { user, isLoading } = useContext(AuthContext);

  useEffect(() => {
    // https://github.com/react-dnd/react-dnd/issues/3178
    if (window.__isReactDndBackendSetUp) {
      window.__isReactDndBackendSetUp = false;
    }
  }, []);

  return (
    <LangueContextProvider>
      {isLoading && (
        <CircularProgress color="secondary" className="loader-global" />
      )}
      {!user && <Login />}
      {user && (
        <Routes>
          <Route path="/" element={<Navigate to="/templates" />} />
          <Route path="/templates" element={<TemplatesList />} />
          <Route path="/newsletters" element={<NewsletterList />} />
          <Route path="/settings/profile" element={<UserSettings />} />
          <Route
            path="/createTemplate/:templateId"
            element={
              <EditorContextProvider>
                <Layout />
              </EditorContextProvider>
            }
          />
          <Route
            path="/editor/:newsletterId"
            element={
              <EditorContextProvider>
                <Layout />
              </EditorContextProvider>
            }
          />
        </Routes>
      )}
    </LangueContextProvider>
  );
}
export default App;
