export default function SideBarNavItem({ mode, SidebarMode, setSidebarMode }) {
  return (
    <div
      className={`sideBar-nav-container-item ${
        mode.disabled ? "--block" : ""
      } ${SidebarMode === mode.title ? "active" : ""}`}
      onClick={() => setSidebarMode(mode.title)}
    >
      {mode.icon}
      {mode.title}
    </div>
  );
}
