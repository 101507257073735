import React from "react";
import Checkbox from "@mui/material/Checkbox";

const CheckboxController = ({
  index,
  isChecked,
  onCheckboxChange,
  isSelected,
  onSelect,
}) => {
  const handleCheckboxChange = () => {
    onCheckboxChange(index);
    onSelect();
  };

  return (
    <div
      className={`newsletter-checkbox bgcolor-langue ${
        isChecked ? "--checked" : ""
      }`}
    >
      <Checkbox
        className="checkbox-item-select"
        checked={isSelected}
        onChange={handleCheckboxChange}
      />
    </div>
  );
};

export default CheckboxController;
