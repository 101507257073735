import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { COLUMN } from "../../../constants";
import DropZone from "../dragAndDrop/DropZone";
import Component from "./Component";

const style = {};
const Column = ({
  data,
  components,
  handleDrop,
  path,
  setComponents,
  removeToTrashBin,
  itemActuallyEdited,
  itemActuallyEditedLinks,
  handleComponentEditable,
  displayNavbarMenu,
}) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: COLUMN,
      id: data.id,
      children: data.children,
      path,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const renderComponent = (component, currentPath, displayNavbarMenu) => {
    return (
      <Component
        key={component.id}
        data={component}
        components={components}
        setComponents={setComponents}
        path={currentPath}
        removeToTrashBin={removeToTrashBin}
        itemActuallyEdited={itemActuallyEdited}
        itemActuallyEditedLinks={itemActuallyEditedLinks}
        handleComponentEditable={handleComponentEditable}
        displayNavbarMenu={displayNavbarMenu}
      />
    );
  };

  return (
    <div
      ref={ref}
      style={{ ...style, opacity }}
      className="base draggable column"
      onClick={displayNavbarMenu}
    >
      {/* {data.id} */}
      {data.children.map((component, index) => {
        const currentPath = `${path}-${index}`;

        return (
          <React.Fragment key={component.id}>
            {/* <DropZone
              data={{
                path: currentPath,
                childrenCount: data.children.length,
              }}
              onDrop={handleDrop}
            /> */}
            {renderComponent(component, currentPath)}
          </React.Fragment>
        );
      })}
      {/* <DropZone
        data={{
          path: `${path}-${data.children.length}`,
          childrenCount: data.children.length,
        }}
        onDrop={handleDrop}
        isLast
      /> */}
    </div>
  );
};
export default Column;
