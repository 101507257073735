import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import moment from "moment";
import { useEffect, useState } from "react";

const filter = createFilterOptions();

export default function AutoCompletion({ setNameNewsletter, dateEnvoi }) {
  const suggestList = [
    "Bon_plans",
    "Weekendesk",
    "DM",
    "VF",
    "Focus_destination",
  ];

  const [displayValue, setDisplayValue] = useState(null);

  useEffect(() => {
    if (displayValue === null) {
      const currentDate = moment(dateEnvoi).format("YYYYMMDD");
      const updatedValue = `${currentDate}_`;
      setNameNewsletter(updatedValue);
    }

    if (displayValue) {
      const currentDate = moment(dateEnvoi).format("YYYYMMDD");
      const updatedValue = `${currentDate}_${displayValue}`;
      setNameNewsletter(updatedValue);
    }
  }, [setNameNewsletter, displayValue, dateEnvoi]);

  return (
    <Autocomplete
      value={displayValue}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          const currentDate = moment(dateEnvoi).format("YYYYMMDD");
          const updatedValue = `${currentDate}_${newValue}`;
          setNameNewsletter(updatedValue);
          setDisplayValue(newValue); // Mise à jour de la valeur affichée
        } else if (newValue && newValue.inputValue) {
          const currentDate = moment(dateEnvoi).format("YYYYMMDD");
          const updatedValue = `${currentDate}_${newValue.inputValue}`;
          setNameNewsletter(updatedValue);
          setDisplayValue(newValue.inputValue); // Mise à jour de la valeur affichée
        } else {
          setNameNewsletter(newValue);
          setDisplayValue(newValue); // Mise à jour de la valeur affichée
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option);
        if (inputValue !== "" && !isExisting) {
          filtered.push(inputValue);
        }

        return filtered;
      }}
      sx={{ width: 300 }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={suggestList}
      getOptionLabel={(option) => option} // Utiliser l'option directement comme libellé
      isOptionEqualToValue={
        (option, value) => option.startsWith(value) // Comparaison personnalisée pour tenir compte du préfixe de date
      }
      renderInput={(params) => <TextField {...params} label="Suggestions" />}
    />
  );
}
