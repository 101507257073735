import axios from "axios";
import { BEARER, API_BASE_URL } from "../constants";

class TemplateService {
  constructor(jwtToken) {
    this.http = axios.create({
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${BEARER} ${jwtToken}`,
      },
    });
  }
  async createTemplate({ name, langue }) {
    const response = await this.http
      .post(`templates`, {
        data: {
          name: name,
          langue: langue,
        },
      })
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  }
  async getAll(langue) {
    const response = await this.http
      .get(`/templates?filters[langue][$eq]=${langue}`)
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  }
  async getOne(id) {
    const response = await this.http
      .get(`/templates/${id}`)
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        console.log("error", error);
        return error.response;
      });
    return response;
  }
  async delete(id) {
    const response = await this.http
      .delete(`/templates/${id}`)
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  }
  async edit(id, { name, layout, components, content }) {
    const response = await this.http
      .put(`templates/${id}`, {
        data: {
          name,
          layout: JSON.stringify(layout),
          components: JSON.stringify(components),
          content: content,
        },
      })
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  }
}

export default TemplateService;
