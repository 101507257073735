import { useContext, useEffect, useRef, useState } from "react"
import { Editor } from "@tinymce/tinymce-react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { SketchPicker } from "react-color"
import IncrementButton from "./IncrementButton"
import ColorPicker from "./ColorPicker"
import DropDown from "./DropDown"
import AlignButtons from "./AlignButtons"
import { EditorContext } from "../../../../context/EditorContext"
function TextEdit({
  InputKey,
  setInputKey,
  TextInputsEditable,
  setTextInputsEditable,
}) {
  const { ProductInputsEditable, setProductInputsEditable } =
    useContext(EditorContext)
  const [value, setValue] = useState("")
  const dropDownLabelFontFamily = [
    {
      label: "Arial",
      value: 1,
      labelstyle: {
        fontFamily: "Arial, Helvetica, sans-serif",
      },
    },
    {
      label: "Verdana",
      value: 2,
      labelstyle: {
        fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
      },
    },
    {
      label: "Tahoma",
      value: 3,
      labelstyle: {
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      },
    },
    {
      label: "Trebuchet MS",
      value: 4,
      labelstyle: {
        fontFamily:
          "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
      },
    },
    {
      label: "Times New Roman",
      value: 5,
      labelstyle: {
        fontFamily: "'Times New Roman', Times, serif",
      },
    },
  ]
  const dropDownLabelFontWeight = [
    {
      label: "Normal",
      value: 1,
      labelstyle: {
        fontWeight: "400",
      },
    },
    {
      label: "Gras",
      value: 2,
      labelstyle: {
        fontWeight: "bold",
      },
    },
  ]
  // console.log("TextInputsEditable", TextInputsEditable);
  const [currentColor, setCurrentColor] = useState("#FFFFFF")
  const [displayResetColorBtn, setDisplayResetColorBtn] = useState(false)
  const [disableRedBarPicker, setDisableRedBarPicker] = useState("flex")
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const colorOnChange = (color) => {
    setCurrentColor(color.hex)
    setDisableRedBarPicker("none")
    setDisplayResetColorBtn("flex")
    console.log(color)
  }
  const displaySketchPicker = () => {
    if (displayColorPicker === false) {
      setDisplayColorPicker(true)
    }
  }
  const disableColorPicker = () => {
    if (displayColorPicker === true) {
      setDisplayColorPicker(false)
      console.log(displayColorPicker)
    }
  }
  const resetColorPicker = () => {
    setDisplayColorPicker(false)
    setDisableRedBarPicker("flex")
    setCurrentColor("#FFFFFF")
    setDisplayResetColorBtn(false)
    console.log("triggered bg color")
  }
  const [fontSizeIncrementValue, setFontSizeIncrementValue] = useState(14)
  const [lineHeightIncrementValue, setLineHeightIncrementValue] = useState(140)

  // Utile pour trouver l'index du deuxième "Titre produit"
  const secondTitreProduitIndex = TextInputsEditable.findIndex(
    (value, index) => index > 0 && value?.contentTxt === "Titre produit"
  )
  // console.log("ProductInputsEditable", ProductInputsEditable)
  const secondTitreProduit = useRef(secondTitreProduitIndex)

  // Utilise useEffect pour mettre à jour la référence du useRef lorsque TextInputsEditable change
  useEffect(() => {
    // Trouve le nouvel index de la deuxième occurrence de "Titre produit"
    const newSecondTitreProduitIndex = TextInputsEditable.findIndex(
      (value, index) =>
        (index > 1 &&
          (value?.contentTxt === "Titre produit" ||
            value?.contentTxt.includes('<div id="Titre"'))) ||
        value?.contentTxt === "<div>Titre produit</div>"
    )
    // console.log("newSecondTitreProduitIndex", newSecondTitreProduitIndex)
    // console.log("TextInputEditable", TextInputsEditable)
    // Met à jour la référence du useRef
    secondTitreProduit.current = newSecondTitreProduitIndex
  }, [TextInputsEditable]) // Déclenche l'effet lorsque TextInputsEditable change
  // console.log("secondTitreProduit", secondTitreProduit)

  if (TextInputsEditable.length === 0) return <></>
  return (
    <>
      <div>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="accordeon-button --overidemui"
          >
            <Typography className="accordeon-label --overidemui">
              Texte
            </Typography>
          </AccordionSummary>
          {/* Property */}
          {value === `ne pas afficher pour l'instant` && (
            <>
              <AccordionDetails className="accordeon-details-container --overidemui">
                <div className="text-properties-container --overidemui">
                  {/* FONT FAMILY */}
                  <div
                    className="backgroundcolor-container-controller --overidemui"
                    onClick={disableColorPicker}
                  >
                    <p
                      className="tabs-element-label-property --overidemui"
                      onClick={disableColorPicker}
                    >
                      Famille de polices
                    </p>
                    <DropDown label={dropDownLabelFontFamily} />
                  </div>
                  {/* FONT WEIGHT */}
                  <div
                    className="backgroundcolor-container-controller --overidemui"
                    onClick={disableColorPicker}
                  >
                    <p
                      className="tabs-element-label-property --overidemui"
                      onClick={disableColorPicker}
                    >
                      Gras de la police
                    </p>
                    <DropDown label={dropDownLabelFontWeight} />
                  </div>
                  {/* FONT SIZE */}
                  <div
                    className="backgroundcolor-container-controller --overidemui"
                    onClick={disableColorPicker}
                  >
                    <p
                      className="tabs-element-label-property --overidemui"
                      onClick={disableColorPicker}
                    >
                      Taille de police
                    </p>
                    <IncrementButton defaultValue={fontSizeIncrementValue} />
                  </div>
                  {/* TEXT COLOR */}
                  <div
                    className="backgroundcolor-container-controller --overidemui"
                    onClick={disableColorPicker}
                  >
                    <p
                      className="tabs-element-label-property --overidemui"
                      onClick={disableColorPicker}
                    >
                      Couleur
                    </p>
                    <ColorPicker
                      resetColorPicker={resetColorPicker}
                      displayResetColorBtn={displayResetColorBtn}
                      disableColorPicker={disableColorPicker}
                      currentColor={currentColor}
                      displaySketchPicker={displaySketchPicker}
                      disableRedBarPicker={disableRedBarPicker}
                    />
                  </div>
                  {displayColorPicker && (
                    <SketchPicker
                      color={currentColor}
                      onChangeComplete={colorOnChange}
                      className="sketchpicker-bg-color"
                    />
                  )}
                  {/* TEXT ALIGN */}
                  <div
                    className="backgroundcolor-container-controller --overidemui"
                    onClick={disableColorPicker}
                  >
                    <p
                      className="tabs-element-label-property --overidemui"
                      onClick={disableColorPicker}
                    >
                      Alignement
                    </p>
                    <AlignButtons />
                  </div>
                  {/* LINE HEIGHT */}
                  <div
                    className="backgroundcolor-container-controller --overidemui"
                    onClick={disableColorPicker}
                    style={{ borderBottom: "none", paddingBottom: "0px" }}
                  >
                    <p
                      className="tabs-element-label-property --overidemui"
                      onClick={disableColorPicker}
                    >
                      Interligne
                    </p>
                    <IncrementButton defaultValue={lineHeightIncrementValue} />
                  </div>
                </div>
              </AccordionDetails>
            </>
          )}
          {/* Content */}
          {TextInputsEditable.length > 0 && (
            <>
              <AccordionDetails className="accordeon-details-container --overidemui">
                <div className="text-properties-container --overidemui">
                  {TextInputsEditable.map((inputEditable, i) => {
                    return (
                      <div
                        key={`inputEditable-text-${i}`}
                        className={`backgroundcolor-container-controller --overidemui ${
                          i === secondTitreProduit?.current ? "--before" : ""
                        }`}
                        onClick={disableColorPicker}
                        style={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <p
                          className="tabs-element-label-property --overidemui"
                          onClick={disableColorPicker}
                        >
                          Champ n°{i}
                        </p>

                        <EditorCustom
                          index={i}
                          inputEditable={inputEditable}
                          TextInputsEditable={TextInputsEditable}
                          setTextInputsEditable={setTextInputsEditable}
                        />
                      </div>
                    )
                  })}
                </div>
              </AccordionDetails>
            </>
          )}
        </Accordion>
      </div>
    </>
  )
}
export default TextEdit
const EditorCustom = ({
  index,
  inputEditable,
  TextInputsEditable,
  setTextInputsEditable,
}) => {
  return (
    <Editor
    //On passe des props à 'Editor'
      id={`editable-input-${index}`}
      tinymceScriptSrc={"/lib/tinymce/tinymce.min.js"}
      inline={true}
      value={inputEditable.contentTxt}
      //onEditorChange fait partie de la bibliothèque Tinymce. Lorsqu'un changement se produit,
      //la function ci-dessous s'éxécutera.
      onEditorChange={(newValue, editor) => {
        //Copie du tableau 'TextInputsEditable' dans le tableau newArray. Cependant ils restent différent  
        //si des éléments sont modifiés dans 'TextInputsEditable', ils ne le seront pas dans newArray
        let newArray = [...TextInputsEditable]
        //On récupère l'élément du tableau newArray qui contient le props index correspondant.
        newArray[index] = {
          //contentTxt stocke la valeur récuperer de l'éditeur (sous format HTML).
          contentTxt: editor.getContent({ format: "html" }),
          //Indique que le type est du text
          type: "text",
          //Défini une clé à l'élément
          key: index,
        }
        //Ensuite la function 'setTextInputsEditable' est appelée. (voir 'SideBarEditComponent')
        setTextInputsEditable(newArray)
      }}
      init={{
        color_map: [
          "#0E3368",
          "Important",
          "#0970E6",
          "Navigation",
          "#F15145",
          "Marketing",
          "#F07930",
          "Promotion",
        ],
        inline_styles: true,
        setup: function (editor) {
          editor.on("NodeChange", function (e) {
            if (e.element.nodeName === "DIV") {
              e.element.style.display = "inline-block"
              if (
                (e.element.textContent === "Titre produit") |
                e.element.innerHTML.includes('<div id="Titre"')
              )
                e.element.id = "Titre"
            }
          })
        },
        paste_as_text: true,
        auto_focus: true,
        height: 200,
        forced_root_block: "div",
        width: "100%",
        menubar: false,
        toolbar: `undo redo | fontsizeselect | forecolor backcolor | bold italic underline strikethrough | alignleft aligncenter alignright alignfull | superscript link | customTtcButton`,
        plugins: "hr link nonbreaking charmap emoticons visualchars code paste",
        // CSS OF .form-control
        content_style: ` .mce-content-body {
              border: 2px solid rgba(0,0,0, 0.1);
              min-width: 70%; 
              padding: 0.375rem 0.75rem;
            }`,
      }}
    />
  )
}
