import React, { useState } from "react";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";

function AlignButtons() {
  const [activeButton, setActiveButton] = useState("left");

  const alignButtons = [
    {
      id: "left",
      icon: <FormatAlignLeftIcon className="text-align-icon --overidemui" />,
      activeStyle: {
        backgroundColor: "#e5f0f8",
        color: "#0087ee",
        borderColor: "#2695ea",
        borderTopLeftRadius: "0.25rem",
        borderBottomLeftRadius: "0.25rem",
      },
      defaultStyle: {
        backgroundColor: "white",
        color: "rgba(143, 150, 153, 0.5)",
        borderColor: "rgba(143, 150, 153, 0.7)",
        borderTopLeftRadius: "0.25rem",
        borderBottomLeftRadius: "0.25rem",
      },
    },
    {
      id: "center",
      icon: <FormatAlignCenterIcon className="text-align-icon --overidemui" />,
      activeStyle: {
        backgroundColor: "#e5f0f8",
        color: "#0087ee",
        borderColor: "#2695ea",
      },
      defaultStyle: {
        backgroundColor: "white",
        color: "rgba(143, 150, 153, 0.5)",
        borderColor: "rgba(143, 150, 153, 0.7)",
      },
    },
    {
      id: "right",
      icon: <FormatAlignRightIcon className="text-align-icon --overidemui" />,
      activeStyle: {
        backgroundColor: "#e5f0f8",
        color: "#0087ee",
        borderColor: "#2695ea",
      },
      defaultStyle: {
        backgroundColor: "white",
        color: "rgba(143, 150, 153, 0.5)",
        borderColor: "rgba(143, 150, 153, 0.7)",
      },
    },
    {
      id: "justify",
      icon: <FormatAlignJustifyIcon className="text-align-icon --overidemui" />,
      activeStyle: {
        backgroundColor: "#e5f0f8",
        color: "#0087ee",
        borderColor: "#2695ea",
        borderTopRightRadius: "0.25rem",
        borderBottomRightRadius: "0.25rem",
      },
      defaultStyle: {
        backgroundColor: "white",
        color: "rgba(143, 150, 153, 0.5)",
        borderColor: "rgba(143, 150, 153, 0.7)",
        borderTopRightRadius: "0.25rem",
        borderBottomRightRadius: "0.25rem",
      },
    },
  ];

  return (
    <div className="text-align-component-group --overidemui">
      {alignButtons.map((button, id) => (
        <div
          className="align-btn"
          key={id}
          onClick={() => setActiveButton(button.id)}
          style={
            activeButton === button.id
              ? button.activeStyle
              : button.defaultStyle
          }
        >
          {button.icon}
        </div>
      ))}
    </div>
  );
}

export default AlignButtons;
