import axios from "axios";
import { BEARER, API_BASE_URL } from "../constants";
import { getAuthToken, removeAuthToken } from "../utils/helpers";

const authToken = getAuthToken();

class UserService {
  async login({ identifier, password }) {
    const response = await axios
      .post(
        `${API_BASE_URL}auth/local`,
        {
          identifier,
          password,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  }
  async logout() {
    removeAuthToken();
    window.location.reload();
  }
  async checkIfUserTokenExpires(jwt) {
    const response = await axios
      .get(`${API_BASE_URL}users/me`, {
        headers: {
          Authorization: `${BEARER} ${authToken}`,
        },
      })
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    return response;
  }

  async editUser(id, { username, defaultLanguage, listSendMail }) {
    const authToken = getAuthToken(); // Obtenez le jeton d'authentification actuel
    try {
      const response = await axios.put(
        `${API_BASE_URL}users/${id}`,
        {
          username: username,
          defaultLanguage: defaultLanguage,
          listSendMail: listSendMail,
        },
        {
          headers: {
            Authorization: `${BEARER} ${authToken}`,
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }
}

export default new UserService();
