import React, { useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";


function IncrementButton({ defaultValue }) {
  const [state, setState] = useState(defaultValue);
  const [displayDisableBtn, setDisplayDisableBtn] = useState(true);

  const handleIncrement = () => {
    setState(state + 1);
    if (state >= 0) {
      setDisplayDisableBtn(false);
    }
  };

  const handleDecrement = () => {
    setState(state - 1);
    if (state <= 1) {
      setDisplayDisableBtn(true);
    }
  };

  return (
    <ButtonGroup size="small" aria-label="small outlined button group">
      {
        (!displayDisableBtn,
        displayDisableBtn && (
          <Button
            disabled
            className="decrement-disabledbtn --overidemui"
            onClick={handleDecrement}
            style={{
              border: "1px solid rgb(225, 228, 231)",
              color: " rgb(73, 80, 87)",
            }}
          >
            -
          </Button>
        ))
      }
      {
        (displayDisableBtn,
        !displayDisableBtn && (
          <Button
            onClick={handleDecrement}
            style={{
              border: "1px solid rgb(225, 228, 231)",
              color: " rgb(73, 80, 87)",
              backgroundColor: "white",
            }}
          >
            -
          </Button>
        ))
      }
      <Button
        disabled
        style={{
          border: "1px solid rgb(225, 228, 231)",
          color: " rgb(73, 80, 87)",
          backgroundColor: "white",
        }}
      >
        {state}
      </Button>
      <Button
        onClick={handleIncrement}
        style={{
          border: "1px solid rgb(225, 228, 231)",
          borderLeft: "1px solid rgb(225, 228, 231)",
          color: " rgb(73, 80, 87)",
          backgroundColor: "white",
        }}
      >
        +
      </Button>
    </ButtonGroup>
  );
}

export default IncrementButton;
