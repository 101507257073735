import { useContext, useState, useEffect, useRef, useReducer } from "react";
import Navbar from "../components/Navbar";
import { Box } from "@mui/material";
import { LangueContext } from "../context/LangueContext";
import { AuthContext } from "../context/AuthContext";
import BasicBreadcrumbs from "../components/Breadcrumbs";
import EditProfile from "../components/EditProfile";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import { ReactMultiEmail } from "react-multi-email";
import UserService from "../services/UserService";

export default function UserSettings() {
  const { user, setUser } = useContext(AuthContext);
  const { langue } = useContext(LangueContext);

  const initialDefaultLanguage = useRef(user?.defaultLanguage);
  const initialUsernameRef = useRef(user?.username);

  const reducer = (prev, state) => ({ ...prev, ...state });
  const [actionTag, setActionTag] = useReducer(reducer, {
    isChanchingDefaultLanguage: false,
    isChangingUsername: false,
  });

  useEffect(() => {
    // Compare the current username with the initial username
    setActionTag({
      isChanchingDefaultLanguage:
        user?.defaultLanguage !== initialDefaultLanguage.current,
    });
    setActionTag({
      isChangingUsername: user?.username !== initialUsernameRef.current,
    });
  }, [user]);

  // TAB CONTEXT

  const [tabValue, setTabValue] = useState("1");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // EMAIL

  const [emails, setEmails] = useState(user?.listSendMail);
  const [focused, setFocused] = useState(false);

  console.log("emails", emails);
  console.log("user", user);

  return (
    <>
      <Navbar />
      <Box component="main" sx={{ flexGrow: 1, p: 10 }}>
        <div className="ariane-settings-controller">
          <BasicBreadcrumbs />
        </div>
        <Box className="main-usersettings-container">
          <h2 className="user-settings-title">Préférences de l'utilisateur</h2>
          <Box className="tab-context-box">
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Détails du profil" value="1" />
                  <Tab label="Envoi Mail" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <EditProfile
                  langue={langue}
                  user={user}
                  setUser={setUser}
                  actionTag={actionTag}
                  setActionTag={setActionTag}
                  initialDefaultLanguage={initialDefaultLanguage}
                />
              </TabPanel>
              <TabPanel value="2">
                <p className="email-list-send-title">
                  Veuillez indiquer ci-dessous la liste des adresses e-mail où
                  vous souhaitez recevoir les e-mails de test.
                </p>
                <form>
                  <br />
                  <ReactMultiEmail
                    placeholder="Indiquez vos adresses e-mail"
                    emails={emails}
                    onChange={async (_emails) => {
                      setEmails(_emails);
                      const updatedEmails = _emails; // Utiliser une variable temporaire
                      setUser({
                        ...user,
                        listSendMail: updatedEmails,
                      });
                      const response = await UserService.editUser(user?.id, {
                        listSendMail: updatedEmails,
                      });
                      console.log("response", response);
                    }}
                    autoFocus={true}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          <div data-tag-item>{email}</div>
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                </form>
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Box>
    </>
  );
}
