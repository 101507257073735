import React, { useCallback, useContext, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { useParams } from "react-router-dom";
import { EditorContext } from "../../../context/EditorContext";
import { LangueContext } from "../../../context/LangueContext";
import NewsletterService from "../../../services/NewsletterService";
import TemplateService from "../../../services/TemplateService";

import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import TextEdit from "./componentProperty/TextEdit";
import ImageEdit from "./componentProperty/ImageEdit";
import LinkEdit from "./componentProperty/LinkEdit";
import SideBarItem from "./SideBarItem";
import DropZone from "../dragAndDrop/DropZone";
import Row from "../dragAndDrop/Row";

import shortid from "shortid";
import { SIDEBAR_ITEM, COMPONENT, COLUMN } from "../../../constants";
import {
  handleMoveWithinParent,
  handleMoveToDifferentParent,
  handleMoveSidebarComponentIntoParent,
  handleRemoveItemFromLayout,
} from "../helpers";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import {
  Box,
  IconButton,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
} from "@mui/material";

import AppsIcon from "@mui/icons-material/Apps";
import WidgetsIcon from "@mui/icons-material/Widgets";
import SettingsIcon from "@mui/icons-material/Settings";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DropDownComponents from "../dragAndDrop/DropDownComponents";
import ComponentService from "../../../services/ComponentService";
import moment from "moment";
import ProductCheckbox from "./componentProperty/ProductCheckbox";
import { AuthContext } from "../../../context/AuthContext";
import { getAuthToken } from "../../../utils/helpers";
import ExportModal from "../ExportModal";

export default function SideBarListComponent({ Components }) {
  const componentTypes = ["header", "content", "footer", "separator"];
  return (
    <div
      className="sideBar-items-wrapper"
      style={{
        background: "#F1F1F1",
        // HIDE PROPERTIES
        // display: sidebarSettings.navbarDisplay,
      }}
    >
      <div
        className="sideBar-items-container"
        style={{
          height: "50%",
        }}
      >
        {componentTypes.map((componentType, i) => (
          <DropDownComponents
            componentType={componentType}
            key={componentType + i}
          >
            {Components &&
              Components.length > 0 &&
              Components.map((sidebarItem, index) => {
                if (componentType === sidebarItem.attributes.section) {
                  let dataSideBar = {
                    id: sidebarItem.id,
                    type: SIDEBAR_ITEM,
                    component: { ...sidebarItem.attributes },
                    section: sidebarItem.attributes.section,
                  };
                  return (
                    <SideBarItem key={dataSideBar.id} data={dataSideBar} />
                  );
                }
              })}
          </DropDownComponents>
        ))}
      </div>
    </div>
  );
}
