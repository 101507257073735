import { createContext, useState, useEffect } from "react";
import UserService from "../services/UserService";
import { getAuthToken, removeAuthToken } from "../utils/helpers";

const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const checkIfUserTokenExpires = async () => {
    const response = await UserService.checkIfUserTokenExpires();
    if (response?.status === 200) {
      setUser(response?.data);
    }
    if (response?.status !== 200) {
      removeAuthToken();
      setUser(null);
    }
  };

  useEffect(() => {
    if (!getAuthToken()) return;
    checkIfUserTokenExpires();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, isLoading, setIsLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
