import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function DropDownComponents({ type, datatest, componentType, children }) {
  return (
    <>
      <div className="dropdown-sidebarItems">
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="accordeon-button --overidemui"
          >
            <Typography className="accordeon-label --overidemui">
              {componentType}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="accordeon-details-container --overidemui">
            <div className="text-properties-container --sidebarItems">
              <div className="sidebarItems-children-container">{children}</div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}

export default DropDownComponents;