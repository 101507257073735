// PROMO SECTION

export const contentFirstWithoutPromo = {
  balise: "tr",
  childrens: [
    {
      balise: "td",
      childrens: [
        {
          balise: "table",
          childrens: [
            {
              balise: "tbody",
              childrens: [
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: "Titre produit",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-510",
                              style: "color: #13173d;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          style:
                            "\n                                font-size: 18px;\n                                font-weight: 700;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: "Sous Titre",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-510",
                              style: "color: #575757;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          style:
                            "\n                                font-size: 12px;\n                                font-weight: 400;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
              ],
              attributes: [],
            },
          ],
          attributes: [
            {
              border: "0",
            },
            {
              cellspacing: "0",
            },
          ],
        },
      ],
      attributes: [
        {
          valign: "top",
        },
        {
          align: "left",
        },
      ],
    },
    {
      balise: "td",
      childrens: [
        {
          balise: "table",
          childrens: [
            {
              balise: "tbody",
              childrens: [
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          attributes: [
                            {
                              class: "editable",
                            },
                          ],
                          contentTxt: "à partir de",
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                color: #575757;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              childrens: [
                                {
                                  balise: "sup",
                                  attributes: [
                                    {
                                      style: "font-size: 16px",
                                    },
                                  ],
                                  contentTxt: "*",
                                },
                              ],
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: " 400€",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-510",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                font-size: 32px;\n                                font-weight: 700;\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          attributes: [
                            {
                              class: "editable",
                            },
                          ],
                          contentTxt: "TTC/pers.",
                          InputEditable: true,
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                color: #575757;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
              ],
              attributes: [],
            },
          ],
          attributes: [
            {
              border: "0",
            },
            {
              cellspacing: "0",
            },
            {
              style: "font-size: 12px; width: 100%; color: #0e3368",
            },
          ],
        },
      ],
      attributes: [
        {
          style: "width: 120px",
        },
        {
          valign: "top",
        },
        {
          align: "right",
        },
        {
          "data-section": "container-price",
        },
      ],
    },
  ],
  attributes: [
    {
      "data-section": "promoSection",
    },
  ],
  promoSection: "promoSection",
};
export const contentFirstWithPromo = {
  balise: "tr",
  childrens: [
    {
      balise: "td",
      childrens: [
        {
          balise: "table",
          childrens: [
            {
              balise: "tbody",
              childrens: [
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: "Titre produit",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-511",
                              style: "color: #13173d;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          style:
                            "\n                                font-size: 18px;\n                                font-weight: 700;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: "Sous Titre",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-511",
                              style: "color: #575757;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          style:
                            "\n                                font-size: 12px;\n                                font-weight: 400;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
              ],
              attributes: [],
            },
          ],
          attributes: [
            {
              border: "0",
            },
            {
              cellspacing: "0",
            },
          ],
        },
      ],
      attributes: [
        {
          valign: "top",
        },
        {
          align: "left",
        },
      ],
    },
    {
      balise: "td",
      childrens: [
        {
          balise: "table",
          childrens: [
            {
              balise: "tbody",
              childrens: [
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          attributes: [
                            {
                              class: "editable",
                            },
                          ],
                          contentTxt: "à partir de",
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                color: #575757;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              childrens: [
                                {
                                  balise: "sup",
                                  attributes: [
                                    {
                                      style: "font-size: 16px",
                                    },
                                  ],
                                  contentTxt: "*",
                                },
                              ],
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: " 400€",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              style: "color: #f07930;",
                              class: "linked-btn btn-511",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                font-size: 32px;\n                                font-weight: 700;\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "span",
                              attributes: [
                                {
                                  style: "text-decoration: line-through",
                                },
                              ],
                              contentTxt: "500€",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "editable",
                            },
                          ],
                          contentTxt: "TTC/pers.<br />au lieu de ",
                          InputEditable: true,
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                color: #575757;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "table",
                          childrens: [
                            {
                              balise: "tbody",
                              childrens: [
                                {
                                  balise: "tr",
                                  childrens: [
                                    {
                                      balise: "td",
                                      childrens: [
                                        {
                                          balise: "div",
                                          attributes: [
                                            {
                                              class: "editable",
                                            },
                                          ],
                                          contentTxt: "- 00 %",
                                          InputEditable: true,
                                        },
                                      ],
                                      attributes: [
                                        {
                                          valign: "top",
                                        },
                                        {
                                          align: "right",
                                        },
                                        {
                                          width: "50px",
                                        },
                                        {
                                          style:
                                            "\n                                    background-color: #f07930;\n                                    color: #ffffff;\n                                    font-family: 'Segoe UI', Helvetica, Arial,\n                                        sans-serif;\n                                    font-size: 12px;\n                                    text-decoration: none;\n                                    font-weight: normal;\n                                    display: block;\n                                    ",
                                        },
                                      ],
                                    },
                                  ],
                                  attributes: [],
                                },
                              ],
                              attributes: [],
                            },
                          ],
                          attributes: [
                            {
                              align: "right",
                            },
                            {
                              valign: "top",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
              ],
              attributes: [],
            },
          ],
          attributes: [
            {
              border: "0",
            },
            {
              cellspacing: "0",
            },
            {
              style: "font-size: 12px; width: 100%; color: #0e3368",
            },
          ],
        },
      ],
      attributes: [
        {
          style: "width: 120px",
        },
        {
          valign: "top",
        },
        {
          align: "right",
        },
        {
          "data-section": "container-price",
        },
      ],
    },
  ],
  attributes: [
    {
      "data-section": "promoSection",
    },
  ],
  promoSection: "promoSection",
};

export const contentSecondWithoutPromo = {
  balise: "tr",
  childrens: [
    {
      balise: "td",
      childrens: [
        {
          balise: "table",
          childrens: [
            {
              balise: "tbody",
              childrens: [
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: "Titre produit",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-510",
                              style: "color: #13173d;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          style:
                            "\n                                font-size: 18px;\n                                font-weight: 700;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: "Sous Titre",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-510",
                              style: "color: #575757;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          style:
                            "\n                                font-size: 12px;\n                                font-weight: 400;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
              ],
              attributes: [],
            },
          ],
          attributes: [
            {
              border: "0",
            },
            {
              cellspacing: "0",
            },
          ],
        },
      ],
      attributes: [
        {
          valign: "top",
        },
        {
          align: "left",
        },
      ],
    },
    {
      balise: "td",
      childrens: [
        {
          balise: "table",
          childrens: [
            {
              balise: "tbody",
              childrens: [
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          attributes: [
                            {
                              class: "editable",
                            },
                          ],
                          contentTxt: "à partir de",
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                color: #575757;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              childrens: [
                                {
                                  balise: "sup",
                                  attributes: [
                                    {
                                      style: "font-size: 16px",
                                    },
                                  ],
                                  contentTxt: "*",
                                },
                              ],
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: " 400€",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-510",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                font-size: 32px;\n                                font-weight: 700;\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          attributes: [
                            {
                              class: "editable",
                            },
                          ],
                          contentTxt: "TTC/pers.",
                          InputEditable: true,
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                color: #575757;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
              ],
              attributes: [],
            },
          ],
          attributes: [
            {
              border: "0",
            },
            {
              cellspacing: "0",
            },
            {
              style: "font-size: 12px; width: 100%; color: #0e3368",
            },
          ],
        },
      ],
      attributes: [
        {
          style: "width: 120px",
        },
        {
          valign: "top",
        },
        {
          align: "right",
        },
        {
          "data-section": "container-price",
        },
      ],
    },
  ],
  attributes: [
    {
      "data-section": "promoSection",
    },
  ],
  promoSection: "promoSection",
};
export const contentSecondWithPromo = {
  balise: "tr",
  childrens: [
    {
      balise: "td",
      childrens: [
        {
          balise: "table",
          childrens: [
            {
              balise: "tbody",
              childrens: [
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: "Titre produit",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-511",
                              style: "color: #13173d;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          style:
                            "\n                                font-size: 18px;\n                                font-weight: 700;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: "Sous Titre",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-511",
                              style: "color: #575757;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          style:
                            "\n                                font-size: 12px;\n                                font-weight: 400;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
              ],
              attributes: [],
            },
          ],
          attributes: [
            {
              border: "0",
            },
            {
              cellspacing: "0",
            },
          ],
        },
      ],
      attributes: [
        {
          valign: "top",
        },
        {
          align: "left",
        },
      ],
    },
    {
      balise: "td",
      childrens: [
        {
          balise: "table",
          childrens: [
            {
              balise: "tbody",
              childrens: [
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          attributes: [
                            {
                              class: "editable",
                            },
                          ],
                          contentTxt: "à partir de",
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                color: #575757;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              childrens: [
                                {
                                  balise: "sup",
                                  attributes: [
                                    {
                                      style: "font-size: 16px",
                                    },
                                  ],
                                  contentTxt: "*",
                                },
                              ],
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: " 400€",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-511",
                              style: "color: #f07930;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                font-size: 32px;\n                                font-weight: 700;\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "span",
                              attributes: [
                                {
                                  style: "text-decoration: line-through",
                                },
                              ],
                              contentTxt: "500€",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "editable",
                            },
                          ],
                          contentTxt: "TTC/pers.<br />au lieu de ",
                          InputEditable: true,
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                color: #575757;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "table",
                          childrens: [
                            {
                              balise: "tbody",
                              childrens: [
                                {
                                  balise: "tr",
                                  childrens: [
                                    {
                                      balise: "td",
                                      childrens: [
                                        {
                                          balise: "div",
                                          attributes: [
                                            {
                                              class: "editable",
                                            },
                                          ],
                                          contentTxt: "- 00 %",
                                          InputEditable: true,
                                        },
                                      ],
                                      attributes: [
                                        {
                                          valign: "top",
                                        },
                                        {
                                          align: "right",
                                        },
                                        {
                                          width: "50px",
                                        },
                                        {
                                          style:
                                            "\n                                    background-color: #f07930;\n                                    color: #ffffff;\n                                    font-family: 'Segoe UI', Helvetica, Arial,\n                                        sans-serif;\n                                    font-size: 12px;\n                                    text-decoration: none;\n                                    font-weight: normal;\n                                    display: block;\n                                    ",
                                        },
                                      ],
                                    },
                                  ],
                                  attributes: [],
                                },
                              ],
                              attributes: [],
                            },
                          ],
                          attributes: [
                            {
                              align: "right",
                            },
                            {
                              valign: "top",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
              ],
              attributes: [],
            },
          ],
          attributes: [
            {
              border: "0",
            },
            {
              cellspacing: "0",
            },
            {
              style: "font-size: 12px; width: 100%; color: #0e3368",
            },
          ],
        },
      ],
      attributes: [
        {
          style: "width: 120px",
        },
        {
          valign: "top",
        },
        {
          align: "right",
        },
        {
          "data-section": "container-price",
        },
      ],
    },
  ],
  attributes: [
    {
      "data-section": "promoSection",
    },
  ],
  promoSection: "promoSection",
};

// DESCRIPTION SECTION

export const contentWithFirstDescription = {
  balise: "tr",
  isHidden: false,
  childrens: [
    {
      balise: "td",
      attributes: [
        {
          width: "10",
        },
      ],
    },
    {
      balise: "td",
      childrens: [
        {
          balise: "div",
          childrens: [
            {
              balise: "div",
              attributes: [
                {
                  class: "editable",
                },
              ],
              contentTxt:
                "First ipsum dolor sit, amet consectetur elit.\n                                        Quisquam, quod! Lorem ipsum dolor sit, amet\n                                        consectetur elit. Quisquam, quod!",
              InputEditable: true,
            },
          ],
          attributes: [
            {
              class: "linked-btn btn-510",
              style: "text-decoration: none; color: #575757;",
            },
          ],
        },
      ],
      attributes: [
        {
          valign: "top",
        },
        {
          style:
            "\n                                    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                ",
        },
      ],
    },
    {
      balise: "td",
      attributes: [
        {
          width: "10",
        },
      ],
    },
  ],
  attributes: [
    {
      id: "ProductDescription",
    },
    {
      "data-section": "description",
    },
  ],
  descriptionSection: "descriptionSection",
};

export const contentWithSecondDescription = {
  balise: "tr",
  isHidden: false,
  childrens: [
    {
      balise: "td",
      attributes: [
        {
          width: "10",
        },
      ],
    },
    {
      balise: "td",
      childrens: [
        {
          balise: "div",
          childrens: [
            {
              balise: "div",
              attributes: [
                {
                  class: "editable",
                },
              ],
              contentTxt:
                "Second ipsum dolor sit, amet consectetur elit.\n                                        Quisquam, quod! Lorem ipsum dolor sit, amet\n                                        consectetur elit. Quisquam, quod!",
              InputEditable: true,
            },
          ],
          attributes: [
            {
              class: "linked-btn btn-510",
              style: "text-decoration: none; color: #575757;",
            },
          ],
        },
      ],
      attributes: [
        {
          valign: "top",
        },
        {
          style:
            "\n                                    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                ",
        },
      ],
    },
    {
      balise: "td",
      attributes: [
        {
          width: "10",
        },
      ],
    },
  ],
  attributes: [
    {
      id: "ProductDescription",
    },
    {
      "data-section": "description",
    },
  ],
  descriptionSection: "descriptionSection",
};

// MULTIPLE PAIEMENT SECTION

export const multiplePaiementSection = {
  balise: "tr",
  childrens: [
    {
      balise: "td",
      attributes: [
        {
          width: "10",
        },
        {
          height: "15",
        },
      ],
    },
    {
      balise: "td",
      childrens: [
        {
          balise: "div",
          childrens: [
            {
              balise: "p",
              childrens: [
                {
                  balise: "b",
                  attributes: [
                    {
                      style: "font-size: 18px",
                    },
                  ],
                  contentTxt: "3X ou 4X",
                },
                {
                  balise: "b",
                  childrens: [
                    {
                      balise: "sup",
                      attributes: [
                        {
                          style: "font-size: 10px; font-weight: normal",
                        },
                      ],
                      contentTxt: "(2)",
                    },
                  ],
                  attributes: [
                    {
                      style: "font-size: 18px",
                    },
                  ],
                },
              ],
              attributes: [
                {
                  style:
                    "\n                                                width: max-content;\n                                                margin: 0px;\n                                                padding: 5px;\n                                                border-radius: 5px;\n                                                background-color: #ffe14d;\n                                                color: #f15145;\n                                                text-align: left;\n                                                font-size: 13px;\n                                                line-height: 1;\n                                            ",
                },
              ],
              contentTxt: "Payez en ",
            },
          ],
          attributes: [
            {
              class: "editable",
            },
          ],
        },
      ],
      attributes: [
        {
          colspan: "2",
        },
        {
          valign: "top",
        },
      ],
    },
  ],
  attributes: [
    {
      id: "ProductPaiementPlusieursFois",
    },
    {
      "data-section": "paiement-plusieurs-fois",
    },
  ],
  ProductPaiementPlusieursFoisSection: "ProductPaiementPlusieursFois",
};

/// SECOND PRODUCT SECTIONS

// PROMO SECTION

export const VcontentFirstWithoutPromo = {
  balise: "tr",
  childrens: [
    {
      balise: "td",
      childrens: [
        {
          balise: "table",
          childrens: [
            {
              balise: "tbody",
              childrens: [
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: "Titre produit",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-510",
                              style: "color: #13173d;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          style:
                            "\n                                font-size: 18px;\n                                font-weight: 700;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: "Sous Titre",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-510",
                              style: "color: #575757;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          style:
                            "\n                                font-size: 12px;\n                                font-weight: 400;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
              ],
              attributes: [],
            },
          ],
          attributes: [
            {
              border: "0",
            },
            {
              cellspacing: "0",
            },
          ],
        },
      ],
      attributes: [
        {
          valign: "top",
        },
        {
          align: "left",
        },
      ],
    },
    {
      balise: "td",
      childrens: [
        {
          balise: "table",
          childrens: [
            {
              balise: "tbody",
              childrens: [
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          attributes: [
                            {
                              class: "editable",
                            },
                          ],
                          contentTxt: "à partir de",
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                color: #575757;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              childrens: [
                                {
                                  balise: "sup",
                                  attributes: [
                                    {
                                      style: "font-size: 16px",
                                    },
                                  ],
                                  contentTxt: "*",
                                },
                              ],
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: " 400€",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-510",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                font-size: 32px;\n                                font-weight: 700;\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          attributes: [
                            {
                              class: "editable",
                            },
                          ],
                          contentTxt: "TTC/pers.",
                          InputEditable: true,
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                color: #575757;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
              ],
              attributes: [],
            },
          ],
          attributes: [
            {
              border: "0",
            },
            {
              cellspacing: "0",
            },
            {
              style: "font-size: 12px; width: 100%; color: #0e3368",
            },
          ],
        },
      ],
      attributes: [
        {
          style: "width: 120px",
        },
        {
          valign: "top",
        },
        {
          align: "right",
        },
        {
          "data-section": "container-price",
        },
      ],
    },
  ],
  attributes: [
    {
      "data-section": "promoSection",
    },
  ],
  promoSection: "promoSection",
};
export const VcontentFirstWithPromo = {
  balise: "tr",
  childrens: [
    {
      balise: "td",
      childrens: [
        {
          balise: "table",
          childrens: [
            {
              balise: "tbody",
              childrens: [
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: "Titre produit",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-511",
                              style: "color: #13173d;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          style:
                            "\n                                font-size: 18px;\n                                font-weight: 700;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: "Sous Titre",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-511",
                              style: "color: #575757;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          style:
                            "\n                                font-size: 12px;\n                                font-weight: 400;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
              ],
              attributes: [],
            },
          ],
          attributes: [
            {
              border: "0",
            },
            {
              cellspacing: "0",
            },
          ],
        },
      ],
      attributes: [
        {
          valign: "top",
        },
        {
          align: "left",
        },
      ],
    },
    {
      balise: "td",
      childrens: [
        {
          balise: "table",
          childrens: [
            {
              balise: "tbody",
              childrens: [
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          attributes: [
                            {
                              class: "editable",
                            },
                          ],
                          contentTxt: "à partir de",
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                color: #575757;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              childrens: [
                                {
                                  balise: "sup",
                                  attributes: [
                                    {
                                      style: "font-size: 16px",
                                    },
                                  ],
                                  contentTxt: "*",
                                },
                              ],
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: " 400€",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              style: "color: #f07930;",
                              class: "linked-btn btn-511",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                font-size: 32px;\n                                font-weight: 700;\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "span",
                              attributes: [
                                {
                                  style: "text-decoration: line-through",
                                },
                              ],
                              contentTxt: "500€",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "editable",
                            },
                          ],
                          contentTxt: "TTC/pers.<br />au lieu de ",
                          InputEditable: true,
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                color: #575757;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "table",
                          childrens: [
                            {
                              balise: "tbody",
                              childrens: [
                                {
                                  balise: "tr",
                                  childrens: [
                                    {
                                      balise: "td",
                                      childrens: [
                                        {
                                          balise: "div",
                                          attributes: [
                                            {
                                              class: "editable",
                                            },
                                          ],
                                          contentTxt: "- 00 %",
                                          InputEditable: true,
                                        },
                                      ],
                                      attributes: [
                                        {
                                          valign: "top",
                                        },
                                        {
                                          align: "right",
                                        },
                                        {
                                          width: "50px",
                                        },
                                        {
                                          style:
                                            "\n                                    background-color: #f07930;\n                                    color: #ffffff;\n                                    font-family: 'Segoe UI', Helvetica, Arial,\n                                        sans-serif;\n                                    font-size: 12px;\n                                    text-decoration: none;\n                                    font-weight: normal;\n                                    display: block;\n                                    ",
                                        },
                                      ],
                                    },
                                  ],
                                  attributes: [],
                                },
                              ],
                              attributes: [],
                            },
                          ],
                          attributes: [
                            {
                              align: "right",
                            },
                            {
                              valign: "top",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
              ],
              attributes: [],
            },
          ],
          attributes: [
            {
              border: "0",
            },
            {
              cellspacing: "0",
            },
            {
              style: "font-size: 12px; width: 100%; color: #0e3368",
            },
          ],
        },
      ],
      attributes: [
        {
          style: "width: 120px",
        },
        {
          valign: "top",
        },
        {
          align: "right",
        },
        {
          "data-section": "container-price",
        },
      ],
    },
  ],
  attributes: [
    {
      "data-section": "promoSection",
    },
  ],
  promoSection: "promoSection",
};

export const VcontentSecondWithoutPromo = {
  balise: "tr",
  childrens: [
    {
      balise: "td",
      childrens: [
        {
          balise: "table",
          childrens: [
            {
              balise: "tbody",
              childrens: [
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: "Titre produit",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-510",
                              style: "color: #13173d;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          style:
                            "\n                                font-size: 18px;\n                                font-weight: 700;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: "Sous Titre",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-510",
                              style: "color: #575757;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          style:
                            "\n                                font-size: 12px;\n                                font-weight: 400;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
              ],
              attributes: [],
            },
          ],
          attributes: [
            {
              border: "0",
            },
            {
              cellspacing: "0",
            },
          ],
        },
      ],
      attributes: [
        {
          valign: "top",
        },
        {
          align: "left",
        },
      ],
    },
    {
      balise: "td",
      childrens: [
        {
          balise: "table",
          childrens: [
            {
              balise: "tbody",
              childrens: [
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          attributes: [
                            {
                              class: "editable",
                            },
                          ],
                          contentTxt: "à partir de",
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                color: #575757;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              childrens: [
                                {
                                  balise: "sup",
                                  attributes: [
                                    {
                                      style: "font-size: 16px",
                                    },
                                  ],
                                  contentTxt: "*",
                                },
                              ],
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: " 400€",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-510",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                font-size: 32px;\n                                font-weight: 700;\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          attributes: [
                            {
                              class: "editable",
                            },
                          ],
                          contentTxt: "TTC/pers.",
                          InputEditable: true,
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                color: #575757;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
              ],
              attributes: [],
            },
          ],
          attributes: [
            {
              border: "0",
            },
            {
              cellspacing: "0",
            },
            {
              style: "font-size: 12px; width: 100%; color: #0e3368",
            },
          ],
        },
      ],
      attributes: [
        {
          style: "width: 120px",
        },
        {
          valign: "top",
        },
        {
          align: "right",
        },
        {
          "data-section": "container-price",
        },
      ],
    },
  ],
  attributes: [
    {
      "data-section": "promoSection",
    },
  ],
  promoSection: "promoSection",
};
export const VcontentSecondWithPromo = {
  balise: "tr",
  childrens: [
    {
      balise: "td",
      childrens: [
        {
          balise: "table",
          childrens: [
            {
              balise: "tbody",
              childrens: [
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: "Titre produit",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-511",
                              style: "color: #13173d;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          style:
                            "\n                                font-size: 18px;\n                                font-weight: 700;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: "Sous Titre",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-511",
                              style: "color: #575757;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          style:
                            "\n                                font-size: 12px;\n                                font-weight: 400;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
              ],
              attributes: [],
            },
          ],
          attributes: [
            {
              border: "0",
            },
            {
              cellspacing: "0",
            },
          ],
        },
      ],
      attributes: [
        {
          valign: "top",
        },
        {
          align: "left",
        },
      ],
    },
    {
      balise: "td",
      childrens: [
        {
          balise: "table",
          childrens: [
            {
              balise: "tbody",
              childrens: [
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          attributes: [
                            {
                              class: "editable",
                            },
                          ],
                          contentTxt: "à partir de",
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                color: #575757;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              childrens: [
                                {
                                  balise: "sup",
                                  attributes: [
                                    {
                                      style: "font-size: 16px",
                                    },
                                  ],
                                  contentTxt: "*",
                                },
                              ],
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt: " 400€",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              style: "color: #f07930;",
                              class: "linked-btn btn-511",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                font-size: 32px;\n                                font-weight: 700;\n                                line-height: 1;\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                            ",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "span",
                              attributes: [
                                {
                                  style: "text-decoration: line-through",
                                },
                              ],
                              contentTxt: "500€",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "editable",
                            },
                          ],
                          contentTxt: "TTC/pers.<br />au lieu de ",
                          InputEditable: true,
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                        {
                          style:
                            "\n                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                color: #575757;\n                            ",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "table",
                          childrens: [
                            {
                              balise: "tbody",
                              childrens: [
                                {
                                  balise: "tr",
                                  childrens: [
                                    {
                                      balise: "td",
                                      childrens: [
                                        {
                                          balise: "div",
                                          attributes: [
                                            {
                                              class: "editable",
                                            },
                                          ],
                                          contentTxt: "- 00 %",
                                          InputEditable: true,
                                        },
                                      ],
                                      attributes: [
                                        {
                                          valign: "top",
                                        },
                                        {
                                          align: "right",
                                        },
                                        {
                                          width: "50px",
                                        },
                                        {
                                          style:
                                            "\n                                    background-color: #f07930;\n                                    color: #ffffff;\n                                    font-family: 'Segoe UI', Helvetica, Arial,\n                                        sans-serif;\n                                    font-size: 12px;\n                                    text-decoration: none;\n                                    font-weight: normal;\n                                    display: block;\n                                    ",
                                        },
                                      ],
                                    },
                                  ],
                                  attributes: [],
                                },
                              ],
                              attributes: [],
                            },
                          ],
                          attributes: [
                            {
                              align: "right",
                            },
                            {
                              valign: "top",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          align: "right",
                        },
                      ],
                    },
                  ],
                  attributes: [],
                },
              ],
              attributes: [],
            },
          ],
          attributes: [
            {
              border: "0",
            },
            {
              cellspacing: "0",
            },
            {
              style: "font-size: 12px; width: 100%; color: #0e3368",
            },
          ],
        },
      ],
      attributes: [
        {
          style: "width: 120px",
        },
        {
          valign: "top",
        },
        {
          align: "right",
        },
        {
          "data-section": "container-price",
        },
      ],
    },
  ],
  attributes: [
    {
      "data-section": "promoSection",
    },
  ],
  promoSection: "promoSection",
};

// DESCRIPTION SECTION

export const VcontentWithFirstDescription = {
  balise: "tr",
  isHidden: false,
  childrens: [
    {
      balise: "td",
      attributes: [
        {
          width: "10",
        },
      ],
    },
    {
      balise: "td",
      childrens: [
        {
          balise: "div",
          childrens: [
            {
              balise: "div",
              attributes: [
                {
                  class: "editable",
                },
              ],
              contentTxt:
                "First ipsum dolor sit, amet consectetur elit.\n                                        Quisquam, quod! Lorem ipsum dolor sit, amet\n                                        consectetur elit. Quisquam, quod!",
              InputEditable: true,
            },
          ],
          attributes: [
            {
              class: "linked-btn btn-510",
              style: "text-decoration: none; color: #575757;",
            },
          ],
        },
      ],
      attributes: [
        {
          valign: "top",
        },
        {
          style:
            "\n                                    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                ",
        },
      ],
    },
    {
      balise: "td",
      attributes: [
        {
          width: "10",
        },
      ],
    },
  ],
  attributes: [
    {
      id: "ProductDescription",
    },
    {
      "data-section": "description",
    },
  ],
  descriptionSection: "descriptionSection",
};

export const VcontentWithSecondDescription = {
  balise: "tr",
  isHidden: false,
  childrens: [
    {
      balise: "td",
      attributes: [
        {
          width: "10",
        },
      ],
    },
    {
      balise: "td",
      childrens: [
        {
          balise: "div",
          childrens: [
            {
              balise: "div",
              attributes: [
                {
                  class: "editable",
                },
              ],
              contentTxt:
                "Second ipsum dolor sit, amet consectetur elit.\n                                        Quisquam, quod! Lorem ipsum dolor sit, amet\n                                        consectetur elit. Quisquam, quod!",
              InputEditable: true,
            },
          ],
          attributes: [
            {
              class: "linked-btn btn-510",
              style: "text-decoration: none; color: #575757;",
            },
          ],
        },
      ],
      attributes: [
        {
          valign: "top",
        },
        {
          style:
            "\n                                    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                ",
        },
      ],
    },
    {
      balise: "td",
      attributes: [
        {
          width: "10",
        },
      ],
    },
  ],
  attributes: [
    {
      id: "ProductDescription",
    },
    {
      "data-section": "description",
    },
  ],
  descriptionSection: "descriptionSection",
};

// MULTIPLE PAIEMENT SECTION

export const VmultiplePaiementSection = {
  balise: "tr",
  childrens: [
    {
      balise: "td",
      attributes: [
        {
          width: "10",
        },
        {
          height: "15",
        },
      ],
    },
    {
      balise: "td",
      childrens: [
        {
          balise: "div",
          childrens: [
            {
              balise: "p",
              childrens: [
                {
                  balise: "b",
                  attributes: [
                    {
                      style: "font-size: 18px",
                    },
                  ],
                  contentTxt: "3X ou 4X",
                },
                {
                  balise: "b",
                  childrens: [
                    {
                      balise: "sup",
                      attributes: [
                        {
                          style: "font-size: 10px; font-weight: normal",
                        },
                      ],
                      contentTxt: "(2)",
                    },
                  ],
                  attributes: [
                    {
                      style: "font-size: 18px",
                    },
                  ],
                },
              ],
              attributes: [
                {
                  style:
                    "\n                                                width: max-content;\n                                                margin: 0px;\n                                                padding: 5px;\n                                                border-radius: 5px;\n                                                background-color: #ffe14d;\n                                                color: #f15145;\n                                                text-align: left;\n                                                font-size: 13px;\n                                                line-height: 1;\n                                            ",
                },
              ],
              contentTxt: "Payez en ",
            },
          ],
          attributes: [
            {
              class: "editable",
            },
          ],
        },
      ],
      attributes: [
        {
          colspan: "2",
        },
        {
          valign: "top",
        },
      ],
    },
  ],
  attributes: [
    {
      id: "ProductPaiementPlusieursFois",
    },
    {
      "data-section": "paiement-plusieurs-fois",
    },
  ],
  ProductPaiementPlusieursFoisSection: "ProductPaiementPlusieursFois",
};

// GENERAL SECTION

// SPACING SECTION

export const spacingProductSection = {
  balise: "tr",
  spacingProductSection: "spacingProductSection",
  childrens: [
    {
      balise: "td",
      attributes: [
        {
          height: "31",
        },
      ],
    },
  ],
  attributes: [
    {
      "data-section": "spacing-product-between-title-description",
    },
  ],
  isHidden: false,
};

export const productContainerDescription = {
  balise: "tr",
  childrens: [
    {
      balise: "td",
      childrens: [
        {
          balise: "table",
          childrens: [
            {
              balise: "tbody",
              childrens: [
                {
                  balise: "tr",
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                        {
                          height: "15",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "p",
                              childrens: [
                                {
                                  balise: "b",
                                  attributes: [
                                    {
                                      style: "font-size: 18px",
                                    },
                                  ],
                                  contentTxt: "3X ou 4X",
                                },
                                {
                                  balise: "b",
                                  childrens: [
                                    {
                                      balise: "sup",
                                      attributes: [
                                        {
                                          style:
                                            "font-size: 10px; font-weight: normal",
                                        },
                                      ],
                                      contentTxt: "(2)",
                                    },
                                  ],
                                  attributes: [
                                    {
                                      style: "font-size: 18px",
                                    },
                                  ],
                                },
                              ],
                              attributes: [
                                {
                                  style:
                                    "\n                                    width: max-content;\n                                    margin: 0px;\n                                    padding: 5px;\n                                    border-radius: 5px;\n                                    background-color: #ffe14d;\n                                    color: #f15145;\n                                    text-align: left;\n                                    font-size: 13px;\n                                    line-height: 1;\n                                ",
                                },
                              ],
                              contentTxt: "Payez en ",
                            },
                          ],
                          attributes: [
                            {
                              class: "editable",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          colspan: "2",
                        },
                        {
                          valign: "top",
                        },
                      ],
                    },
                  ],
                  attributes: [
                    {
                      id: "ProductPaiementPlusieursFois",
                    },
                    {
                      "data-section": "paiement-plusieurs-fois",
                    },
                  ],
                  ProductPaiementPlusieursFoisSection:
                    "ProductPaiementPlusieursFois",
                },
                {
                  balise: "tr",
                  isHidden: false,
                  childrens: [
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      childrens: [
                        {
                          balise: "div",
                          childrens: [
                            {
                              balise: "div",
                              attributes: [
                                {
                                  class: "editable",
                                },
                              ],
                              contentTxt:
                                "Lorem ipsum dolor sit, amet consectetur elit.\n                                        Quisquam, quod! Lorem ipsum dolor sit, amet\n                                        consectetur elit. Quisquam, quod!",
                              InputEditable: true,
                            },
                          ],
                          attributes: [
                            {
                              class: "linked-btn btn-510",
                              style: "text-decoration: none; color: #575757;",
                            },
                          ],
                        },
                      ],
                      attributes: [
                        {
                          valign: "top",
                        },
                        {
                          style:
                            "\n                                    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;\n                                ",
                        },
                      ],
                    },
                    {
                      balise: "td",
                      attributes: [
                        {
                          width: "10",
                        },
                      ],
                    },
                  ],
                  attributes: [
                    {
                      id: "ProductDescription",
                    },
                    {
                      "data-section": "description",
                    },
                  ],
                  descriptionSection: "descriptionSection",
                },
              ],
              attributes: [],
            },
          ],
          attributes: [
            {
              style:
                "\n                            height: 100%;\n                            width: 100%;\n                            color: #f5f5f5;\n                            font-size: 13px;\n                        ",
            },
            {
              border: "0",
            },
            {
              cellspacing: "0",
            },
          ],
        },
      ],
      attributes: [
        {
          valign: "top",
        },
        {
          align: "left",
        },
        {
          colspan: "2",
        },
        {
          height: "80",
        },
      ],
    },
  ],
  attributes: [
    {
      id: "ProductContainerDescription",
    },
    {
      "data-section": "container-description",
    },
  ],
  isHidden: false,
  ProductContainerDescription: "ProductContainerDescription",
};
