type IBalise =
  | "table"
  | "td"
  | "th"
  | "tr"
  | "a"
  | "img"
  | "p"
  | "h1"
  | "span"
  | "tbody"
  | "br"
  | string;

type Attr = {
  [key: string]: any;
};

interface IHTMLElement {
  balise: IBalise;
  attributes?: Attr[];
  contentTxt?: string;
  childrens?: IHTMLElement[];
  isHidden?: boolean;
}

export const parseJsonToHtml = (objects: IHTMLElement[]): string => {
  const htmlOfNewsletter = document.createElement("div");
  objects.map((object) => {
    return htmlOfNewsletter.appendChild(
      parseFakeHtmlElementToRealHtmlElement(object)
    );
  });
  return htmlOfNewsletter.outerHTML;
};

const parseFakeHtmlElementToRealHtmlElement = (
  object: IHTMLElement
): HTMLElement => {
  const html = document.createElement(object.balise);
  if (object.contentTxt) {
    html.innerHTML = object.contentTxt; // Convert encoded entities to actual HTML
  }
  // Ajout de chaque attribut
  if (object.attributes) {
    for (const attr of object.attributes) {
      for (const key in attr) {
        const value = attr[key];
        if (Array.isArray(value)) {
          html.setAttribute(key, value.join(" "));
        } else {
          html.setAttribute(key, value);
        }
      }
    }
  }

  object.childrens?.map((children) => {
    // On navigue à travers tout l'objet, si dans l'objet en question, il y a une clé isHidden === true, on passe à la suite pour ne pas parse l'objet en HTML
    if (object.isHidden) {
      return html;
    }
    return html.appendChild(parseFakeHtmlElementToRealHtmlElement(children));
  });

  return html;
};

interface IHTMLElementToJson {
  balise: IBalise;
  attributes?: Record<string, string>[];
  contentTxt?: string;
  childrens?: IHTMLElementToJson[];
  isHidden?: boolean;
}

export const htmlToObject = (html: string): IHTMLElementToJson => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  const parseAttributes = (node: Element): Record<string, string>[] => {
    return [
      Array.from(node.attributes).reduce((attributes, { name, value }) => {
        attributes[name] = value;
        return attributes;
      }, {} as Record<string, string>),
    ];
  };

  const parseElement = (element: Element): IHTMLElementToJson => {
    const obj: IHTMLElementToJson = {
      balise: element.tagName.toLowerCase(),
      attributes: parseAttributes(element),
    };

    if (element.children.length > 0) {
      obj.childrens = [];
      for (let i = 0; i < element.children.length; i++) {
        const child = element.children[i];
        if (child instanceof Element) {
          obj.childrens.push(parseElement(child));
        }
      }
    } else if (element.textContent && element.textContent.trim()) {
      if (
        element.childElementCount === 1 &&
        element.firstElementChild?.tagName.toLowerCase() === "div"
      ) {
        obj.contentTxt = element.firstElementChild.textContent!.trim();
      } else {
        obj.contentTxt = element.textContent.trim();
      }
    }

    return obj;
  };

  return parseElement(doc.body.firstElementChild!);
};

export const html = `
<table data-langue="FR" data-type="double-product" style="font-family: 'Segoe UI', Helvetica, Arial, sans-serif"
        border="0" cellspacing="0" class="full-width" align="center" width="700">
        <tbody>
            <tr>
                <td class="full-width" valign="top" align="center"><a
                        href="https://voyages.carrefour.fr/?utm_source=crm_carrefourvoyages&amp;utm_medium=newsletter&amp;utm_content=commerciale&amp;utm_campaign=bug+edition+double+product&amp;rlvt-u=%%EMAIL%%"
                        target="_blank">
                        <table data-type="product" data-product-paiement-promotion="false"
                            data-product-description="true" data-product-paiement-plusieurs-fois="true"
                            class="align-center" align="left" style="border: 1px solid #dadada; border-radius: 5px"
                            border="0" cellspacing="0" width="330" height="auto">
                            <tbody>
                                <tr>
                                    <td valign="top" align="center" width="330" height="180" colspan="3">
                                        <div class="editable-img" data-linked-btn-id="510"><img
                                                class="image-responsive editable"
                                                style="display: block;                        width: 100%;                        border-top-left-radius: 5px;                        border-top-right-radius: 5px;                        height: 180px;"
                                                border="0" alt="[pdt-title]" src="images/0.jpg"></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td valign="top">
                                        <table border="0" cellspacing="0" width="330" height="96">
                                            <tbody>
                                                <tr>
                                                    <td height="2"></td>
                                                </tr>
                                                <tr data-section="promoSection">
                                                    <td valign="top" align="left">
                                                        <table border="0" cellspacing="0">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="10"></td>
                                                                    <td
                                                                        style="font-size: 18px;                                font-weight: 700;                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;">
                                                                        <div class="linked-btn btn-510"
                                                                            style="color: #13173d;">
                                                                            <div class="editable">Titre produit</div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="10"></td>
                                                                    <td
                                                                        style="font-size: 12px;                                font-weight: 400;                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;">
                                                                        <div class="linked-btn btn-510"
                                                                            style="color: #575757;">
                                                                            <div class="editable">Sous Titre</div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td style="width: 120px" valign="top" align="right"
                                                        data-section="container-price">
                                                        <table border="0" cellspacing="0"
                                                            style="font-size: 12px; width: 100%; color: #0e3368">
                                                            <tbody>
                                                                <tr>
                                                                    <td valign="top" align="right"
                                                                        style="line-height: 1;                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;                                color: #575757;">
                                                                        <div class="editable">à partir de</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td valign="top" align="right"
                                                                        style="font-size: 32px;                                font-weight: 700;                                line-height: 1;                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;">
                                                                        <div class="linked-btn btn-510">
                                                                            <div class="editable">400€<sup
                                                                                    style="font-size: 16px">*</sup>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td width="10"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td valign="top" align="right"
                                                                        style="line-height: 1;                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;                                color: #575757;">
                                                                        <div class="editable">TTC/pers.</div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr data-section="spacing-product-between-title-description">
                                                    <td height="31"></td>
                                                </tr>
                                                <tr id="ProductContainerDescription"
                                                    data-section="container-description">
                                                    <td valign="top" align="left" colspan="2" height="80">
                                                        <table
                                                            style="height: 100%;                            width: 100%;                            color: #f5f5f5;                            font-size: 13px;"
                                                            border="0" cellspacing="0">
                                                            <tbody>
                                                                <tr id="ProductPaiementPlusieursFois"
                                                                    data-section="paiement-plusieurs-fois">
                                                                    <td width="10" height="15"></td>
                                                                    <td colspan="2" valign="top">
                                                                        <div class="editable">
                                                                            <p
                                                                                style="width: max-content;                                                margin: 0px;                                                padding: 5px;                                                border-radius: 5px;                                                background-color: #ffe14d;                                                color: #f15145;                                                text-align: left;                                                font-size: 13px;                                                line-height: 1;">
                                                                                Payez en <b style="font-size: 18px">3X
                                                                                    ou 4X</b><b
                                                                                    style="font-size: 18px"><sup
                                                                                        style="font-size: 10px; font-weight: normal">(2)</sup></b>
                                                                            </p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr id="ProductDescription" data-section="description">
                                                                    <td width="10"></td>
                                                                    <td valign="top"
                                                                        style="font-family: 'Segoe UI', Helvetica, Arial, sans-serif;">
                                                                        <div class="linked-btn btn-510"
                                                                            style="text-decoration: none; color: #575757;">
                                                                            <div class="editable">First ipsum dolor sit,
                                                                                amet consectetur elit. Quisquam, quod!
                                                                                Lorem ipsum dolor sit, amet consectetur
                                                                                elit. Quisquam, quod!</div>
                                                                        </div>
                                                                    </td>
                                                                    <td width="10"></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="0" height="10"
                                        style="font-size: 0; line-height: 0; border-collapse: collapse"></td>
                                </tr>
                                <tr>
                                    <td valign="top" align="center">
                                        <table
                                            style="font-family: 'Segoe UI', Helvetica, Arial, sans-serif;                    color: #fff;                    font-size: 14px;                    font-weight: bold;                    background: #f15145;                    width: 140px;                    height: 34px;                    border-radius: 24px;"
                                            border="0" cellspacing="0" width="140" height="40">
                                            <tbody>
                                                <tr>
                                                    <td valign="center" align="center">
                                                        <div class="linked-btn btn-510">
                                                            <div class="editable">VOIR L'OFFRE</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="0" height="10"
                                        style="font-size: 0; line-height: 0; border-collapse: collapse"></td>
                                </tr>
                            </tbody>
                        </table>
                    </a><a
                        href="https://www.mcdonalds.fr/?utm_source=crm_carrefourvoyages&amp;utm_medium=newsletter&amp;utm_content=commerciale&amp;utm_campaign=bug+edition+double+product&amp;rlvt-u=%%EMAIL%%"
                        target="_blank">
                        <table data-type="product" data-product-paiement-promotion="true"
                            data-product-description="true" data-product-paiement-plusieurs-fois="true"
                            class="align-center" align="right" style="border: 1px solid #dadada; border-radius: 5px"
                            border="0" cellspacing="0" width="330" height="auto">
                            <tbody>
                                <tr>
                                    <td valign="top" align="center" width="330" height="180" colspan="3">
                                        <div class="editable-img" data-linked-btn-id="511"><img
                                                class="image-responsive editable"
                                                style="display: block;                        width: 100%;                        border-top-left-radius: 5px;                        border-top-right-radius: 5px;                        height: 180px;"
                                                border="0" alt="[pdt-title]" src="images/1.jpg"></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td valign="top">
                                        <table border="0" cellspacing="0" width="330" height="96">
                                            <tbody>
                                                <tr>
                                                    <td height="2"></td>
                                                </tr>
                                                <tr data-section="promoSection">
                                                    <td valign="top" align="left">
                                                        <table border="0" cellspacing="0">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="10"></td>
                                                                    <td
                                                                        style="font-size: 18px;                                font-weight: 700;                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;">
                                                                        <div class="linked-btn btn-511"
                                                                            style="color: #13173d;">
                                                                            <div class="editable">Titre produit</div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td width="10"></td>
                                                                    <td
                                                                        style="font-size: 12px;                                font-weight: 400;                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;">
                                                                        <div class="linked-btn btn-511"
                                                                            style="color: #575757;">
                                                                            <div class="editable">Sous Titre</div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td style="width: 120px" valign="top" align="right"
                                                        data-section="container-price">
                                                        <table border="0" cellspacing="0"
                                                            style="font-size: 12px; width: 100%; color: #0e3368">
                                                            <tbody>
                                                                <tr>
                                                                    <td valign="top" align="right"
                                                                        style="line-height: 1;                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;                                color: #575757;">
                                                                        <div class="editable">à partir de</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td valign="top" align="right"
                                                                        style="font-size: 32px;                                font-weight: 700;                                line-height: 1;                                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;">
                                                                        <div class="linked-btn btn-511"
                                                                            style="color: #f07930;">
                                                                            <div class="editable">400€<sup
                                                                                    style="font-size: 16px">*</sup>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td width="10"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td valign="top" align="right"
                                                                        style="font-family: 'Segoe UI', Helvetica, Arial, sans-serif;                                color: #575757;">
                                                                        <div class="editable">TTC/pers.<br>au lieu de </div>
                                                                        <div class="editable">500€</div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td valign="top" align="right">
                                                                        <table align="right" valign="top">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td valign="top" align="right"
                                                                                        width="50px"
                                                                                        style="background-color: #f07930;                                    color: #ffffff;                                    font-family: 'Segoe UI', Helvetica, Arial,                                        sans-serif;                                    font-size: 12px;                                    text-decoration: none;                                    font-weight: normal;                                    display: block;">
                                                                                        <div class="editable">- 00 %
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr data-section="spacing-product-between-title-description"></tr>
                                                <tr id="ProductContainerDescription"
                                                    data-section="container-description">
                                                    <td valign="top" align="left" colspan="2" height="80">
                                                        <table
                                                            style="height: 100%;                            width: 100%;                            color: #f5f5f5;                            font-size: 13px;"
                                                            border="0" cellspacing="0">
                                                            <tbody>
                                                                <tr id="ProductPaiementPlusieursFois"
                                                                    data-section="paiement-plusieurs-fois">
                                                                    <td width="10" height="15"></td>
                                                                    <td colspan="2" valign="top">
                                                                        <div class="editable">
                                                                            <p
                                                                                style="width: max-content;                                                margin: 0px;                                                padding: 5px;                                                border-radius: 5px;                                                background-color: #ffe14d;                                                color: #f15145;                                                text-align: left;                                                font-size: 13px;                                                line-height: 1;">
                                                                                Payez en <b style="font-size: 18px">3X
                                                                                    ou 4X</b><b
                                                                                    style="font-size: 18px"><sup
                                                                                        style="font-size: 10px; font-weight: normal">(2)</sup></b>
                                                                            </p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr id="ProductDescription" data-section="description">
                                                                    <td width="10"></td>
                                                                    <td valign="top"
                                                                        style="font-family: 'Segoe UI', Helvetica, Arial, sans-serif;">
                                                                        <div class="linked-btn btn-510"
                                                                            style="text-decoration: none; color: #575757;">
                                                                            <div class="editable">Second ipsum dolor
                                                                                sit, amet consectetur elit. Quisquam,
                                                                                quod! Lorem ipsum dolor sit, amet
                                                                                consectetur elit. Quisquam, quod!</div>
                                                                        </div>
                                                                    </td>
                                                                    <td width="10"></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="0" height="10"
                                        style="font-size: 0; line-height: 0; border-collapse: collapse"></td>
                                </tr>
                                <tr>
                                    <td valign="top" align="center">
                                        <table
                                            style="font-family: 'Segoe UI', Helvetica, Arial, sans-serif;                    color: #fff;                    font-size: 14px;                    font-weight: bold;                    background: #f15145;                    width: 140px;                    height: 34px;                    border-radius: 24px;"
                                            border="0" cellspacing="0" width="140" height="40">
                                            <tbody>
                                                <tr>
                                                    <td valign="center" align="center">
                                                        <div class="linked-btn btn-511">
                                                            <div class="editable">VOIR L'OFFRE</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="0" height="10"
                                        style="font-size: 0; line-height: 0; border-collapse: collapse"></td>
                                </tr>
                            </tbody>
                        </table>
                    </a></td>
            </tr>
        </tbody>
    </table>
`;

// console.log(htmlToObject(html))

export const resultObject: IHTMLElementToJson = htmlToObject(html);
export const log = JSON.stringify(resultObject, null, 2);
