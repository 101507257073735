import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function ImageEdit({ ImageInputsEditable, setImageInputsEditable }) {
  if (ImageInputsEditable.length === 0) return <></>;
  return (
    <>
      <div>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="accordeon-button --overidemui"
          >
            <Typography className="accordeon-label --overidemui">
              Image
            </Typography>
          </AccordionSummary>
          {/* Content */}
          {ImageInputsEditable.length > 0 && (
            <>
              <AccordionDetails className="accordeon-details-container --overidemui">
                {ImageInputsEditable.map((inputEditable, i) => {
                  // console.log("i", i);
                  return (
                    <div
                      key={`inputEditable-img-${i}`}
                      className="text-properties-container --overidemui"
                    >
                      {i === 1 && (
                        <p className="second-section-separator">
                          Deuxième image
                        </p>
                      )}
                      {/* IMAGE URL */}
                      <div
                        className="backgroundcolor-container-controller --overidemui"
                        style={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: "10px",
                        }}
                      >
                        {/* TEXT SRC */}
                        <div
                          className="label-uploadbtn-wrapper"
                          style={{
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <p className="tabs-element-label-property --overidemui">
                            Image URL
                          </p>
                          <input
                            value={inputEditable.src}
                            onChange={(e) => {
                              let newArray = [...ImageInputsEditable];
                              newArray[i] = {
                                src: e.target.value,
                                alt: inputEditable?.alt,
                              };
                              setImageInputsEditable(newArray);
                            }}
                            type="text"
                            className="form-control --image"
                          />
                        </div>
                      </div>
                      {/* TEXT ALTERNATIF */}
                      <div
                        className="backgroundcolor-container-controller --overidemui"
                        style={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                          borderBottom: "none",
                          paddingBottom: "0",
                        }}
                      >
                        <div className="label-uploadbtn-wrapper">
                          <p className="tabs-element-label-property --overidemui">
                            Texte Alternatif
                          </p>
                        </div>
                        <input
                          value={inputEditable.alt}
                          onChange={(e) => {
                            let newArray = [...ImageInputsEditable];
                            newArray[i] = {
                              src: inputEditable?.src,
                              alt: e.target.value,
                            };
                            setImageInputsEditable(newArray);
                          }}
                          type="text"
                          className="form-control --image"
                        />
                      </div>
                    </div>
                  );
                })}
              </AccordionDetails>
            </>
          )}
        </Accordion>
      </div>
    </>
  );
}

export default ImageEdit;
