import React from "react";


function ColorPicker({
  disableColorPicker,
  disableRedBarPicker,
  displayResetColorBtn,
  displaySketchPicker,
  resetColorPicker,
  currentColor,
}) {
  return (
    <>
      <div
        className="backgroundcolor-picker --overidemui"
        style={{ backgroundColor: currentColor }}
        onClick={displaySketchPicker}
      >
        {displayResetColorBtn && (
          <div className="closebtn" onClick={resetColorPicker}>
            <i
              className="fa-solid fa-circle-xmark --overidemui"
              onClick={disableColorPicker}
            />
          </div>
        )}
        <span
          className="unselected-color --overidemui"
          style={{ display: disableRedBarPicker }}
        ></span>
      </div>
    </>
  );
}

export default ColorPicker;
