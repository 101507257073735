import { useContext } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Accordion, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { EditorContext } from "../../../../context/EditorContext";

export default function ProductCheckbox() {
  const { ProductInputsEditable, setProductInputsEditable } =
    useContext(EditorContext);

  const {itemActuallyEdited, setitemActuallyEdited} = useContext(EditorContext)

  const handleChangePromo = (event, i) => {
    setitemActuallyEdited((itemActuallyEdited) => {
      const updatedItemProductActuallyEdited = structuredClone(itemActuallyEdited);
      const promoKey = `promo_${i + 1}`
      updatedItemProductActuallyEdited.statut[promoKey] = !updatedItemProductActuallyEdited.statut[promoKey]
      return updatedItemProductActuallyEdited;
    });
  };
  

    const handleChangeDescription = (event, i) => {
      setitemActuallyEdited((itemActuallyEdited) => {
        const updatedItemProductActuallyEdited = structuredClone(itemActuallyEdited);
        const descriptionKey = `description_${i + 1}`
        updatedItemProductActuallyEdited.statut[descriptionKey] = !updatedItemProductActuallyEdited.statut[descriptionKey]
        return updatedItemProductActuallyEdited;
      });
    };

    const handleChangeMultiplePaiement = (event, i) => {
      setitemActuallyEdited((itemActuallyEdited) => {
        const updatedItemProductActuallyEdited = structuredClone(itemActuallyEdited);
        const multiplePaiementKey = `multiplePaiement_${i + 1}`
        updatedItemProductActuallyEdited.statut[multiplePaiementKey] = !updatedItemProductActuallyEdited.statut[multiplePaiementKey]
        return updatedItemProductActuallyEdited;
      });
    };

  return (
    <>
      {Array.isArray(ProductInputsEditable) &&
        ProductInputsEditable.length > 0 && itemActuallyEdited &&
        ProductInputsEditable.map((ProductInputEditable, i) => {
          const promoKey = `promo_${i + 1}`
          const descriptionKey = `description_${i + 1}`
          const multiplePaiementKey = `multiplePaiement_${i + 1}`
          return (
            <Accordion elevation={0} key={i}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel1a-content`}
                id={`panel1a-header`}
                className="accordeon-button --overidemui"
              >
                <Typography className="accordeon-label --overidemui">
                  Paramètres produit {i + 1}
                </Typography>
              </AccordionSummary>
              <Box sx={{ display: "flex", backgroundColor: "#f9f9f9" }}>
                <FormControl
                  sx={{ m: 3 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                        checked={Boolean(itemActuallyEdited.statut[promoKey])}
                        onChange={(e) => {
                          handleChangePromo(e, i);
                        }}
                        name="isInPromo"
                        />
                      }
                      label="Produit en réduction"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(itemActuallyEdited.statut[descriptionKey])}
                          onChange={(e) => {
                            handleChangeDescription(e, i);
                          }}
                          name="isDescription"
                        />
                      }
                      label="Produit avec description"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          // id = {i + 1}
                          checked={Boolean(itemActuallyEdited.statut[multiplePaiementKey])}
                          onChange={(e) => {
                            handleChangeMultiplePaiement(e, i);
                          }}
                          name="isMultiplePaiement"
                        />
                      }
                      label="Produit avec paiement en plusieurs fois"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            </Accordion>
          );
        })}
    </>
  );
}
