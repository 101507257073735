// # src/helpers.js

import { AUTH_TOKEN } from "../constants";

export const getAuthToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
    localStorage.setItem("langue", "FR");
  }
};

export const removeAuthToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem("langue");
};
