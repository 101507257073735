// # src/constant.js

export const AVATAR_API = "https://ui-avatars.com/api";
// Si vous lancez le back avec npm run develop utilisez localhost:1337 si vous utilisez docker gardez localhost:13371
export const API_BASE_URL = `${process.env.NODE_ENV == "development" ? process.env.REACT_APP_LOCAL_URL_API : "https://back-new-delhi.cv-tools.fr/api/" }`;
// export const API_BASE_URL = `${process.env.NODE_ENV == "development" ? "http://localhost:13371/api/" : "https://back-new-delhi.cv-tools.fr/api/" }`;
export const AUTH_TOKEN = "authToken";
export const BEARER = "Bearer";

export const SIDEBAR_ITEM = "sidebarItem";
export const ROW = "row";
export const COLUMN = "column";
export const COMPONENT = "component";

// export const SIDEBAR_ITEMS = [
//   {
//     id: shortid.generate(),
//     type: SIDEBAR_ITEM,
//     component: {
//       type: "row",
//       content: "",
//       icon: <ViewColumnIcon className="testIconBar" />,
//     },
//   },
//   {
//     id: shortid.generate(),
//     type: SIDEBAR_ITEM,
//     component: {
//       type: "heading",
//       content: "<h1>hello world</h1>",
//       icon: <HMobiledataIcon className="testIconBar" />,
//     },
//   },
//   {
//     id: shortid.generate(),
//     type: SIDEBAR_ITEM,
//     component: {
//       type: "text",
//       content: "<p>example</p>",
//       icon: <TextFieldsIcon className="testIconBar" />,
//     },
//   },
//   {
//     id: shortid.generate(),
//     type: SIDEBAR_ITEM,
//     component: {
//       type: "image",
//       content: (
//         <img
//           src="https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/2a/86/f7/2a86f7c0-8342-ea85-5f0a-d97352be4760/cover.jpg/1200x1200bf-60.jpg"
//           style={{ width: "190px,", objectFit: "cover", height: "190px" }}
//         />
//       ),
//       icon: <ImageIcon className="testIconBar" />,
//     },
//   },
//   {
//     id: shortid.generate(),
//     type: SIDEBAR_ITEM,
//     component: {
//       type: "button",
//       content: <button>CALL TO ACTION</button>,
//       icon: <Crop169Icon className="testIconBar" />,
//     },
//   },
//   {
//     id: shortid.generate(),
//     type: SIDEBAR_ITEM,
//     component: {
//       type: "html",
//       content: (
//         <button
//           style={{
//             width: "80px",
//             height: "80px",
//           }}
//           onClick={() => {
//             console.log(TestTiny.log);
//           }}
//         ></button>
//       ),
//       icon: <CodeIcon className="testIconBar" />,
//     },
//   },
//   {
//     id: shortid.generate(),
//     type: SIDEBAR_ITEM,
//     component: {
//       type: "headertest",
//       content: `<table
//           class="container"
//           border="0"
//           cellspacing="0"
//           cellpadding="0"
//           align="center"
//           width="700"
//           align="center"
//         >
//           <tbody>
//             <tr>
//               <td height="15" style="font-size:0">
//                 &nbsp;
//               </td>
//             </tr>
//             <tr>
//               <td valign="middle" align="left">
//                 <a
//                   title="Carrefour Voyages"
//                   style="text-decoration: none;"
//                   href="[tracking-start]https://voyages.carrefour.fr/[tracking-end]_header-logo"
//                   target="_blank"
//                 >
//                   <img
//                     style="display: block;"
//                     border="0"
//                     hspace="0"
//                     alt="Carrefour Voyages"
//                     vspace="0"
//                     src="https://back.voyages.carrefour.fr/admin/TS/fckUserFiles/Image/newsletter/static/logo-carrefour_voyages-v2.png"
//                     width="166"
//                     height="44"
//                   />
//                 </a>
//               </td>
//               <td valign="middle" align="right">
//                 <table
//                   class="width-auto"
//                   style="width:auto;"
//                   align="right"
//                   cellspacing="0"
//                   cellpadding="0"
//                 >
//                   <tr>
//                     <td
//                       style="font-family:'Segoe UI', Helvetica, Arial, sans-serif; color: #fff;"
//                       align="left"
//                       valign="middle"
//                       colspan="2"
//                     >
//                       <span style="background-color: #0970e6; padding: 0 10px; border-top-right-radius: 15px; border-bottom-right-radius: 15px;">
//                         Profitez-en !
//                       </span>
//                     </td>
//                   </tr>
//                   <tr>
//                     <td
//                       align="left"
//                       valign="middle"
//                       style="color: #0E3368;"
//                       width="40"
//                     >
//                       <a
//                         style="display: block; width: 100%; height: 40px;"
//                         href="[tracking-start]https://voyages.carrefour.fr/accueil/paiement-securise[tracking-end]"
//                       >
//                         <img
//                           style="display: inline-block; width: 40px;"
//                           src="https://back.voyages.carrefour.fr/admin/TS/fckUserFiles/Image/newsletter/static/picto-carte-blue.png"
//                           alt="icone user"
//                         />
//                       </a>
//                     </td>
//                     <td
//                       style="font-family:'Segoe UI', Helvetica, Arial, sans-serif; color: #0E3368; font-size: 13px;"
//                       align="left"
//                       valign="middle"
//                     >
//                       <div class="editable">
//                         Règlement en
//                         <br />
//                         <strong>4 fois sans frais</strong>
//                       </div>
//                     </td>
//                   </tr>
//                 </table>
//               </td>
//             </tr>
//             <tr>
//               <td height="15" style="font-size:0">
//                 &nbsp;
//               </td>
//             </tr>
//           </tbody>
//         </table>`,
//       // icon: <CodeIcon className="testIconBar" />,
//     },
//   },
//   {
//     id: shortid.generate(),
//     type: SIDEBAR_ITEM,
//     component: {
//       type: "producttest",
//       content: `<table class="full-width" style="border: 1px solid #dadada; border-radius: 5px;" border="0"
//     cellspacing="0" cellpadding="0" width="700" height="300" align="center">
// <tr>
//   <td valign="top" align="center">
//       <!-- IMAGE -->
//       <table class="full-width" cellspacing="0" cellpadding="0" border="0" class="full-width" align="left" valign="top" width="500" height="300" >
//           <tr>
//               <td>
//                   <div class="editable-img" data-linked-btn-id="510">
//                       <a style="text-decoration: none;" href="#[url]" title="[pdt-title]"
//                           target="_blank">
//                           <img height="300" class="image-responsive" style="display: block; width: 100%;"
//                               border="0" alt="[pdt-title]" src="[src]">
//                       </a>
//                   </div>
//               </td>
//           </tr>
//       </table >
//       <!-- DESCRIPTION -->
//       <table class="full-width" width="195" cellspacing="0" height="300" cellpadding="0" border="0" align="center" valign="top">
//           <!-- SPACING -->
//           <tr>
//               <td class="remove" valign="top" height="20"></td>
//           </tr>
//           <!-- SPACING -->
//           <tr>
//               <td valign="top" height="20"></td>
//           </tr>
//           <!-- PAYS -->
//           <tr>
//               <td style="font-family:'Segoe UI', Helvetica, Arial, sans-serif; color: #0e3368; font-size: 30px;" align="center" valign="top">
//                   <div class="editable">
//                       Grèce<br>
//                       <span style="font-size: 16px;">Crète</span>
//                   </div>
//               </td>
//           </tr>
//           <!-- SPACING -->
//           <tr>
//               <td valign="top" height="15"></td>
//           </tr>
//           <!-- Prix -->
//           <tr>
//               <td height="55" style="font-family:'Segoe UI', Helvetica, Arial, sans-serif; color: #0e3368; font-size: 12px;" align="center" valign="top">
//                   <div class="editable">
//                       dès <b style="font-size: 40px;">4508€<sup style="font-size: 16px">*</sup></b>
//                   </div>
//               </td>
//           </tr>
//           <!-- SPACING -->
//           <tr class="remove">
//               <td valign="top" height="20"></td>
//           </tr>
//           <!-- BTN ACHAT -->
//           <tr>
//               <td align="center" valign="top">
//                   <table style="width: 140px; height: 40px;" cellspacing="0" cellpadding="0" align="center" valign="middle">
//                       <tr>
//                           <td style="font-family:'Segoe UI', Helvetica, Arial, sans-serif; color: #fff; font-size: 14px; background: #F15145; width: 140px; height: 40px; border-radius: 24px;" align="center" valign="middle">
//                               <div class="linked-btn btn-510">
//                                   <a href="#[url]"
//                                       style="text-decoration: none; color: #fff;"
//                                       title="Voir l'offre - [pdt-title]"
//                                       target="_blank">
//                                       <div class="editable">
//                                           <strong>JE DECOUVRE</strong>
//                                       </div>
//                                   </a>
//                               </div>
//                           </td>
//                       </tr>
//                   </table>
//               </td>
//           </tr>
//           <!-- SPACING -->
//           <tr>
//               <td valign="top" height="20"></td>
//           </tr>
//       </table>
//   </td>
// </tr>
//     </table>`,
//     },
//   },
// ];

// export const SIDEBAR_NAV = ["content", "blocks", "body", "images"];
