import {
  createContext,
  useState,
  forwardRef,
  useContext,
  useEffect,
} from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Slide from "@mui/material/Slide"
import { useNavigate, useParams } from "react-router-dom"
import NewsletterService from "../services/NewsletterService"
import TemplateService from "../services/TemplateService"
import { AuthContext } from "./AuthContext"
import { getAuthToken } from "../utils/helpers"
import NotificationEditor from "../components/Editor/NotificationEditor"
import {
  contentFirstWithPromo,
  contentFirstWithoutPromo,
  contentSecondWithPromo,
  contentSecondWithoutPromo,
  contentWithFirstDescription,
  contentWithSecondDescription,
  multiplePaiementSection,
  VcontentFirstWithPromo,
  VcontentFirstWithoutPromo,
  VcontentSecondWithPromo,
  VcontentSecondWithoutPromo,
  VcontentWithFirstDescription,
  VcontentWithSecondDescription,
  VmultiplePaiementSection,
} from "../utils/DoubleProductSection"
const EditorContext = createContext()

 

function collectContentObjects(obj, contentObjects = []) {
  if (!obj || typeof obj !== "object") {
    return contentObjects // Retourne le tableau des objets collectés
  }

  // Vérifie si l'objet a à la fois "src" et "alt" et les stocke dans le même objet
  if (obj.hasOwnProperty("src") && obj.hasOwnProperty("alt")) {
    contentObjects.push({
      src: obj.src,
      alt: obj.alt,
    })
  }
  if (obj.hasOwnProperty("contentTxt") && obj.hasOwnProperty("InputEditable")) {
    contentObjects.push(obj)
  }
  if (obj.hasOwnProperty("href")) {
    contentObjects.push(obj)
  }
  for (let key in obj) {
    collectContentObjects(obj[key], contentObjects)
  }
  return contentObjects
}

function collectDoubleProductSection(obj, contentObjects = []) {
  if (!obj || typeof obj !== "object") {
    return contentObjects // Retourne le tableau des objets collectés
  }
  if (obj.hasOwnProperty("isInPromo")) {
    contentObjects.push(obj)
  }

  for (let key in obj) {
    collectDoubleProductSection(obj[key], contentObjects)
  }
  return contentObjects
}

const EditorContextProvider = ({ children }) => {
  const [NewsletterProperty, setNewsletterProperty] = useState(null)
  const [Components, setComponents] = useState([])
  const [FirstClickItemEdited, setFirstClickItemEdited] = useState(false);
  const [itemActuallyEdited, setitemActuallyEdited] = useState(null)
  const [itemActuallyEditedLinks, setitemActuallyEditedLinks] = useState([])
  const [toggleDeleteModal, setToggleDeleteModal] = useState(false)
  const [NotificationToggle, setNotificationToggle] = useState({
    state: false,
  })

  //Déclaration d'une variable d'état qui a pour valeur initial trois tableau
  //'TextInputsEditable', 'ImageInputsEditable' et 'LinkInputsEditable' vide.
  const [InputsEditable, setInputsEditable] = useState({
    TextInputsEditable: [],
    ImageInputsEditable: [],
    LinkInputsEditable: [],
  })

  const [ProductInputsEditable, setProductInputsEditable] = useState([])
  const [doubleProductParameters, setdDoubleProductParameters] = useState({
    isInPromo: false,
    isDescription: false,
    isMultiplePaiement: false,
  })

  
  // Fonction pour extraire les attributs "src", "alt" et "href" (ou autres...) avec leurs valeurs

  const extractAttributes = (content, keys) => {
    const attributesArray = []
    const extractFromChildren = (children) => {
      if (!children) return
      children.forEach((child) => {
        if (child.attributes) {
          const attributeObject = {}
          keys.forEach((key) => {
            const attribute = child.attributes.find((attr) => attr[key])

            // Check si un attribut existe et check pour href, si l'objet contient linkNotEditable true alors il ne faut pas push
            if (attribute && !attribute.linkNotEditable) {
              attributeObject[key] = attribute[key]
            }
          })
          if (Object.keys(attributeObject).length > 0) {
            attributesArray.push(attributeObject)
          }
        }
        if (child.childrens) {
          extractFromChildren(child.childrens)
        }
      })
    }
    extractFromChildren(content)
    return attributesArray
  }

  // Fonction pour extraire les champs TEXTE (contentTxt) uniquement si la clé InputEditable se trouve dans le même objet (permet de gérer quels inputs on souhaite afficher et rendre accessible à l'édition)

  const extractContentTxt = (content) => {
    const contentTxtArray = []
    const extractFromChildren = (children) => {
      if (!children) return
      children.forEach((child) => {
        if (child.contentTxt && child.InputEditable) {
          contentTxtArray.push({ contentTxt: child.contentTxt })
        }
        if (child.childrens) {
          extractFromChildren(child.childrens)
        }
      })
    }
    extractFromChildren(content)
    return contentTxtArray
  }

  const [oldContentVersion, setoldContentVersion] = useState(null)
  const [oldSecondContentVersion, setoldSecondContentVersion] = useState(null)

  const [stateProduct, setStateProduct] = useState({statut : {
    promo_1 : false,
    description_1: false,
    multiplePaiement_1 : false,
    promo_2 : false,
    description_2 : false,
    multiplePaiement_2 : false,
  }})

  
  // SET ITEM ACTUALLY EDITED  // (Fonction déclanché au clic d'un composant pour récupérer toute ses informations)

    const handleComponentEditable = (event, idComponent) => {
      //Si event n'est pas null ou undefined, il appelle la fonction stopPropagation qui empêche l'évenement de se propager
      event?.stopPropagation()
  
      let componentHandle = {
        ...NewsletterProperty?.components.find(
          (component) => component.id === idComponent
        ),
      }
      
      if (componentHandle.type === 'product' && !componentHandle.statut) {
        componentHandle = structuredClone(componentHandle)
        componentHandle = {...componentHandle, ...stateProduct}
        console.log('rere')
      }
      
  
      const linksArray = extractAttributes(componentHandle.content, ["href"])
      // Les clés src et alt puis href
      // Récupérer les clés et leurs valeurs sous forme d'array d'objets
      const attributesArray = extractAttributes(componentHandle.content, [
        "src",
        "alt",
      ])
      // Récupérer les champs contentTxt sous forme d'array d'objets
      const contentTxtArray = extractContentTxt(componentHandle.content)
  
      if (componentHandle.name === "📦 Deux produits reduction 📦") {
        setoldContentVersion(contentTxtArray)
      }
  
      if (componentHandle.name === "📦 Deux produits reduction 2 📦") {
        setoldSecondContentVersion(contentTxtArray)
      }
  
      if (componentHandle.name === "📦 Deux produits reduction 3 📦") {
        setoldSecondContentVersion(contentTxtArray)
      }
  
      if (componentHandle.name === "📦 Deux produits reduction 4 📦") {
        setoldSecondContentVersion(contentTxtArray)
      }
  
      const doubleProductSection = collectDoubleProductSection(
        componentHandle.content
      )
  
      // Initialiser les états des checkbox
  
      if (componentHandle.type === "product" && !FirstClickItemEdited) {
        setProductInputsEditable([
          {
            isDescription: doubleProductSection[0]?.isDescription,
            isInPromo: doubleProductSection[0]?.isInPromo,
            isMultiplePaiement: doubleProductSection[0]?.isMultiplePaiement,
          },
          {
            isDescription: doubleProductSection[1]?.isDescription,
            isInPromo: doubleProductSection[1]?.isInPromo,
            isMultiplePaiement: doubleProductSection[1]?.isMultiplePaiement,
          },
        ])
      }
  
      // Afficher le tableau d'objets
      setInputsEditable((prevInputsEditable) => ({
        ...prevInputsEditable,
        TextInputsEditable: contentTxtArray,
        ImageInputsEditable: attributesArray,
        LinkInputsEditable: linksArray,
      }))
  
      
      setitemActuallyEdited(
        {...componentHandle,
        clientX: event?.clientX,
        clientY: event?.clientY,
      })
      
      setitemActuallyEditedLinks(linksArray)
    }

  // Edition des composants
  useEffect(() => {
    if (FirstClickItemEdited && !itemActuallyEdited) setFirstClickItemEdited(false);
    if (!itemActuallyEdited) return
    setFirstClickItemEdited(true)

    const updatedItemActuallyEdited = structuredClone(itemActuallyEdited)

    const contentObjects = collectContentObjects(
      updatedItemActuallyEdited.content
    )

    if (itemActuallyEdited.type === "product") {
      const doubleProductSection = collectDoubleProductSection(
        updatedItemActuallyEdited.content
      )

      function updateSectionControllerState(doubleProductSection) {
        let sectionDescription = 0
        let multiplePaiementSection = 0
        let promoSection = 0

        return doubleProductSection.map((section) => {
          section.isDescription =
            ProductInputsEditable[sectionDescription]?.isDescription
          sectionDescription++

          section.isInPromo = ProductInputsEditable[promoSection]?.isInPromo
          promoSection++

          section.isMultiplePaiement =
            ProductInputsEditable[multiplePaiementSection]?.isMultiplePaiement
          multiplePaiementSection++

          return section
        })
      }

      const updatedDoubleProductSectionState =
        updateSectionControllerState(doubleProductSection)

      if (itemActuallyEdited.name === "📦 Deux produits reduction 📦") {
        
        function updatePromoSection(sections,) {
          return sections.map((section) => {
            
            // Fonction récursive pour naviguer à travers les enfants
            const updateChildren = (children) => {
              return children.map((child) => {
                // REMOVE OR ADD Section
                if (child.promoSection) {
                  if (section.first_product) {
                    child = section.isInPromo
                      ? contentFirstWithPromo
                      : contentFirstWithoutPromo
                  }
                  if (section.second_product) {
                    child = section.isInPromo
                      ? contentSecondWithPromo
                      : contentSecondWithoutPromo
                  }
                }
                if (child.descriptionSection) {
                  // Remplacer la valeur de promoSection
                  if (section.first_product)
                    child = section.isDescription
                      ? contentWithFirstDescription
                      : { descriptionSection: "descriptionSection" }
                  if (section.second_product)
                    child = section.isDescription
                      ? contentWithSecondDescription
                      : { descriptionSection: "descriptionSection" }
                }
                if (child.ProductPaiementPlusieursFoisSection) {
                  // Remplacer la valeur de promoSection
                  child = section.isMultiplePaiement
                    ? multiplePaiementSection
                    : {
                        ProductPaiementPlusieursFoisSection:
                          "ProductPaiementPlusieursFoisSection",
                      }
                }

                // REMOVE SPACE CONTROLLER
                if (child.spacingProductSection) {
                  const prevchild = { ...child }

                  if (
                    section.isDescription === true &&
                    section.isMultiplePaiement === true
                  ) {
                    prevchild.isHidden = false
                  }

                  if (
                    (section.isDescription === false &&
                      section.isMultiplePaiement === false) ||
                    section.isInPromo === true
                  ) {
                    prevchild.isHidden = true
                  }

                  child = prevchild
                }
                if (child.ProductContainerDescription) {
                  const prevchild = { ...child }

                  if (
                    section.isDescription === false &&
                    section.isMultiplePaiement === false
                  ) {
                    prevchild.isHidden = true
                  }

                  if (
                    section.isDescription === true ||
                    section.isMultiplePaiement === true
                  ) {
                    prevchild.isHidden = false
                  }

                  child = prevchild
                }

                if (child.childrens) {
                  // Si l'enfant a des enfants, récursion
                  child.childrens = updateChildren(child.childrens)
                }
                return child
              })
            }

            // Mettre à jour les enfants
            section.childrens = updateChildren(section.childrens)
            

            return section
          })
        }
        // // Utilisation de la fonction pour mettre à jour les sections
        updatePromoSection(doubleProductSection)
      }

      if (itemActuallyEdited.name === "📦 Deux produits reduction 2 📦") {
        function updatePromoSection(sections) {
          return sections.map((section) => {
            // Fonction récursive pour naviguer à travers les enfants
            const updateChildren = (children) => {
              return children.map((child) => {
                // REMOVE OR ADD Section
                if (child.promoSection) {
                  if (section.first_product) {
                    child = section.isInPromo
                      ? VcontentFirstWithPromo
                      : VcontentFirstWithoutPromo
                  }
                  if (section.second_product) {
                    child = section.isInPromo
                      ? VcontentSecondWithPromo
                      : VcontentSecondWithoutPromo
                  }
                }
                if (child.descriptionSection) {
                  // Remplacer la valeur de promoSection
                  if (section.first_product)
                    child = section.isDescription
                      ? VcontentWithFirstDescription
                      : { descriptionSection: "descriptionSection" }
                  if (section.second_product)
                    child = section.isDescription
                      ? VcontentWithSecondDescription
                      : { descriptionSection: "descriptionSection" }
                }
                if (child.ProductPaiementPlusieursFoisSection) {
                  // Remplacer la valeur de promoSection
                  child = section.isMultiplePaiement
                    ? VmultiplePaiementSection
                    : {
                        ProductPaiementPlusieursFoisSection:
                          "ProductPaiementPlusieursFoisSection",
                      }
                }

                // REMOVE SPACE CONTROLLER
                if (child.spacingProductSection) {
                  const prevchild = { ...child }

                  if (
                    section.isDescription === true &&
                    section.isMultiplePaiement === true
                  ) {
                    prevchild.isHidden = false
                  }

                  if (
                    (section.isDescription === false &&
                      section.isMultiplePaiement === false) ||
                    section.isInPromo === true
                  ) {
                    prevchild.isHidden = true
                  }

                  child = prevchild
                }
                if (child.ProductContainerDescription) {
                  const prevchild = { ...child }

                  if (
                    section.isDescription === false &&
                    section.isMultiplePaiement === false
                  ) {
                    prevchild.isHidden = true
                  }

                  if (
                    section.isDescription === true ||
                    section.isMultiplePaiement === true
                  ) {
                    prevchild.isHidden = false
                  }

                  child = prevchild
                }

                if (child.childrens) {
                  // Si l'enfant a des enfants, récursion
                  child.childrens = updateChildren(child.childrens)
                }
                return child
              })
            }

            // Mettre à jour les enfants
            section.childrens = updateChildren(section.childrens)

            return section
          })
        }
        // // Utilisation de la fonction pour mettre à jour les sections
        updatePromoSection(doubleProductSection)
      }

      if (itemActuallyEdited.name === "📦 Deux produits reduction 3 📦") {
        function updatePromoSection(sections) {
          return sections.map((section) => {
            // Fonction récursive pour naviguer à travers les enfants
            const updateChildren = (children) => {
              return children.map((child) => {
                // REMOVE OR ADD Section
                if (child.promoSection) {
                  if (section.first_product) {
                    child = section.isInPromo
                      ? contentFirstWithPromo
                      : contentFirstWithoutPromo
                  }
                  if (section.second_product) {
                    child = section.isInPromo
                      ? contentSecondWithPromo
                      : contentSecondWithoutPromo
                  }
                }
                if (child.descriptionSection) {
                  // Remplacer la valeur de promoSection
                  if (section.first_product)
                    child = section.isDescription
                      ? contentWithFirstDescription
                      : { descriptionSection: "descriptionSection" }
                  if (section.second_product)
                    child = section.isDescription
                      ? contentWithSecondDescription
                      : { descriptionSection: "descriptionSection" }
                }
                if (child.ProductPaiementPlusieursFoisSection) {
                  // Remplacer la valeur de promoSection
                  child = section.isMultiplePaiement
                    ? multiplePaiementSection
                    : {
                        ProductPaiementPlusieursFoisSection:
                          "ProductPaiementPlusieursFoisSection",
                      }
                }

                // REMOVE SPACE CONTROLLER
                if (child.spacingProductSection) {
                  const prevchild = { ...child }

                  if (
                    section.isDescription === true &&
                    section.isMultiplePaiement === true
                  ) {
                    prevchild.isHidden = false
                  }

                  if (
                    (section.isDescription === false &&
                      section.isMultiplePaiement === false) ||
                    section.isInPromo === true
                  ) {
                    prevchild.isHidden = true
                  }

                  child = prevchild
                }
                if (child.ProductContainerDescription) {
                  const prevchild = { ...child }

                  if (
                    section.isDescription === false &&
                    section.isMultiplePaiement === false
                  ) {
                    prevchild.isHidden = true
                  }

                  if (
                    section.isDescription === true ||
                    section.isMultiplePaiement === true
                  ) {
                    prevchild.isHidden = false
                  }

                  child = prevchild
                }

                if (child.childrens) {
                  // Si l'enfant a des enfants, récursion
                  child.childrens = updateChildren(child.childrens)
                }
                return child
              })
            }

            // Mettre à jour les enfants
            section.childrens = updateChildren(section.childrens)

            return section
          })
        }
        // // Utilisation de la fonction pour mettre à jour les sections
        updatePromoSection(doubleProductSection)
      }

      if (itemActuallyEdited.name === "📦 Deux produits reduction 4 📦") {
        function updatePromoSection(sections) {
          return sections.map((section) => {
            // Fonction récursive pour naviguer à travers les enfants
            const updateChildren = (children) => {
              return children.map((child) => {
                // REMOVE OR ADD Section
                if (child.promoSection) {
                  if (section.first_product) {
                    child = section.isInPromo
                      ? contentFirstWithPromo
                      : contentFirstWithoutPromo
                  }
                  if (section.second_product) {
                    child = section.isInPromo
                      ? contentSecondWithPromo
                      : contentSecondWithoutPromo
                  }
                }
                if (child.descriptionSection) {
                  // Remplacer la valeur de promoSection
                  if (section.first_product)
                    child = section.isDescription
                      ? contentWithFirstDescription
                      : { descriptionSection: "descriptionSection" }
                  if (section.second_product)
                    child = section.isDescription
                      ? contentWithSecondDescription
                      : { descriptionSection: "descriptionSection" }
                }
                if (child.ProductPaiementPlusieursFoisSection) {
                  // Remplacer la valeur de promoSection
                  child = section.isMultiplePaiement
                    ? multiplePaiementSection
                    : {
                        ProductPaiementPlusieursFoisSection:
                          "ProductPaiementPlusieursFoisSection",
                      }
                }
  
                // REMOVE SPACE CONTROLLER
                if (child.spacingProductSection) {
                  const prevchild = { ...child }
  
                  if (
                    section.isDescription === true &&
                    section.isMultiplePaiement === true
                  ) {
                    prevchild.isHidden = false
                  }
  
                  if (
                    (section.isDescription === false &&
                      section.isMultiplePaiement === false) ||
                    section.isInPromo === true
                  ) {
                    prevchild.isHidden = true
                  }
  
                  child = prevchild
                }
                if (child.ProductContainerDescription) {
                  const prevchild = { ...child }
  
                  if (
                    section.isDescription === false &&
                    section.isMultiplePaiement === false
                  ) {
                    prevchild.isHidden = true
                  }
  
                  if (
                    section.isDescription === true ||
                    section.isMultiplePaiement === true
                  ) {
                    prevchild.isHidden = false
                  }
  
                  child = prevchild
                }
  
                if (child.childrens) {
                  // Si l'enfant a des enfants, récursion
                  child.childrens = updateChildren(child.childrens)
                }
                return child
              })
            }
  
            // Mettre à jour les enfants
            section.childrens = updateChildren(section.childrens)
  
            return section
          })
        }
        // // Utilisation de la fonction pour mettre à jour les sections
        updatePromoSection(doubleProductSection)
      }

    }

    // On utilise contentObjects pour mettre à jour les objets ayant du composants qui sont éditables : contentTxt, href, src, alt...
    let testIndex = 0
    contentObjects.forEach((editableContent, index) => {
      // TXT
      if (
        editableContent.contentTxt &&
        InputsEditable.TextInputsEditable[testIndex]?.contentTxt
      ) {
        editableContent.contentTxt =
          InputsEditable.TextInputsEditable[testIndex]?.contentTxt
        testIndex++
      }
      // IMG SRC ALT
      if (
        editableContent.src &&
        editableContent.alt &&
        InputsEditable.ImageInputsEditable[index]
      ) {
        editableContent.src = InputsEditable.ImageInputsEditable[index]?.src
        editableContent.alt = InputsEditable.ImageInputsEditable[index]?.alt
      }
      // LINK
      if (editableContent.href && InputsEditable.LinkInputsEditable[index]) {
        editableContent.href = InputsEditable.LinkInputsEditable[index]?.href
      }
    })

    // Maintenant, vous pouvez mettre à jour le composant
    const updateEditableContent = (content, newContentObjects) => {
      if (!content || typeof content !== "object") {
        // Si content n'est pas défini ou n'est pas un objet, retournez-le tel quel
        return content
      }
      const updatedContent = Array.isArray(content)
        ? [...content]
        : { ...content }
      let textIndex = 0
      let imageIndex = 0
      let linksIndex = 0
      let titleIndex = 0
      // Liste des liens nécessaire pour que l'utilisateur puisse directement vérifier ses URL grâce au bouton "ouvrir"
      let listOfLinks = []
      setitemActuallyEditedLinks(listOfLinks)
      const updateContentRecursive = (currentContent) => {
        currentContent.forEach((child) => {
          if (child.childrens) {
            // Si le composant a des enfants, récursivement, appelez la fonction sur les enfants.
            updateContentRecursive(child.childrens)
          }
          // Maintenant, vous pouvez mettre à jour le contenu du composant actuel
          // TEXT
          let contentTxt = child.contentTxt
          // LINK
          const hrefAttribute = child.attributes
            ? child.attributes.find((attr) => attr?.href)
            : undefined

          const titleAttribute = child.attributes
            ? child.attributes.find((attr) => attr?.title)
            : undefined

          // IMAGE
          const imgAttribute = child.attributes
            ? child.attributes.find((attr) => attr?.src || attr?.alt)
            : undefined
          // OVERIDE CONTROLLER

          // TEXT
          if (
            contentTxt &&
            InputsEditable.TextInputsEditable[textIndex]?.contentTxt
          ) {
            contentTxt =
              InputsEditable.TextInputsEditable[textIndex]?.contentTxt
            textIndex++
          }

          // IMAGE
          if (imgAttribute && InputsEditable.ImageInputsEditable[imageIndex]) {
            imgAttribute.src =
              InputsEditable.ImageInputsEditable[imageIndex].src
            imgAttribute.alt =
              InputsEditable.ImageInputsEditable[imageIndex].alt
            imageIndex++
          }

          // On écrase la valeure des propriétés title par celles des alt des images (en suivant la logique de l'index)
          if (
            titleAttribute &&
            InputsEditable.ImageInputsEditable[titleIndex]
          ) {
            titleAttribute.title =
              InputsEditable.ImageInputsEditable[titleIndex].alt
            titleIndex++
          }

          // LINK
          if (hrefAttribute && InputsEditable.LinkInputsEditable[linksIndex]) {
            hrefAttribute.href =
              InputsEditable.LinkInputsEditable[linksIndex].href
            listOfLinks.push(hrefAttribute?.href)
            linksIndex++ // Incrémentation de l'index des liens ici
          }
        })
      }

      // Démarrer la mise à jour récursive depuis le contenu racine
      updateContentRecursive(updatedContent)
      return updatedContent
    }

    updatedItemActuallyEdited.content = updateEditableContent(
      updatedItemActuallyEdited.content,
      contentObjects
    )

    // Maintenant, mettez à jour le composant dans NewsletterProperty
    const updatedComponents = NewsletterProperty.components.map((component) => {
      if (component.id === updatedItemActuallyEdited.id) {
        return updatedItemActuallyEdited
      }
      return component
    })

    // Mettez à jour NewsletterProperty avec les composants mis à jour
    setNewsletterProperty({
      ...NewsletterProperty,
      components: updatedComponents,
    })
  }, [
    itemActuallyEdited,
    InputsEditable.TextInputsEditable,
    InputsEditable.ImageInputsEditable,
    InputsEditable.LinkInputsEditable,
    doubleProductParameters,
    ProductInputsEditable,
    oldContentVersion,
    oldSecondContentVersion
  ])

  function updateStyleById(obj, targetId, newStyle) {
    for (let key in obj) {
      if (obj[key] && typeof obj[key] === 'object') {
        if (obj[key].id === targetId) {
          obj[key].style = newStyle;
          return; // Exit once the target is found and updated
        }
        updateStyleById(obj[key], targetId, newStyle); // Recursively search in nested objects
      }
    }
  }


  if (itemActuallyEdited && itemActuallyEdited.type === 'product') {

    if (itemActuallyEdited.statut.promo_1 === true){
      updateStyleById(itemActuallyEdited, 'PriceStrikethrough_1', '');
      updateStyleById(itemActuallyEdited, 'PourcentPromo_1', `background-color: #f07930; color: #ffffff; font-family: 'Segoe UI', Helvetica, Arial, sans-serif; font-size: 12px; text-decoration: none; font-weight: normal; display: block;`);
      updateStyleById(itemActuallyEdited, 'PriceColor_1', `font-size: 32px; font-weight: 700; line-height: 1; font-family: 'Segoe UI', Helvetica, Arial, sans-serif; color: #f07930;`);      
    } else if (itemActuallyEdited.statut.promo_1 === false) {
      updateStyleById(itemActuallyEdited, 'PriceStrikethrough_1', 'display: none;');
      updateStyleById(itemActuallyEdited, 'PourcentPromo_1', `display: none;`);
      updateStyleById(itemActuallyEdited, 'PriceColor_1', `font-size: 32px; font-weight: 700; line-height: 1; font-family: 'Segoe UI', Helvetica, Arial, sans-serif;`);
    } 

    if (itemActuallyEdited.statut.promo_2 === true){
      updateStyleById(itemActuallyEdited, 'PriceStrikethrough_2', '');
      updateStyleById(itemActuallyEdited, 'PourcentPromo_2', `background-color: #f07930; color: #ffffff; font-family: 'Segoe UI', Helvetica, Arial, sans-serif; font-size: 12px; text-decoration: none; font-weight: normal; display: block;`);
      updateStyleById(itemActuallyEdited, 'PriceColor_2', `font-size: 32px; font-weight: 700; line-height: 1; font-family: 'Segoe UI', Helvetica, Arial, sans-serif; color: #f07930;`);      
    } else if (itemActuallyEdited.statut.promo_2 === false) {
      updateStyleById(itemActuallyEdited, 'PriceStrikethrough_2', 'display: none;');
      updateStyleById(itemActuallyEdited, 'PourcentPromo_2', `display: none;`);
      updateStyleById(itemActuallyEdited, 'PriceColor_2', `font-size: 32px; font-weight: 700; line-height: 1; font-family: 'Segoe UI', Helvetica, Arial, sans-serif;`); 
    }

    if (itemActuallyEdited.statut.description_1 === true){
      updateStyleById(itemActuallyEdited, 'ProductDescription_1', '');
    } else if (itemActuallyEdited.statut.description_1 === false) {
      updateStyleById(itemActuallyEdited, 'ProductDescription_1', 'display: none;');
    }

    if (itemActuallyEdited.statut.description_2 === true){
      updateStyleById(itemActuallyEdited, 'ProductDescription_2', '');
    } else if (itemActuallyEdited.statut.description_2 === false) {
      updateStyleById(itemActuallyEdited, 'ProductDescription_2', 'display: none;');
    }
  

    if (itemActuallyEdited.statut.multiplePaiement_1 === true){
      updateStyleById(itemActuallyEdited, 'ProductPaiementPlusieursFois_1', '');
    } else if (itemActuallyEdited.statut.multiplePaiement_1 === false) {
      updateStyleById(itemActuallyEdited, 'ProductPaiementPlusieursFois_1', 'display: none;');

    }
    
    if (itemActuallyEdited.statut.multiplePaiement_2 === true){
      updateStyleById(itemActuallyEdited, 'ProductPaiementPlusieursFois_2', '');
    } else if (itemActuallyEdited.statut.multiplePaiement_2 === false) {
      updateStyleById(itemActuallyEdited, 'ProductPaiementPlusieursFois_2', 'display: none;');
    }
    
  }


console.log(itemActuallyEdited)
  // Permet de gérer l'édition du texte des modules double produits
  // useEffect(() => {
  //   if (!itemActuallyEdited) return

  //   handleComponentEditable(null, itemActuallyEdited.id)
  // }, [
  //   ProductInputsEditable[0]?.isInPromo,
  //   ProductInputsEditable[1]?.isInPromo,
  //   ProductInputsEditable[0]?.isDescription,
  //   ProductInputsEditable[1]?.isDescription,
  //   ProductInputsEditable[0]?.isMultiplePaiement,
  //   ProductInputsEditable[1]?.isMultiplePaiement,
  //   itemActuallyEdited?.id
  // ])

  // Permet de garder en mémoire les champs contentTXT actuellement édité (ou non) sur le module
  useEffect(() => {
    if (!itemActuallyEdited || itemActuallyEdited.type !== "product") return

    if (itemActuallyEdited.name === "📦 Deux produits reduction 📦") {
      setInputsEditable({
        ...InputsEditable,
        TextInputsEditable: oldContentVersion,
      })
    }

    if (itemActuallyEdited.name === "📦 Deux produits reduction 2 📦") {
      setInputsEditable({
        ...InputsEditable,
        TextInputsEditable: oldSecondContentVersion,
      })
    }

    if (itemActuallyEdited.name === "📦 Deux produits reduction 3 📦") {
      setInputsEditable({
        ...InputsEditable,
        TextInputsEditable: oldSecondContentVersion,
      })
    }

    if (itemActuallyEdited.name === "📦 Deux produits reduction 4 📦") {
      setInputsEditable({
        ...InputsEditable,
        TextInputsEditable: oldSecondContentVersion,
      })
    }
  }, [
    itemActuallyEdited?.type,
    itemActuallyEdited?.id,
    itemActuallyEdited?.name,
  ])



  return (
    <EditorContext.Provider
      value={{
        NewsletterProperty,
        setNewsletterProperty,
        Components,
        setComponents,
        itemActuallyEdited,
        itemActuallyEditedLinks,
        setitemActuallyEdited,
        InputsEditable,
        setInputsEditable,
        ProductInputsEditable,
        setProductInputsEditable,
        doubleProductParameters,
        setdDoubleProductParameters,
        handleComponentEditable,
        toggleDeleteModal,
        setToggleDeleteModal,
        NotificationToggle,
        setNotificationToggle,
        stateProduct,
        setStateProduct,
      }}
    >
      {NotificationToggle?.state === true && <NotificationEditor />}
      {toggleDeleteModal && <DeleteModal />}
      {children}
    </EditorContext.Provider>
  )
}
export { EditorContext, EditorContextProvider }
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const DeleteModal = () => {
  const { axios } = useContext(AuthContext)
  const { newsletterId, templateId } = useParams()
  const navigate = useNavigate()
  const { toggleDeleteModal, setToggleDeleteModal } = useContext(EditorContext)
  const deleteNews = async () => {
    if (newsletterId) {
      await new NewsletterService(getAuthToken()).delete(newsletterId)
      setToggleDeleteModal(false)
      navigate("/newsletters", { replace: true })
    }
    if (templateId) {
      await new TemplateService(getAuthToken()).delete(templateId)
      setToggleDeleteModal(false)
      navigate("/templates", { replace: true })
    }
  }
  return (
    <div>
      <Dialog
        open={toggleDeleteModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setToggleDeleteModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {newsletterId ? "SUPPRIMER LA NEWSLETTER" : "SUPPRIMER LE TEMPLATE"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {newsletterId
              ? "La newsletter sera définitivement supprimée et ne pourra pas être récupérée."
              : "Le template sera définitivement supprimé et ne pourra pas être récupéré."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setToggleDeleteModal(false)}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => deleteNews()}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
