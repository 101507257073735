import React, { useContext, useState } from "react";
import { EditorContext } from "../../../context/EditorContext";
import TextEdit from "./componentProperty/TextEdit";
import ImageEdit from "./componentProperty/ImageEdit";
import LinkEdit from "./componentProperty/LinkEdit";
import ProductCheckbox from "./componentProperty/ProductCheckbox";
import { handleRemoveItemFromLayout } from "../helpers";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
export default function SideBarEditComponent({ toggleSideBarWidth }) {
  const {
    NewsletterProperty,
    setNewsletterProperty,
    itemActuallyEdited,
    setitemActuallyEdited,
    InputsEditable,
    setInputsEditable,
    doubleProductParameters,
  } = useContext(EditorContext);
  const [InputKey, setInputKey] = useState(null);
  const getPathWithIdItem = () => {
    let splitItemPath;
    NewsletterProperty?.layout.map((row, iRow) => {
      if (itemActuallyEdited.id == row.id) splitItemPath = `${iRow}`;
      row?.children.map((column, iCol) => {
        if (itemActuallyEdited.id == column.id)
          splitItemPath = `${iRow}-${iCol}`;
        column?.children.map((component, iCom) => {
          if (itemActuallyEdited.id == component.id)
            splitItemPath = `${iRow}-${iCol}-${iCom}`;
        });
      });
    });
    return splitItemPath;
  };
  const removeToTrashBinWithIdItem = (id) => {
    const splitItemPath = getPathWithIdItem();
    setNewsletterProperty({
      ...NewsletterProperty,
      layout: handleRemoveItemFromLayout(
        NewsletterProperty?.layout,
        splitItemPath
      ),
    });
    setitemActuallyEdited(null);
  };

  //Déclaration d'une fonction qui prend newArray (TextEdit.jsx) comme paramètres.
  const setTextInputsEditable = (newArray) => {
    //Cette fonction en appelle une autre (setInputsEditable).
    setInputsEditable(prevInputsEditable => ({
      //Copie du tableau 'InputsEditable'.
      ...prevInputsEditable,
      //Remplace la clé 'TextInputsEditable' de le copie 'InputsEditable' par 'newArray'
      TextInputsEditable: newArray,
    }));
  };
  const setImageInputsEditable = (newArray) => {
    setInputsEditable({
      ...InputsEditable,
      ImageInputsEditable: newArray,
    });
  };
  const setLinkInputsEditable = (newArray) => {
    setInputsEditable({
      ...InputsEditable,
      LinkInputsEditable: newArray,
    });
  };
  const setProductInputsEditable = (newArray) => {
    setInputsEditable({
      ...InputsEditable,
      ProductInputsEditable: newArray,
    });
  };

  const setStateProduct = () => {
    console.log('SideBarEditCompenent')
  }
  return (
    <>
      <div className="sideBar-edit-nav-container">
        <div className="sideBar-edit-nav-container-title">
          {itemActuallyEdited?.name}
        </div>
        <div className="sideBar-edit-nav-container-action">
          <IconButton
            onClick={toggleSideBarWidth}
            className="aspect-icon-controller --navbar"
          >
            <AspectRatioIcon />
          </IconButton>
          <IconButton
            onClick={() => removeToTrashBinWithIdItem(itemActuallyEdited.id)}
            className="delete-icon-controller --navbar"
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            onClick={() => setitemActuallyEdited(null)}
            className="close-icon-controller --navbar"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="sideBar-edit-items-container">
        {(itemActuallyEdited.type === "text" || "product" || "link") && (
          <TextEdit
            InputKey={InputKey}
            setInputKey={setInputKey}
            TextInputsEditable={InputsEditable?.TextInputsEditable}
            setTextInputsEditable={setTextInputsEditable}
            ProductInputsEditable={InputsEditable?.ProductInputsEditable}
            doubleProductParameters={doubleProductParameters}
          />
        )}
        {(itemActuallyEdited.type === "image" || "product" || "text") && (
          <ImageEdit
            ImageInputsEditable={InputsEditable?.ImageInputsEditable}
            setImageInputsEditable={setImageInputsEditable}
          />
        )}
        {(itemActuallyEdited.type === "link" ||
          "image" ||
          "text" ||
          "product") && (
          <LinkEdit
            LinkInputsEditable={InputsEditable?.LinkInputsEditable}
            setLinkInputsEditable={setLinkInputsEditable}
          />
        )}
        {itemActuallyEdited.type === "product" && <ProductCheckbox />}
      </div>
    </>
  );
}
