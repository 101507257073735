import React, { useContext, useEffect } from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { useParams } from "react-router-dom";
import { EditorContext } from "../../context/EditorContext";
import { LangueContext } from "../../context/LangueContext";
import NewsletterService from "../../services/NewsletterService";
import TemplateService from "../../services/TemplateService";
import { styled } from "@mui/material/styles";

import { Box } from "@mui/material";
import { getAuthToken } from "../../utils/helpers";
import Builder from "./Builder";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Layout() {
  const { langue, setLangue } = useContext(LangueContext);
  const { NewsletterProperty, setNewsletterProperty } =
    useContext(EditorContext);
  const { newsletterId, templateId } = useParams();
  // Get Specific newsletter/template
  const getNewsletter = async (idNews) => {
    if (newsletterId) {
      const response = await new NewsletterService(getAuthToken()).getOne(
        idNews
      );
      if (response.status === 200) {
        setLangue(response?.data?.data?.attributes?.langue);
        setNewsletterProperty({
          ...response.data.data.attributes,
          layout: JSON.parse(response.data.data.attributes.layout),
          components: JSON.parse(response.data.data.attributes.components),
        });
      }
    }
    if (templateId) {
      const response = await new TemplateService(getAuthToken()).getOne(idNews);
      if (response.status === 200) {
        setLangue(response?.data?.data?.attributes?.langue);
        setNewsletterProperty({
          ...response.data.data.attributes,
          layout: JSON.parse(response.data.data.attributes.layout),
          components: JSON.parse(response.data.data.attributes.components),
        });
      }
    }
  };
  useEffect(() => {
    if (newsletterId) getNewsletter(newsletterId);
    if (templateId) getNewsletter(templateId);
    // console.log('NewsletterProperty', NewsletterProperty)
  }, [newsletterId, templateId]);

  return (
    <>
      {/* <Navbar /> */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {NewsletterProperty != null && (
          <DndProvider backend={HTML5Backend}>
            <Builder
              idNews={newsletterId ? newsletterId : templateId}
              langue={langue}
            />
          </DndProvider>
        )}
      </Box>
    </>
  );
}
