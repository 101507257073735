import CheckboxController from "../components/CheckboxController";
import NewsletterService from "../services/NewsletterService";
import { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../utils/helpers";
moment.locale("fr");
export default function Card({
  value,
  index,
  langue,
  selectedNewsletters,
  setSelectedNewsletters,
  displayPreview,
  isLittleVersion,
  isNewsletterCard,
  selectedTemplate,
  setSelectedTemplate,
  setTypeCreating,
  setComponentsTemplate,
  setLayoutTemplate,
  setOpenModalNewsletter,
}) {
  const navigate = useNavigate();
  // Edit Tags
  const [editTagsLarge, setEditTagsLarge] = useState([]);
  // DuplicateTags
  const [duplicateTagsLarge, setDuplicateTagsLarge] = useState([]);
  // TEMPLATE
  const [editTags, setEditTags] = useState([]);
  const [useTags, setUseTags] = useState([]);
  // DELETE + CHECKBOX CONTROLLER
  const [isCheckedArray, setIsCheckedArray] = useState([]);
  // Fonction pour sélectionner une newsletter / template spécifique
  const handleSelectNewsletter = (id) => {
    if (isNewsletterCard === true) {
      setSelectedNewsletters((prevSelection) => {
        if (prevSelection.includes(id)) {
          return prevSelection.filter((selectedId) => selectedId !== id);
        } else {
          return [...prevSelection, id];
        }
      });
    }
    if (isNewsletterCard === false) {
      setSelectedTemplate((prevSelection) => {
        if (prevSelection.includes(id)) {
          return prevSelection.filter((selectedId) => selectedId !== id);
        } else {
          return [...prevSelection, id];
        }
      });
    }
  };
  // Fonction pour gérer le changement de la Checkbox pour une newsletter spécifique
  const handleCheckboxChange = (index) => {
    setIsCheckedArray((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = !newArray[index]; // Inverse la valeur de la checkbox pour l'index spécifié
      return newArray;
    });
  };
  return (
    <div
      className={`card ${isLittleVersion ? "card-little-version" : ""}`}
      key={`newsletter-${index}`}
    >
      <CheckboxController
        index={index}
        langue={langue}
        isChecked={isCheckedArray[index]}
        onCheckboxChange={handleCheckboxChange}
        isSelected={
          isNewsletterCard
            ? selectedNewsletters.includes(value.id)
            : selectedTemplate.includes(value.id)
        }
        onSelect={() => handleSelectNewsletter(value.id)}
      />
      <div
        className={`widget-status widget-status-${value.attributes.status}`}
      ></div>
      <div
        className={`iframe-templatelist ${
          isLittleVersion ? "--allnewsletter" : ""
        }`}
        dangerouslySetInnerHTML={{
          __html: value.attributes.content,
        }}
      ></div>
      <div className={`preview-edit ${displayPreview ? "--on" : ""}`}>
        <div
          className={`widget-status widget-status-${value.attributes.status}`}
        ></div>
        <p className="template-title">{value.attributes.name}</p>
        <p className="template-title" style={{ fontStyle: "italic" }}>
          {moment(value.attributes.createdAt).format("ll")}
        </p>
        {isNewsletterCard && (
          <p className="template-title">{value.attributes.utm_content}</p>
        )}
        {isNewsletterCard ? (
          // NEWSLETTER BUTTONS CONTROLLER
          // NEWSLETTER BUTTONS CONTROLLER
          // NEWSLETTER BUTTONS CONTROLLER
          <div className="card-buttons-container">
            <Button
              className="bgcolor-langue shadowcolor-langue card-buttons-controller"
              variant="contained"
              onClick={() => navigate(`/editor/${value.id}`)}
              onMouseEnter={() => {
                // Mettre à jour l'état editTagsLarge pour l'élément actuel lors du mouseEnter
                setEditTagsLarge((prevState) => {
                  const newState = [...prevState];
                  newState[index] = true;
                  return newState;
                });
              }}
              onMouseLeave={() => {
                // Mettre à jour l'état editTagsLarge pour l'élément actuel lors du mouseLeave
                setEditTagsLarge((prevState) => {
                  const newState = [...prevState];
                  newState[index] = false;
                  return newState;
                });
              }}
            >
              <EditRoundedIcon />
              {editTagsLarge[index] && (
                <div className="buttons-tag">Editer</div>
              )}
            </Button>
            <Button
              className="bgcolor-langue shadowcolor-langue card-buttons-controller"
              variant="contained"
              onClick={async () => {
                const response = await new NewsletterService(
                  getAuthToken()
                ).createNewsletter({
                  name: value.attributes.name,
                  langue: value.attributes.langue,
                  dateSend: value.attributes.date_envoi,
                  utmContent: value.attributes.utm_content,
                  components: JSON.parse(value.attributes.components),
                  layout: JSON.parse(value.attributes.layout),
                  content: value.attributes.content,
                });
                if (response.status === 200) window.location.reload(true);
              }}
              onMouseEnter={() => {
                setDuplicateTagsLarge((prevState) => {
                  const newState = [...prevState];
                  newState[index] = true;
                  return newState;
                });
              }}
              onMouseLeave={() => {
                setDuplicateTagsLarge((prevState) => {
                  const newState = [...prevState];
                  newState[index] = false;
                  return newState;
                });
              }}
            >
              <ContentCopyIcon />
              {duplicateTagsLarge[index] && (
                <div className="buttons-tag --longtag-version">Dupliquer</div>
              )}
            </Button>
          </div>
        ) : (
          // TEMPLATE BUTTONS CONTROLLER
          // TEMPLATE BUTTONS CONTROLLER
          // TEMPLATE BUTTONS CONTROLLER
          <div className="card-buttons-container">
            {/* UTILISER */}
            <Button
              className="bgcolor-langue shadowcolor-langue card-buttons-controller"
              variant="contained"
              onClick={() => {
                setTypeCreating("newsletter");
                setComponentsTemplate(JSON.parse(value.attributes.components));
                setLayoutTemplate(JSON.parse(value.attributes.layout));
                setOpenModalNewsletter(true);
              }}
              onMouseEnter={() => {
                // Mettre à jour l'état editTagsLarge pour l'élément actuel lors du mouseEnter
                setUseTags((prevState) => {
                  const newState = [...prevState];
                  newState[index] = true;
                  return newState;
                });
              }}
              onMouseLeave={() => {
                setUseTags((prevState) => {
                  const newState = [...prevState];
                  newState[index] = false;
                  return newState;
                });
              }}
            >
              <AddIcon />
              {useTags[index] && <div className="buttons-tag">Utiliser</div>}
            </Button>
            {/* MODIFIER */}
            <Button
              className="bgcolor-langue shadowcolor-langue card-buttons-controller"
              variant="contained"
              onClick={() => {
                navigate(`/createTemplate/${value.id}`);
              }}
              onMouseEnter={() => {
                // Mettre à jour l'état editTagsLarge pour l'élément actuel lors du mouseEnter
                setEditTags((prevState) => {
                  const newState = [...prevState];
                  newState[index] = true;
                  return newState;
                });
              }}
              onMouseLeave={() => {
                setEditTags((prevState) => {
                  const newState = [...prevState];
                  newState[index] = false;
                  return newState;
                });
              }}
            >
              <EditRoundedIcon />
              {editTags[index] && (
                <div className="buttons-tag --longtag-version">Modifier</div>
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
