import { useState, useEffect, useContext } from "react";
import { LangueContext } from "../context/LangueContext";
import TemplateService from "../services/TemplateService";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { Switch } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Navbar from "../components/Navbar";
import Card from "./Card";
import MenuCreateNewsletter from "../components/MenuCreateNewsletter";
import BasicBreadcrumbs from "../components/Breadcrumbs";
import { getAuthToken } from "../utils/helpers";

import moment from "moment";
import { CircularProgress } from "@mui/joy";
moment.locale("fr");

function TemplateList() {
  const { langue, setLangue } = useContext(LangueContext);
  const [isLoading, setIsLoading] = useState(false);

  // GET TEMPLATE

  const [templateList, setTemplateList] = useState([]);

  const getTemplates = async () => {
    setIsLoading(true);
    const reponse = await new TemplateService(getAuthToken()).getAll(langue);
    if (reponse.status === 200) setTemplateList(reponse.data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getTemplates();
  }, [langue]);

  // Redirect Edit Controller

  const [componentsTemplate, setComponentsTemplate] = useState([]);
  const [layoutTemplate, setLayoutTemplate] = useState([]);
  const [typeCreating, setTypeCreating] = useState("template");

  const handleChange = (event, newValue) => {
    setLangue(newValue);
  };

  const [openModalNewsletter, setOpenModalNewsletter] = useState(false);

  // DELETE + CHECKBOX CONTROLLER

  const [selectedTemplate, setSelectedTemplate] = useState([]);

  const handleDeleteSelected = async () => {
    for (const id of selectedTemplate) {
      await new TemplateService(getAuthToken()).delete(id);
    }
    setSelectedTemplate([]);
    getTemplates(); // Pour rafraîchir la liste des newsletters après la suppression
  };

  // PREVIEW CONTROLLER SWITCH

  const [displayPreview, setDisplayPreview] = useState(false);

  const handleSwitchChange = (e, val) => {
    setDisplayPreview(val);
  };

  return (
    <>
      <Navbar />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 10 }}
        style={{ marginLeft: "50px" }}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={langue}>
            <BasicBreadcrumbs />
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="FR" value="FR" onClick={() => setLangue("FR")} />
                <Tab label="BE" value="BE" onClick={() => setLangue("BE")} />
                <Tab label="NL" value="NL" onClick={() => setLangue("NL")} />
              </TabList>
            </Box>
            <TabPanel value={langue} className="tabPanel">
              <h2>Sélectionne un modèle ou crée le tien</h2>
              {isLoading && (
                <CircularProgress color="primary" sx={{ marginTop: "5rem" }} />
              )}
              {!isLoading && (
                <>
                  <div className="card-preview-switchButton-container">
                    <p className="switchButton-label">Afficher les titres</p>
                    <Switch onChange={handleSwitchChange} />
                    {selectedTemplate.length > 0 && (
                      <Button
                        className="delete-selected-elements"
                        variant="contained"
                        onClick={handleDeleteSelected}
                      >
                        Supprimer la sélection
                      </Button>
                    )}
                  </div>
                  <div className="containercard">
                    {templateList &&
                      templateList.length > 0 &&
                      templateList.map((value, index) => (
                        <Card
                          key={index}
                          value={value}
                          index={index}
                          langue={langue}
                          selectedTemplate={selectedTemplate}
                          displayPreview={displayPreview}
                          setSelectedTemplate={setSelectedTemplate}
                          isLittleVersion={index < 5 ? false : true}
                          isNewsletterCard={false}
                          setTypeCreating={setTypeCreating}
                          setComponentsTemplate={setComponentsTemplate}
                          setLayoutTemplate={setLayoutTemplate}
                          setOpenModalNewsletter={setOpenModalNewsletter}
                        />
                      ))}
                    <div className="card">
                      <div
                        className="preview-edit"
                        style={{
                          display: "flex",
                          gap: "20px",
                        }}
                      >
                        <Button
                          className="bgcolor-langue shadowcolor-langue"
                          startIcon={<AddIcon />}
                          variant="contained"
                          onClick={() => {
                            setTypeCreating("template");
                            setOpenModalNewsletter(true);
                          }}
                        >
                          Nouveau Template
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </TabPanel>
          </TabContext>
          {/* MODAL USE TEMPLATE AND CREATE NEWSLETTER*/}
          <MenuCreateNewsletter
            open={openModalNewsletter}
            setOpenModalNewsletter={setOpenModalNewsletter}
            typeCreating={typeCreating}
            componentsTemplate={componentsTemplate}
            layoutTemplate={layoutTemplate}
          />
        </Box>
      </Box>
    </>
  );
}

export default TemplateList;
