import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import UserService from "../services/UserService";
import { useState } from "react";

export default function EditProfile({
  langue,
  user,
  setUser,
  actionTag,
  setActionTag,
}) {
  const isAnyActionTagTrue = Object.values(actionTag).some(
    (value) => value === true
  );

  const [isResponseGood, setIsResponseGood] = useState(false);

  return (
    <div className="label-controller-box">
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel>Langue CMS</InputLabel>
        <Select
          value={langue}
          label="Utm Content"
          onChange={async (e) => {
            setUser({
              ...user,
              defaultLanguage: e.target.value,
            });
          }}
        >
          <MenuItem value={"FR"}>FR</MenuItem>
          <MenuItem value={"BE"}>BE</MenuItem>
          <MenuItem value={"NL"}>NL</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <TextField
          label={`Username`}
          variant="outlined"
          value={user?.username}
          onChange={(e) => {
            setUser({
              ...user,
              username: e.target.value,
            });
          }}
        />
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <TextField
          label={`Email`}
          disabled
          variant="outlined"
          value={user?.email}
        />
      </FormControl>
      <Button
        className={`bgcolor-langue shadowcolor-langue card-buttons-controller notallowed-button ${
          isAnyActionTagTrue ? "--allowed" : ""
        }`}
        variant="contained"
        onClick={async () => {
          if (actionTag.isChangingUsername === true) {
            const response = await UserService.editUser(user?.id, {
              username: user?.username,
            });
            setIsResponseGood(response.status === 200);
          }
          if (actionTag.isChanchingDefaultLanguage === true) {
            const response = await UserService.editUser(user?.id, {
              defaultLanguage: langue,
            });
            setIsResponseGood(response.status === 200);
          }
          setTimeout(() => {
            setIsResponseGood(false);
            setActionTag({
              isChanchingDefaultLanguage: false,
              isChangingUsername: false,
            });
          }, 3000);
        }}
      >
        {isResponseGood ? <CheckIcon /> : "Enregistrer les modifications"}
      </Button>
    </div>
  );
}
