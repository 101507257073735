import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ComponentLinkDoubleProduct = ({
  LinkInputsEditable,
  setLinkInputsEditable,
}) => {
  return (
    <>
      <div>
        <Accordion elevation={0}>
          {/* Produit 1 */}
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="accordeon-button --overidemui"
          >
            <Typography className="accordeon-label --overidemui">
              Lien Produit 1
            </Typography>
          </AccordionSummary>
          {/* Content */}
          {LinkInputsEditable[0].length > 0 && (
            <>
              <AccordionDetails className="accordeon-details-container --overidemui">
                <div className="text-properties-container --overidemui">
                  <div
                    className="backgroundcolor-container-controller --overidemui"
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <div
                      className="label-uploadbtn-wrapper"
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <p className="tabs-element-label-property --overidemui">
                        Lien du composant
                      </p>
                      <input
                        value={LinkInputsEditable[0][0].linkUrl}
                        onChange={(e) => {
                          let newArray = [...LinkInputsEditable];
                          LinkInputsEditable[0].map((inputEditable, i) => {
                            newArray[0][i] = {
                              linkUrl: e.target.value,
                              linkTitle: inputEditable.linkTitle,
                              type: "link",
                            };
                            setLinkInputsEditable(newArray);
                          });
                        }}
                        type="text"
                        className="form-control --image"
                      />
                    </div>
                  </div>
                  <div
                    className="backgroundcolor-container-controller --overidemui"
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                      borderBottom: "none",
                      paddingBottom: "0",
                    }}
                  >
                    <div className="label-uploadbtn-wrapper">
                      <p className="tabs-element-label-property --overidemui">
                        Texte Alternatif
                      </p>
                    </div>
                    <input
                      value={LinkInputsEditable[0][0].linkTitle}
                      onChange={(e) => {
                        let newArray = [...LinkInputsEditable];
                        LinkInputsEditable[0].map((inputEditable, i) => {
                          newArray[0][i] = {
                            linkUrl: inputEditable.linkUrl,
                            linkTitle: e.target.value,
                            type: "link",
                          };
                          setLinkInputsEditable(newArray);
                        });
                      }}
                      type="text"
                      className="form-control --image"
                    />
                  </div>
                </div>
              </AccordionDetails>
            </>
          )}
        </Accordion>
        <Accordion elevation={0}>
          {/* Produit 2 */}
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="accordeon-button --overidemui"
          >
            <Typography className="accordeon-label --overidemui">
              Lien Produit 2
            </Typography>
          </AccordionSummary>
          {/* Content */}
          {LinkInputsEditable[1].length > 0 && (
            <>
              <AccordionDetails className="accordeon-details-container --overidemui">
                <div className="text-properties-container --overidemui">
                  <div
                    className="backgroundcolor-container-controller --overidemui"
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <div
                      className="label-uploadbtn-wrapper"
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <p className="tabs-element-label-property --overidemui">
                        Lien du composant
                      </p>
                      <input
                        value={LinkInputsEditable[1][0].linkUrl}
                        onChange={(e) => {
                          let newArray = [...LinkInputsEditable];
                          LinkInputsEditable[1].map((inputEditable, i) => {
                            newArray[1][i] = {
                              linkUrl: e.target.value,
                              linkTitle: inputEditable.linkTitle,
                              type: "link",
                            };
                            setLinkInputsEditable(newArray);
                          });
                        }}
                        type="text"
                        className="form-control --image"
                      />
                    </div>
                  </div>
                  <div
                    className="backgroundcolor-container-controller --overidemui"
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                      borderBottom: "none",
                      paddingBottom: "0",
                    }}
                  >
                    <div className="label-uploadbtn-wrapper">
                      <p className="tabs-element-label-property --overidemui">
                        Texte Alternatif
                      </p>
                    </div>
                    <input
                      value={LinkInputsEditable[1][0].linkTitle}
                      onChange={(e) => {
                        let newArray = [...LinkInputsEditable];
                        LinkInputsEditable[1].map((inputEditable, i) => {
                          newArray[1][i] = {
                            linkUrl: inputEditable.linkUrl,
                            linkTitle: e.target.value,
                            type: "link",
                          };
                          setLinkInputsEditable(newArray);
                        });
                      }}
                      type="text"
                      className="form-control --image"
                    />
                  </div>
                </div>
              </AccordionDetails>
            </>
          )}
        </Accordion>
      </div>
    </>
  );
};
const LinkGeneral = ({ LinkInputsEditable, setLinkInputsEditable }) => {
  // console.log("LinkInputsEditable", {...LinkInputsEditable});

  if (LinkInputsEditable.length === 0) return <></>;
  return (
    <Accordion elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="accordeon-button --overidemui"
      >
        <Typography className="accordeon-label --overidemui">Liens</Typography>
      </AccordionSummary>
      {/* Content */}
      <AccordionDetails className="accordeon-details-container --overidemui">
        {LinkInputsEditable.map((inputEditable, i) => {
          return (
            <div
              key={i}
              className="label-uploadbtn-wrapper"
              style={{
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <p className="tabs-element-label-property --overidemui">
                Lien n°{i + 1}
              </p>
              <input
                value={inputEditable.href}
                onChange={(e) => {
                  let newArray = [...LinkInputsEditable];
                  newArray[i] = {
                    href: e.target.value ? e.target.value : "\u200b",
                  };
                  setLinkInputsEditable(newArray);
                }}
                type="text"
                className="form-control --image"
              />
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

function LinkEdit({ LinkInputsEditable, setLinkInputsEditable }) {
  // console.log("LinkInputsEditable", LinkInputsEditable);
  return (
    <>
      {LinkInputsEditable.length > 0 &&
        !Array.isArray(LinkInputsEditable[0]) && (
          <LinkGeneral
            LinkInputsEditable={LinkInputsEditable}
            setLinkInputsEditable={setLinkInputsEditable}
          />
        )}
      {LinkInputsEditable.length > 1 &&
        Array.isArray(LinkInputsEditable[0]) && (
          <>
            <ComponentLinkDoubleProduct
              LinkInputsEditable={LinkInputsEditable}
              setLinkInputsEditable={setLinkInputsEditable}
            />
          </>
        )}
    </>
  );
}

export default LinkEdit;
