import axios from "axios"
import { BEARER, API_BASE_URL } from "../constants"
import { parseJsonToHtml } from "../utils/helper-editor"

class NewsletterService {
  constructor(jwtToken) {
    this.http = axios.create({
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${BEARER} ${jwtToken}`,
      },
    })
  }
  async createNewsletter({
    name,
    langue,
    dateSend,
    utmContent,
    layout,
    components,
    content,
    status,
  }) {
    const response = await this.http
      .post(`newsletters`, {
        data: {
          name: name,
          langue: langue,
          date_envoi: dateSend,
          utm_content: utmContent,
          layout: layout ? JSON.stringify(layout) : "[]",
          components: components ? JSON.stringify(components) : "[]",
          content: content,
          status: status,
        },
      })
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        return error.response
      })
    return response
  }
  async getAll(langue) {
    const response = await this.http
      .get(`/newsletters?filters[langue][$eq]=${langue}&sort[0]=id:desc`)
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        return error.response
      })
    return response
  }
  async getByName(name, langue) {
    const response = await this.http
      .get(
        `/newsletters?filters[name][$contains]=${name}&filters[langue][$eq]=${langue}`
      )
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        return error.response
      })
    return response
  }
  async getOne(id) {
    const response = await this.http
      .get(`/newsletters/${id}`)
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        return error.response
      })
    return response
  }
  async delete(id) {
    const response = await this.http
      .delete(`/newsletters/${id}`)
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        console.log("error", error)
        return error.response
      })
    return response
  }
  async edit(
    id,
    {
      layout,
      components,
      content,
      name,
      langue,
      utm_content,
      date_envoi,
      status,
    }
  ) {
    const response = await this.http
      .put(`newsletters/${id}`, {
        data: {
          name,
          langue,
          utm_content,
          date_envoi,
          layout: JSON.stringify(layout),
          components: JSON.stringify(components),
          content,
          status,
        },
      })
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        console.log("error", error)
        return error.response
      })
    return response
  }
  async downloadNewsletter({ newsletterName, html, utmContent }) {
    const response = await this.http
      .post(`newsletters/minify`, {
        html,
      })
      .then(async (response) => {
        // const htmlFormat = response.data.replaceAll('\n', '').replaceAll(/\\/g, '');
        const htmlFormat = response.data.replaceAll("\n", "")
        const blob = new Blob([htmlFormat], {
          encoding: "UTF-8",
          type: "text/html",
        })
        // const blob = new Blob([response.data], { encoding: "UTF-8", type: "text/html" });
        const element = document.createElement("a")
        element.href = URL.createObjectURL(blob)
        // return window.open(element.href,"_blank","");
        element.download = `${newsletterName}.html`
        element.click()
        element.remove()
        return response
      })
      .catch((error) => {
        return error.response
      })
    return response
  }
  async sendBAT({ html, email }) {
    const response = await this.http
      .post(`newsletter-email`, {
        html,
        email,
      })
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        console.log("error", error)
        return error.response
      })
    return response
  }
  async convertLayoutToHTML({
    layout,
    components,
    utmContent,
    newsletterName,
  }) {
    let head = `<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html xmlns="http://www.w3.org/1999/xhtml">
  
  <head>
    <meta this.http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="initial-scale=1.0" />
    <meta name="format-detection" content="telephone=no" />
    <title>Newsletter Carrefour Voyages</title>
    <style type="text/css">
        .ReadMsgBody {
            width: 100%;
            background-color: #ffffff;
        }
  
        .ExternalClass {
            width: 100%;
            background-color: #ffffff;
        }
  
        .ExternalClass,
        .ExternalClass p,
        .ExternalClass span,
        .ExternalClass font,
        .ExternalClass td,
        .ExternalClass div {
            line-height: 100%;
        }
  
        html {
            width: 100%;
        }
  
        body {
            -webkit-text-size-adjust: none;
            -ms-text-size-adjust: none;
            margin: 0;
            padding: 0;
            text-align: center;
        }

        p {
          margin: 0px;
          display: inline-block;
        }
  
        table {
            border-spacing: 0;
        }
  
        table td {
            border-collapse: collapse;
        }
  
        td.hnedt>div,
        td.hnedt div>button {
            display: table !important;
        }
  
        .yshortcuts a {
            border-bottom: none !important;
        }
  
        @media only screen and (max-width: 400px) {
            body {
                width: auto !important;
            }
  
            table[class~="container"] {
                width: 100% !important;
                padding-left: 10px !important;
                padding-right: 10px !important;
            }
  
            table[class~="container-p20"] {
                width: 100% !important;
                padding-left: 20px !important;
                padding-right: 20px !important;
            }
  
            table[class~="container-engagement"] {
                width: 380px !important;
                padding-left: 40px !important;
                padding-right: 40px !important;
            }
  
            table[class~="container-lien"] {
                width: 380px !important;
                padding-left: 0px !important;
                padding-right: 0px !important;
            }
  
            table[class~="container-vol"] {
                width: 100% !important;
                padding-left: 24px !important;
                padding-right: 24px !important;
            }
  
            table[class~="full-width"] {
                width: 100% !important;
                float: left !important;
            }
  
            table[class~="width-auto"] {
                width: auto !important;
            }
  
            table[class~="max-height-none"] {
                max-height: none !important;
            }
  
            tbody[class~="max-height-none"] {
                max-height: none !important;
            }
  
            td[class~="max-height-none"] {
                max-height: none !important;
            }
  
            table[class~="width-95-percent"] {
                width: 95% !important;
            }
  
            table[class~="width-50-percent"] {
                width: 50% !important;
            }
  
            table[class~="width-220"] {
                width: 220px !important;
            }
  
            table[class~="width-50-percent-px"] {
                width: calc(50% - 5px) !important;
            }
  
            td[class~="width-50-percent"] {
                width: 50% !important;
            }
  
            td[class~="width-25"] {
                width: 25px !important;
            }
  
            td[class~="width-80"] {
                width: 80px !important;
            }
  
            td[class~="width-100"] {
                width: 100px !important;
            }
  
            td[class~="display-table-cell"] {
                display: table-cell !important;
                max-width: none !important;
                visibility: visible !important;
            }
  
            table[class~="remove"] {
                display: none !important;
            }
  
            tr[class~="remove"] {
                display: none !important;
            }
  
            td[class~="remove"] {
                display: none !important;
            }
  
            span[class~="remove"] {
                display: none !important;
            }
  
            table[class~="unmove"] {
                display: table !important;
            }
  
            td[class~="unmove"] {
                display: table-cell !important;
            }
  
            table[class~="menu-float-center"] {
                width: 90% !important;
                margin-left: 5% !important;
                margin-right: 5% !important;
                text-align: center !important;
            }
  
            table[class~="align-center"] {
                float: none !important;
            }
  
            table[class~="border-picto-account"] {
                height: 40px !important;
                width: 40px !important;
                border-radius: 50% !important;
                padding: 3px !important;
            }
  
            table[class~="border-top"] {
                border-top: 1px solid #1e5bc6 !important;
            }
  
            table[class~="border-right"] {
                border-right: 1px solid #1e5bc6 !important;
            }
  
            table[class~="border-left"] {
                border-left: 1px solid #1e5bc6 !important;
            }
  
            td[class~="text-center"] {
                text-align: center !important;
            }
  
            td[class~="font-size-18"] {
                font-size: 18px !important;
            }
  
            td[class~="font-size-22"] {
                font-size: 22px !important;
                line-height: 18px !important
            }
  
            td[class~="font-size-28"] {
                font-size: 28px !important;
                line-height: 24px !important
            }
  
            img[class~="image-responsive"] {
                width: 100% !important;
                margin: 0 !important;
                height: auto !important;
                max-width: 100% !important;
            }
  
            img[class~="image-responsive-with-marge"] {
                width: 90% !important;
                margin: 0 !important;
                height: auto !important;
                max-width: 90% !important;
            }
  
            img[class~="img-resize"] {
                width: 260px !important;
                max-width: 260px !important;
            }
  
            img[class~="image-width-20"] {
                width: 20px !important;
            }
  
            span.mobile-lien-blanc a {
                color: #ffffff !important;
                text-decoration: none;
            }
  
            a[class~="dm-pdt"] {
                width: 67px !important;
            }
  
            span[class~="remove"] {
                display: none !important;
            }
  
            a[class~="remove"] {
                display: none !important;
            }
  
            br[class~="remove"] {
                display: none !important;
            }
        }
    </style>
   </head>`
    let body = `<body style="font-size:12px; margin: 0; padding: 0;">
    <table border="0" style="width: 100%;" cellspacing="5" cellpadding="0" width="100%" bgcolor="" align="center">`
    for (let row of layout) {
      body += `<tr>`
      for (let column of row.children) {
        body += `<td valign="top" align="center">`
        for (let component of column.children) {
          let componentChoose = null
          components.map((componentArray, index) =>
            componentArray.id == component.id
              ? (componentChoose = componentArray)
              : null
          )
          if (
            componentChoose !== null &&
            parseJsonToHtml(componentChoose?.content) !== null
          )
            body += parseJsonToHtml(componentChoose.content)
        }
        body += `</td>`
      }
      body += `</tr>`
    }
    body += `</table><img src="http://links.email.carrefourvoyages.fr/open/log/4370499/MTUxNTEyNDM4Mjk0S0/3/MTEzMTg4Njk4MQS2/1/0"
    alt="" style="display: none">
    </body></html>`
    const html = `${head}${body}`
    const htmlParse = new DOMParser().parseFromString(html, "text/html")
    const errorNode = htmlParse.querySelector("parsererror")
    if (errorNode)
      return console.log(
        `Une erreur de parsing est survenue lors du téléchargement ! :${errorNode.innerHTML}`
      )

    // Ajout du tracking sur tous les liens
    const links = htmlParse.body.querySelectorAll("a")
    for (let i = 0; i < links.length; i++) {
      const link = links[i]
      if (link.href !== "") {
        const url = new URL(link.href)

        // Vérifier si l'URL contient uniquement un "#"
        if (url.href === `${url.protocol}//${url.hostname}#`) {
          continue // Ne rien faire pour les liens avec uniquement un "#"
        }

        if (url.hash === "#SPCLICKTOVIEW" || url.hash === "#SPONECLICKOPTOUT") {
          continue
        }

        // Vérifier si le "#" est présent dans l'URL
        const hashIndex = url.href.indexOf("#")
        if (hashIndex !== -1) {
          // Extraire la partie avant le "#"
          const baseUrl = `${url.origin}${url.pathname}`
          console.log("baseUrl", baseUrl)
          // Extraire la partie après le "#"
          const hashPart = url.href.slice(hashIndex)
          console.log("hashPart", hashPart)

          // Ajout des paramètres de tracking à l'URL
          const params = url.searchParams // Récupérer les paramètres de l'URL
          console.log("params", params)

          params.set("utm_source", "crm_carrefourvoyages")
          params.set("utm_medium", "newsletter")
          params.set("utm_content", utmContent)
          params.set("utm_campaign", newsletterName)
          params.set("rlvt-u", "%%EMAIL%%")

          const encodedUrl = encodeURI(url.href)
          console.log("encodedUrl", encodedUrl)
          link.setAttribute("href", encodedUrl)
        } else {
          const params = url.searchParams // Récupérer les paramètres de l'URL

          // Ajout des paramètres de tracking normalement
          params.set("utm_source", "crm_carrefourvoyages")
          params.set("utm_medium", "newsletter")
          params.set("utm_content", utmContent)
          params.set("utm_campaign", newsletterName)
          params.set("rlvt-u", "%%EMAIL%%")

          const encodedUrl = encodeURI(url.href)
          console.log("encodedUrl", encodedUrl)
          link.setAttribute("href", encodedUrl)
        }
      }
    }
    let index = 0

    const finalHtml = htmlParse.documentElement.outerHTML
      .replace(/&amp;/g, "&")
      .replace(/rlvt-u=%2525%2525EMAIL%2525%2525/g, "rlvt-u=%%EMAIL%%")
      .replace(/Email=%2525%2525EMAIL%2525%2525/g, "Email=%%EMAIL%%")
      .replace(
        /src="https:\/\/www\.pulsecarshalton\.co\.uk\/wp-content\/uploads\/2016\/08\/jk-placeholder-image\.jpg"/g,
        (_) => `src="images/${index++}.jpg"`
      )

    console.log("finalHtml", finalHtml)
    return finalHtml
  }
}

export default NewsletterService
