import axios from "axios";

import { BEARER, API_BASE_URL } from "../constants";

class ComponentService {
  constructor(jwtToken) {
    this.http = axios.create({
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${BEARER} ${jwtToken}`,
      },
    });
  }
  async getAll(langue) {
    try {
      const response = await this.http
        .get(
          `components?filters[langue][$eq]=ALL&filters[langue][$eq]=${langue}`
        )
        .then(async (response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });
      return response;
    } catch (error) {
      return error.response;
    }
  }
  async getAllV2(langue) {
    try {
      const response = await this.http
        .get(
          `componentv2s?filters[langue][$eq]=ALL&filters[langue][$eq]=${langue}`
        )
        .then(async (response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });
      return response;
    } catch (error) {
      return error.response;
    }
  }
}

export default ComponentService;
