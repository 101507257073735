import { useContext, useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import CreateIcon from "@mui/icons-material/Create";
import moment from "moment";
import { Modal } from "@mui/material";
import TemplateService from "../services/TemplateService";
import { useNavigate } from "react-router-dom";
import NewsletterService from "../services/NewsletterService";
import { LangueContext } from "../context/LangueContext";
import { getAuthToken } from "../utils/helpers";
import AutoCompletion from "./AutoCompletion";

export default function MenuCreateNewsletter({
  open,
  setOpenModalNewsletter,
  typeCreating,
  componentsTemplate,
  layoutTemplate,
}) {
  const navigate = useNavigate();
  const { langue } = useContext(LangueContext);
  const trackings = ["cycle_relationnel", "commerciale", "cross_selling"];
  const [utmContent, setUtmContent] = useState(trackings[0]);
  const [dateEnvoi, setDateEnvoi] = useState(moment());

  // NAME
  const currentDate = moment().format("YYYYMMDD");
  const initialNameNewsletter = `${currentDate}_NL`;

  const [nameNewsletter, setNameNewsletter] = useState(initialNameNewsletter);

  const createNewsletter = async () => {
    if (typeCreating === "template") {
      const reponse = await new TemplateService(getAuthToken()).createTemplate({
        name: nameNewsletter,
        langue,
      });
      if (reponse.status === 200)
        navigate(`/createTemplate/${reponse?.data?.data?.id}`);
    }
    if (typeCreating === "newsletter") {
      const reponse = await new NewsletterService(
        getAuthToken()
      ).createNewsletter({
        name: nameNewsletter,
        langue,
        dateSend: dateEnvoi,
        layout: layoutTemplate,
        components: componentsTemplate,
        utmContent,
      });
      if (reponse.status === 200)
        navigate(`/editor/${reponse?.data?.data?.id}`);
    }
  };

  return (
    <Modal
      className={langue}
      open={open}
      onClose={() => setOpenModalNewsletter(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.7" }}
    >
      <Box className="form-create-newsletter">
        {typeCreating === "newsletter" && (
          <>
            <SplitButton options={trackings} setOption={setUtmContent} />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Date d'envoi"
                value={moment(dateEnvoi)}
                onChange={(newValue) => {
                  const newDate = moment(newValue.toDate()).format(
                    "YYYY-MM-DD"
                  );
                  setDateEnvoi(newDate);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </>
        )}
        <TextField
          label="Nom"
          variant="outlined"
          sx={{ width: 300 }}
          value={nameNewsletter}
          onChange={(e) => setNameNewsletter(e.target.value)}
        />
        <AutoCompletion
          nameNewsletter={nameNewsletter}
          setNameNewsletter={setNameNewsletter}
          dateEnvoi={dateEnvoi}
        />
        {/* const top100Films = [{ title: "Bon plans" }, { title: "Weekendesk" }]; */}
        <Button
          className="bgcolor-langue"
          onClick={() => createNewsletter()}
          variant="contained"
          startIcon={<CreateIcon />}
        >
          {typeCreating === "newsletter"
            ? "Créez newsletter"
            : "Créez template"}
        </Button>
      </Box>
    </Modal>
  );
}

const SplitButton = function ({ options, setOption }) {
  // export default function SplitButton() {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    setOption(options[selectedIndex]);
  }, [selectedIndex]);

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          className="bgcolor-langue splitbutton-border"
          sx={{ borderOpacity: 0.4 }}
        >
          {options[selectedIndex]}
        </Button>
        <Button
          className="bgcolor-langue"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 2,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      // disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
