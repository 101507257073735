import React, { useEffect } from "react";
import { useDrag } from "react-dnd";

const SideBarItem = ({ data }) => {
  const [{ opacity }, drag] = useDrag({
    item: data,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  useEffect(() => {
    // console.log("testlog", data);
  }, []);

  return (
    <>
      <div
        ref={drag}
        style={{
          opacity,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
        className="sideBar-items-container-item --overide"
      >
        {/* {data?.component?.icon}
        {data?.component?.type} */}
        {data?.component?.name}
      </div>
    </>
  );
};
export default SideBarItem;